import { ActiveRepo } from "activeDoc";
import { Container } from "containers/container";
import { CosetLabel } from "containers/pad/coset-label"
import { useActiveGame, useAsGameViewer, useAsHandViewer } from "hook";
import React from "react";

interface ConsoleArgs {
	repo:ActiveRepo;
	faces: (string | number)[],
	score: any
}

export const GameViewer = (props:ConsoleArgs) => {
	const { repo, faces, score } = props;
	const {game} = useActiveGame( {repo})
	const { stage, actions, } = useAsGameViewer(repo);
	return (
		<Container className="mt-4">
			<div className="text-alt-3 text-3xl score ">
				<span>{stage}</span>
				<span className="label ml-2">solved</span>
				<span className="number ml-2">{score.solved}</span>
				<span className="label ml-2">unsolved</span>
				<span className="number ml-2">{score.unsolved}</span>
				<span className="label ml-2">length</span>
				<span className="number ml-2">{faces.length}</span>
			
			</div>
			<div className="grid grid-cols-8 font-thin text-alt-2">
				<div>id</div>
				<div> active</div>
				<div>player</div>
				<div>cardIdx</div>
				<div>status</div>
				<div>next</div>
				<div>started</div>
				<div>secs</div>
				{actions.map((a, idx) => (
					<React.Fragment key={idx}>
						<div>{a.id}</div>
						<div>{a.active?.toString()}</div>
						<div>
							{a.player} {"<="} {a.originator}
						</div>
						<div>{a.cardIdx}</div>
						<div>{a.status}</div>
						<div>{a.nextRnd}</div>
						<div>{JSON.stringify(a.startedAt)}</div>
						<div>{a.milliSeconds}</div>
					</React.Fragment>
				))}
			</div>
			<div className="grid grid-cols-10 gap-5 mt-4 px-16">
				{faces.map((n: any, idx) => (
					<React.Fragment key={idx}>
						<div
							className="game-card game-card-face text-3xl font-extrabold rounded items-center text-center">
							<CosetLabel coset={game!.coset} label={n} />
						</div>
					</React.Fragment>
				))}
				<div className="text-alt-3">
					{game?.coset}
				</div>
				
			</div>
		</Container>
	);
};
