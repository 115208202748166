import { invariant } from "logs/index"
import { useState } from "react"
import { FveApp } from "services/fveApplication"
import { IProfile, IUser } from "services/types"
import { gql, useMutation } from "urql"
const updateWpUser = gql`
    mutation update_user($id: ID!,
        $name: String,
        $email: String,
        $bio: String,
        $photo: String) {
        updateUser(input : {id: $id,
            websiteUrl: $photo,
            email: $email,
            description: $bio,
            displayName: $name}) {
            user {
                id
                name
                email
                url
                description
            }
        }
    }
`


const mergeWithUser = (patch:Partial<IProfile>) => {
	return { ...FveApp.user$.getValue(), ...patch } as IUser
}


export const useUpdateUser = () => {
	const [updatedUser, updateUpdated] = useState<Partial<IUser>>({})
	const [{ fetching, data, error }, update] = useMutation(updateWpUser);
	const setUser = async (profile:Partial<IProfile>):Promise<Partial<IUser>> => {
		const { hashId, name, photo, email, bio } = { ...FveApp.user$.getValue(), ...profile }
		const variables = { id: hashId, name, photo, email, bio }
		console.log("variables", variables)
		const rt = await update(variables)
		invariant.log(`update user return...`, rt)
		const { data: { updateUser: { user } } } = rt
		const { id: extractId, ...updated } = user
		if (updated) {
			const { name, url: photo, email, description: bio } = updated
			const patch = { name, photo, email, bio}
			updateUpdated(patch)
			FveApp.setUser(mergeWithUser(patch))
			return patch
		}
		return {}
	}
	return {
		updatedUser,
		setUser,
		fetching, data, error
	}
}
