import { PeerSync } from "activeDoc"
import { yamlObj } from "activeDoc/types"
import { GameData } from "containers/game-data"
import { ButtonGroup, Heading, HeadingLabel, IconButton, Tabs, Unit } from "fui"
import { useActiveGame } from "hook"
import React, { useEffect, useMemo, useState } from "react"
import { BiTable } from "react-icons/bi"
import { GameStage, initRepo } from "services"

interface Args {
	peer:PeerSync
}

export const ActiveHost = (props:Args) => {
	const { peer } = props
	const intlRepo = useMemo(() => {
		return initRepo();
	}, [])
	const { game, gameRepo, update, changeSnapshot } = useActiveGame({ sync: peer, repo: intlRepo })
	useEffect(() => {
		gameRepo.onAskingSeed()
	}, [gameRepo])
	const onSetStage = (stage:GameStage) => {
		update.setStage(stage)
	}
	
	const [viewTab, setViewTab] = useState("Data")
	const onTabChange = (activate:string, deActivate:string) => {
		setViewTab(activate)
	}
	return <div>
		<div className="text-alt-3">
			[Active Host] Active Doc {gameRepo.id} | {game?.coset}
		</div>
		<div className="flex flex-row gap-2 items-end">
			<Unit>
				<Heading>
					<BiTable className="text-2xl font-bold"/>
					<HeadingLabel>Set Game Stage</HeadingLabel>
				</Heading>
				<div className="flex flex-row ">
					<ButtonGroup
						onClick={onSetStage}
						defaultLabel="PREP"
						captions={[
							{ label: "PREP", value: "PREP" },
							{ label: "MATCH", value: "MATCH", },
							{ label: "DONE", value: "DONE" },
						]}
					/>
				</div>
			</Unit>
			<div className="flex flex-row gap-2">
				<IconButton className="px-2" variant="color"
				            onClick={() => update.addRandomAction()}>
					Random Actions</IconButton>
				<IconButton className="px-2" variant="color"
				            onClick={() => { gameRepo.onAskingSeed()}}>
					Rollup</IconButton>
			</div>
		</div>
		
		<div>
			<Tabs tabNames={["Data", "Changes"]} callback={onTabChange}/>
		</div>
		<div>
			{viewTab === "Data" &&
			<GameData game={game}/>
			}
			{viewTab === "Changes" &&
			<pre className="p-8">
					{yamlObj(changeSnapshot)}
				</pre>
			}
		
		</div>
	</div>
}
