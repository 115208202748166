import useAxios from "axios-hooks"
import { FveApp } from "services"
import { CreateAction, UpdateAction, VerifyAction } from "./typesAndUtils"


export const usePasswordAction = () => {
	const [{ data, loading, error }, refetch] = useAxios(
		{
			method: "POST",
			url: FveApp.endPoints.passwordAction,
		},
		{
			manual: true
		}
	)
	
	const verifyToken = async (args:VerifyAction) => {
		await refetch({ data: args })
	}
	
	const updateAction = async (args:UpdateAction) => {
		await refetch({ data: args })
	}
	
	const createAccount = async (args: Partial<CreateAction>) =>
	{
		const arg = { action: "create", ...args }
		await refetch({ data: arg })
	}
	
	return { data, loading, error, verifyToken, updateAction, createAccount }
}
