import {
    addMilliseconds,
    addSeconds,
    differenceInMilliseconds,
    isAfter,
} from "date-fns"
import { Observable, timer } from "rxjs"
import {
    distinctUntilChanged,
    map,
    shareReplay,
    takeWhile,
    tap,
} from "rxjs/operators"
import { StageCountDown, StageInfo } from "./types"

export class StageCountdown {
    stages: StageInfo[]
    countdown$?: Observable<Partial<StageCountDown>>
    stage$?: Observable<any>

    private position: Date
    private currentName: string
    private lastOne: Partial<StageCountDown> = {}

    constructor() {
        this.stages = []
        this.position = new Date()
        this.currentName = ""
    }

    countdown(stages: StageInfo[]): Observable<Partial<StageCountDown>> {
        const startingPoint = new Date()
        this.stages = stages
        this.position = new Date()
        this.currentName = stages[0].name
        stages.forEach((stage, idx) => {
            stage.from = addMilliseconds(
                idx === 0 ? startingPoint : stages[idx - 1].till,
                1
            )
            stage.till = addSeconds(
                idx === 0 ? startingPoint : stages[idx - 1].till,
                stage.spanInSeconds
            )
        })

        const maxSpan = this.lastBreakPoint()
        const passPoint = addMilliseconds(maxSpan, 0)
        let underThread = 0
        const threadCount = 5
        const decideResponse = () =>
            underThread < threadCount ? this.countDownStage() : this.lastOne
        const tick = (d: Date) => {
            this.position = d
            if (isAfter(d, passPoint)) {
                underThread++
            }
        }
        this.countdown$ = timer(100, 99) //Initial delay .1 seconds and interval .1
            .pipe(
                map(() => new Date()),
                tap(tick),
                takeWhile(() => underThread <= threadCount),
                map(decideResponse),
                shareReplay()
            )
        this.stage$ = this.countdown$.pipe(
            distinctUntilChanged((prev, curr) => prev.stage === curr.stage),
            map((c) => c.stage)
        )
        return this.countdown$
    }

    forward() {
        const found = this.countDownStage()
        const { till } = found
        if (till) {
            this.position = till
        }
    }

    lastBreakPoint = () => {
        return this.stages[this.stages.length - 1].till
    }

    countDownStage = (): Partial<StageCountDown> => {
        const found = this.stages.find(
            (s) =>
                this.position > s.from &&
                addMilliseconds(this.position, -100) <= s.till
        )
        const diff = (d1: Date, d2: Date) => {
            const d = differenceInMilliseconds(d1, d2)
            return d >= 0 ? d : 0
        }

        this.lastOne = found
            ? { ...found, countdown: diff(found.till, this.position) }
            : this.lastOne

        return this.lastOne
    }
}
