import React from "react"
import { drawNextRnd } from "services"

export const FacePad = (props: { num?: number }) => {
    const  num  = props.num || drawNextRnd("english")
    return (
        <div className="game-card game-card-face rounded">
            <span className="inline-grid justify-center text-5xl font-base">
	            {num}
            </span>
        </div>
    )
}

