import React from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { PasswordMessage } from "./password-message"
import { PasswordForm } from "./password-form"

export const PasswordRoute = () => {
    const { path } = useRouteMatch()
    return (
        <>
            <Route path={`${path}/msg`}>
               <PasswordMessage/>
            </Route>
            <Route path={`${path}/setting`}>
                <PasswordForm/>
            </Route>
        </>
    )
}
