import { Container } from "containers/container"
import React from "react"
import { Game } from "services"


interface ConsoleArgs {
	game?:Game
}

export const GameData = (props:ConsoleArgs) => {
	const { game } = props
	return (game) ?
		   (<Container className="mt-4">
			   <div className="text-alt-3 text-3xl">{game.stage}</div>
			   <div className="grid grid-cols-7 font-thin text-alt-2">
				   <div>id</div>
				   <div>player</div>
				   <div>cardIdx</div>
				   <div>status</div>
				   <div>next</div>
				   <div>started</div>
				   <div>secs</div>
				   {game.actions.map((a, idx) => (<React.Fragment key={idx}>
					   <div>{a.id}</div>
					   <div>{a.player} {"<="} {a.originator}</div>
					   <div>{a.cardIdx}</div>
					   <div>{a.status}</div>
					   <div>{a.nextRnd}</div>
					   <div>{JSON.stringify(a.startedAt)}</div>
					   <div>{a.milliSeconds}</div>
				   </React.Fragment>))}
			   </div>
			   <div className="grid grid-cols-10 gap-5 mt-4 px-16">
				   {game.fish.map((n, idx) => (<React.Fragment key={idx}>
					   <div
						   className="game-card game-card-face text-3xl font-extrabold rounded items-center text-center">{n}</div>
				   </React.Fragment>))}
			   </div>
	
		   </Container>)
				  : (<div>No instance</div>)
}
