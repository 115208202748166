import { ActiveRepo } from "activeDoc"
import { Action, GameStage, PlayMode } from "services"


export interface GameProps {
	player:string;
	coset: string
	mode:PlayMode;
	repo?:ActiveRepo;
	faces:(string | number)[]
	actions:Action[]
}

export interface GameCardArgs {
	label?:number;
	action?:Action;
	coset: string;
	stage:GameStage;
	activeCallback?:Function;
	answerCallback?:Function;
	actionId?:string;
	showPopup?:boolean;
	mode:PlayMode;
	idx:number;
	gameOn:boolean;
}


export const BaseGameCardArgs:GameCardArgs = {
	label: 7,
	coset: "english",
	stage: "PREP",
	mode: "PLAY",
	idx: 0,
	gameOn: false
}


export enum CardDisplayType {
	Back,
	Resolving,
	Challenged,
	Lost,
	Face,
	GuessWithKey,
	NextNum,
	CurrentUnknown
}

export enum CosetType {
	Number,
	Smiley,
	Chinese,
	Japanese,
}


export function decideWhatToDisplay (
	stage:GameStage,
	isLost:boolean,
	isChallenged:boolean,
	isResolving:boolean,
	gameOn:boolean
)
{
	let displayType:CardDisplayType = CardDisplayType.Face;
	if (stage === "PREP") {
		displayType = CardDisplayType.Face;
	} else if (stage === "DONE" || !gameOn) {
		displayType = isLost ? CardDisplayType.Lost : CardDisplayType.Face;
	} else {
		// Fight Stage, default
		displayType = isLost ? CardDisplayType.Lost : CardDisplayType.Back;
		displayType = isResolving ? CardDisplayType.Resolving : displayType;
		displayType = isChallenged ? CardDisplayType.Challenged : displayType;
	}
	return displayType;
}


export const getAction = (idx:number, actions:Action[]) => {
	return actions.find((a) => a.cardIdx === idx && a.status !== "RESOLVED");
};

export function findPop (actions:Action[])
{
	return actions.find((a) => a.active);
}

