import { differenceInMilliseconds } from 'date-fns'
import { useSubs } from "hook/use-subs"
import React, { useEffect, useState } from 'react';
import { clearInterval, setInterval, setTimeout } from 'requestanimationframe-timer';
import { StageConfig, twoDigits, unsubscribeAll } from "services"

interface Args {
	stage: StageConfig
}

const minuteInMilliseconds = 60 * 1000

export const GameStage = (props: Args) => {
	const { stage } = props
	const subs = useSubs()
	const [seconds, setSeconds] = useState<number | string>(0)
	const [minutes, setMinutes] = useState<number>(0)


	useEffect(() => {
		unsubscribeAll(subs)()

		const updateTimer = setInterval(() => {
			const asOf = new Date()
			const diff = differenceInMilliseconds(stage.endsAt, asOf)
			if (diff < 0) {
				clearInterval(updateTimer as any);
			}
			if (diff <= 0) {
				setSeconds(0)
				setMinutes(0)
			} else {
				const wholeMinutes = Math.floor(diff / minuteInMilliseconds)
				const remains = diff - wholeMinutes * minuteInMilliseconds
				setMinutes(wholeMinutes)
				setSeconds(twoDigits(remains / 1000))
			}
		}, 50);
		const dur = differenceInMilliseconds(stage.endsAt, stage.startsAt) + 1000
		setTimeout(() => {
			clearInterval(updateTimer as any);
		}, dur)

		return () => { clearInterval(updateTimer as any); }
	}, [seconds, stage.duration, stage.endsAt, stage.startsAt, subs])
	return (<div className="flex flex-row justify-between text-alt-3 score">
		<div className="flex flex-row align-baseline">
			<span className="label">{stage.stage}</span>
			<span className="ml-4">
				<span className="time">{minutes}</span>
				<span className="">m</span>
				<span className="time ml-4">
					{twoDigits(seconds as any)}
				</span>
				<span className="">s</span>
			</span>
		</div>

	</div>
	)
}
