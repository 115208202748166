import React, { useState } from "react"
import { TiChevronLeft, TiChevronRight } from "react-icons/ti"
import { CardsSetup } from "./cards-setup"
import { ExplainFish } from "./explain-fish"
import { Hero } from "./hero"
import { Replace } from "./replace"
import { Slide } from "./slide"
import "./sliding.scss"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css"

const sequence = ["card-setup", "hero", "replace", "explain-fish"]

const rightSlide = (current: string) => {
    const originalIdx = sequence.indexOf(current) + 1
    const idx = originalIdx >= sequence.length ? 0 : originalIdx
    return sequence[idx]
}

const leftSlide = (current: string) => {
    const originalIdx = sequence.indexOf(current) - 1
    const idx = originalIdx < 0 ? sequence.length - 1 : originalIdx
    return sequence[idx]
}

export const SlidingHomeMade = () => {
    const [activeSlide, setActive] = useState("explain-fish")
    const moveRight = () => {
        setActive(rightSlide(activeSlide))
    }
    const moveLeft = () => {
        setActive(leftSlide(activeSlide))
    }
    return (
        <div className="flex-row flex justify-center slide-door">
            <div className="slider-button" onClick={moveLeft}>
                <TiChevronLeft />
            </div>
            <div>
                <Slide slideName="explain-fish" activeSlide={activeSlide}>
                    <ExplainFish />
                </Slide>
                <Slide slideName="hero" activeSlide={activeSlide}>
                    <Hero />
                </Slide>
                <Slide slideName="card-setup" activeSlide={activeSlide}>
                    <div className="flex flex-row justify-end">
                        <CardsSetup />
                    </div>
                </Slide>
                <Slide slideName="replace" activeSlide={activeSlide}>
                    <Replace />
                </Slide>
                <Slide slideName="slide" activeSlide={activeSlide}>
                    <div> Slide 3</div>
                </Slide>
            </div>
            <div className="slider-button" onClick={moveRight}>
                <TiChevronRight />
            </div>
        </div>
    )
}


export const Sliding = () => {
    return (
        <Splide
            options={{
                rewind: true,
                gap: "4rem",
                padding: "4rem"
            }}
        >
            <SplideSlide>
                <ExplainFish />
            </SplideSlide>
            <SplideSlide>
                <CardsSetup />
            </SplideSlide>
            <SplideSlide>
                <Replace />
            </SplideSlide>
        </Splide>
    )
}
