import classed from "classed-components"
import { Modal } from "containers/modal/modal"
import { IconButton } from "fui/button"
import { useModal } from "hook/use-modal"
import React, { useState } from 'react';
import { EditHeader } from "routes/setup/edit-header"
import { toaster$ } from 'services'
import { FveApp } from "services/fveApplication"
import { useUpdatePassword } from "services/user/use-update-password"
import { fromToday, randId } from "services/utils"

const Label = classed.div(["text-xs", "text-alt-2", "font-bold"])

interface CredentialArgs {
	id?:any
}

export const Credential = (props:CredentialArgs) => {
	const { id } = props
	const { show, shutDown, isOpen } = useModal();
	const [password, setPassword] = useState("")
	const [qualified, setQualified] = useState(false)
	const { updatePassword, fetching: inProgressSetting, data, error } = useUpdatePassword()
	const { user } = FveApp.useUser();
	const capture = (e:any) => {
		setPassword(e.target.value)
		setQualified(e.target.value.length >= 8)
	}
	const onResetPassword = async () => {
		await updatePassword(id, password)
		if (error) {
			toaster$.next({
				              id: randId(),
				              main: "Operation failed",
				              subTitle: error.message,
				              type: "ERROR"
			              })
		} else {
			toaster$.next({
				              id: randId(),
				              main: "Your password has been successfully updated.",
				              type: "SUCCESS"
			              })
		}
		
		shutDown()
	}
	return <>
		<EditHeader label="Change Password" onClick={show}/>
		
		<div className="mt-2">
			<Label>Account Locator</Label>
			<div className="text-lg text-alt-1">{user.hashId || "(hashId)"}</div>
			<Label>User Name</Label>
			<div className="text-lg text-alt-1">{user.username || "(name)"}</div>
			<div className="text-sm text-alt-2 my-2">
				joined {fromToday(user.joinedAt)} ago
			</div>
		</div>
		
		<Modal
			shutDown={shutDown}
			isOpen={isOpen}
			title="Reset Password"
			keepOpenClickOutside={true}
		>
			<div className="w-24rem  p-4 m-4 text-alt-2">
				<div className="mb-4">
					<input
						type="password"
						className="fish-input"
						placeholder="new password (min 8 chars)"
						onChange={capture}
					/>
				</div>
				<div className="grid grid-cols-2 spacing-2">
					<IconButton variant="color" disabled={!qualified}
					            onClick={onResetPassword}
					            fetching={inProgressSetting}>Submit</IconButton>
					<IconButton variant="link" onClick={shutDown}>Cancel</IconButton>
				</div>
			</div>
		</Modal>
	</>
}

