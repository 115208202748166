import { peerConnectFrom$, peerConnectTo$ } from "activeDoc"
import { useSubs } from "hook/use-subs"
import { useTearDown } from "hook/use-tear-down"
import { invariant } from "logs";
import { MessageEvent } from "pubnub"
import { useMemo, useState } from "react";
import { Subject } from "rxjs"
import { filter, tap } from "rxjs/operators"
import { getCurrentMatchNub, getNub, nubSubject } from "services"
import { getActiveMachine, StateInfo } from "services/active-machine";
import { interpret } from 'xstate'

interface arg {
	matchId:string;
	mode:string;
	peer:string;
	host:string;
}

const wireMatchStateMachine = (
	matchId:string, host:string, peer:string, subs:any[], undo:any[],
	onState$:Subject<StateInfo>) => {
	invariant.log(`[match] hwo many times I am here!!!!`)
	undo.push(getCurrentMatchNub().subscribeMatch(matchId))
	const nub = getNub()
	const { message$ } = nubSubject
	const matchMsg$:Subject<MessageEvent> = new Subject<MessageEvent>()
	const isForTheMatch = (m:MessageEvent) => m.channel.endsWith(matchId)
	const traceChannel = (msg:MessageEvent) => { invariant.log(`[match-channel]`, msg as any)}
	subs.push(message$.pipe(filter(isForTheMatch), tap(traceChannel))
					  .subscribe(matchMsg$))
	const stateMachine = getActiveMachine({ matchId, host, peer }, nub);
	const machineService = interpret(stateMachine)
	
	machineService.onTransition(currentState => {
		const initialStateChanged =
			currentState.changed === undefined &&
			Object.keys(currentState.children).length;
		if (currentState.changed || initialStateChanged) {
			onState$.next(currentState as StateInfo);
		}
	})
	machineService.start()
	
	const sendMessage = (msg:any) => machineService.send(msg)
	subs.push(matchMsg$.subscribe((msg:MessageEvent) => {
		const { message } = msg
		const prefix = "event://"
		if (typeof message === "string" && message.startsWith(prefix)) {
			invariant.log(`[matched-channel] send to machine `, message)
			sendMessage(message.replace(prefix, ""))
		} else {
			invariant.log(`[matched] received `, message)
		}
	}))
	return [sendMessage] as const
}

const sendHostSeesPeer = async () => {
	const nub = getCurrentMatchNub()
	await nub.sendMatchMsg('event://HOST_SEE_PEER')
}

const sendPeerSeesHost = async () => {
	const nub = getCurrentMatchNub()
	await nub.sendMatchMsg('event://PEER_SEES_HOST')
}


export const useMatch = (config:arg) => {
	const subs = useSubs();
	const undo = useTearDown()
	const [machineState, setMachineState] = useState<StateInfo>()
	const { matchId, host, peer } = config
	const [sendMessage] = useMemo(() => {
		const onState$ = new Subject<StateInfo>()
		const [sendMessage] = wireMatchStateMachine(matchId, host, peer, subs, undo, onState$)
		subs.push(onState$.subscribe(state => setMachineState(state)))
		subs.push(peerConnectFrom$.subscribe(async (address) => {
			invariant.log(`[peer]`, `peerConnectFrom$`)
			if (address === peer) {await sendHostSeesPeer()}
		}))
		subs.push(peerConnectTo$.subscribe(async (address) => {
			invariant.log(`[peer]`, `peerConnectTo$`)
			if (address === host) {await sendPeerSeesHost()}
		}))
		return [sendMessage]
	}, [matchId, host, peer, subs, undo]);
	return [machineState, sendMessage] as const
};

