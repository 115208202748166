
import React from "react"
import { Covered } from "./replace"
import { JumpFish } from "./jumpFish"

export const ExplainFish = () => {
	return (<div>
		<div className="bg-main-2-to-5 p-8 rounded">
			<div className="flex flex-row gap-1 justify-center">
				<Covered/>
				<JumpFish/>
				<Covered/>
			</div>
		</div>


		<div className="text-lg">
			'Jumping fish' indicates a new number is replacing the number in that position.
		</div>
 
	</div>)
}
