import React from "react"
import {FaStar, FaStarHalfAlt, FaRegStar} from 'react-icons/fa'

interface RankingInfo {
	full:number
	half?:boolean
}

export const Ranking = (props:RankingInfo) => {
	const { full, half } = props
	return (<div className="flex flex-row">
		{
			[...Array(full)].map((n, idx) => <FaStar key={idx}/>)
		}
		{
			half && <FaStarHalfAlt/>
		}
	</div>)
}
