const shuffle = require('lodash.shuffle');

const makePopulation = (source: string) => {
	return ([...new Set(source.split(""))].sort())
}

export const makeCoset = (dict: any) => {
	const entries = Object.entries(dict)
	const simp = entries.map(p => {p[1] = makePopulation(p[1] as string); return p } )
	return Object.fromEntries(simp)
}
