import { useIsLocal } from "hook"
import React from "react"
import { Action } from "services"

interface Args {
    actions: Action[]
	visible?: boolean
}

export const ActionList = (props: Args) => {
	const isLocal = useIsLocal();
	const {actions, visible} = props
	if (!visible || !isLocal) {
		return (<></>)
	}
    return (
        <div>
            <div>Well what do we know?</div>

            <table className="table table-auto text-alt-4 w-3/4">
                <thead className="table-header">
                    <tr className="table-row">
                        <th className="text-left">id</th>
                        <th className="text-left">status</th>
                        <th className="text-left">player</th>
                        <th className="text-left">idx</th>
                        <th className="text-left">status</th>
                        <th className="text-left">startAt</th>
                        <th className="text-left">milisec.</th>
                        <th className="text-left">taken</th>
                    </tr>
                </thead>
                <tbody>
                    {actions.map((a, idx) => (
                        <tr className="table-row" key={idx}>
                            <td>{a.id}</td>
                            <td>{a.status.toString()}</td>
                            <td>{a.player}</td>
                            <td>{a.cardIdx}</td>
                            <td>{a.status}</td>
                            <td>{a.startedAt}</td>
                            <td>{a.milliSeconds}</td>
                            <td>{(a as any).taken!}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
