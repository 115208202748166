import { btwMinAndMax } from 'services/rand'
import { language } from './language'
import { makeCoset } from './utils'

const samplerGenerator = (population:string[]) => (rnd: Function) => {
	const max = population.length - 1
	return () => rnd(max)
}

const cosetNames = Object.keys(language)

const charPopulation = makeCoset(Object.assign({}, language))

const makeSamplers = () => {
	const coset = makeCoset(Object.assign({}, language))
	const entries = Object.entries(coset)
	const altered = entries.map(pair => {
		pair[1] = samplerGenerator(pair[1] as any)
		return pair
	})
	const restored = Object.fromEntries(altered)
	return restored
}

const samplers = makeSamplers() as any

export const getRandomCoset = () => {
	return cosetNames[btwMinAndMax(0, cosetNames.length - 1)]
}

export const drawFromCoset = (cosetName:string, rnd: Function) => {
	return samplers[cosetName](rnd)()
}

export const drawADifferentOne = (cosetName:string, exist:number[], rnd: Function) => {
	const sampler = samplers[cosetName]
	let next : number
	while (true) {
		const candidate = sampler(rnd)()
		const found = exist.some((s:number) => s === candidate)
		if (!found) {
			next = candidate
			break
		}
	}
	return next
}

export const drawTill9 = (cosetName:string, exist:number[], rnd: Function):number[] => {
	if (exist.length === 10) {
		return [...exist]
	}
	const next = drawADifferentOne(cosetName, exist, rnd)
	return drawTill9(cosetName, [...exist, next], rnd)
}


export const getCosetChar = (cosetName: string, idx: number) => {
	return (charPopulation as any)[cosetName][idx]
}
