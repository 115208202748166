import { yamlObj } from 'activeDoc';
import jumpFish from 'assets/images/jump-fish.gif'
import classed from 'classed-components'
import { Container, Modal, RecordsViewer } from "containers";
import { useIsLocal, useModal } from "hook";
import { invariant } from "logs";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { FaIdCard, } from 'react-icons/fa'
import { FiMail } from "react-icons/fi";
import { AvatarCutter } from "routes/setup";
import { Credential } from "routes/setup/credential"
import { SaveCancel } from "routes/setup/save-cancel"
import { cleanUpPosts, ProfileService } from "services";
import { FveApp } from "services/fveApplication";
import { useGetPractices, useUpdateUser } from "services/user"
import { DataError } from './data-error';
import { EditHeader } from './edit-header';
import { ProfileImage } from "./profile-image";

const Label = classed.div(["text-xs", "text-alt-2", "font-bold"])
const emailReg = /^\S+@\S+\.\S+$/

interface IFormInput {
	name:string;
	email:string;
	bio:string
}

interface ErrorArgs {
	msg:string
	field:string
	errors:any
}

const ShowError = ({ msg, field, errors }:ErrorArgs) => {
	return Object.keys(errors).includes(field)
	       ? <label className="block mb-2 text-alt-3">
		       {msg}!
	       </label>
	       : <label className="block mb-2 text-alt-3">
		       {msg}
	       </label>
}


export const Setup = () => {
	const isLocal = useIsLocal()
	const { register, handleSubmit, control, } = useForm<IFormInput>();
	const { isSubmitting, errors } = useFormState({ control });
	// names
	const { user } = FveApp.useUser();
	const { show, shutDown, isOpen } = useModal();
	const [profileUrl, setProfile] = useState(user.photo)
	const [src, setSrc] = useState<any>(undefined);
	
	const { linkInfo } = ProfileService.useProfileLoadLink();
	
	useEffect(() => {
		setProfile(user.photo)
		console.log('** user', user)
	}, [user])
	
	const onSelected = (file:any) => {
		invariant.log(file);
		setSrc(undefined);
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			setSrc(reader.result);
			show();
		});
		reader.readAsDataURL(file);
	};
	
	const modalUpdating = () => {
		setProfile(jumpFish)
		shutDown()
	}
	
	const modalSaved = async () => {
		setProfile(user.photo)
		setEditMode(false)
		await setUser({})
	}
	
	const [editMode, setEditMode] = useState(false)
	const { setUser, fetching, data: userData, error } = useUpdateUser()
	
	const onSubmit:SubmitHandler<IFormInput> = async (data) => {
		console.log(`** updateUser resp`, data)
		if (Object.keys(errors).length === 0) {
			await setUser({ ...data })
			setEditMode(false)
		}
	}
	const hocSubmit = handleSubmit(onSubmit)
	
	// === get the practices ======
	const { getMyPractices, data: myPractices } = useGetPractices()
	useEffect(() => {
		if (user) {
			(async () => {await getMyPractices(user.id as any)})()
		}
	}, [getMyPractices, user])
	
	return (
		<>
			<Container className="pt-24">
				<div className="grid grid-cols-4 gap-4">
					<div className="col-span-1">
						<div>
							<ProfileImage url={profileUrl} editing={editMode}
							              callback={onSelected}/>
						</div>
						
						{!editMode && !fetching &&

						<div className="text-2xl text-alt-2 font-thin pt-8">

							<EditHeader label="Edit Profile" onClick={() => setEditMode(true)}/>

							<div className="py-2">
								<div className="flex flex-row items-centers text-lg">
									<FaIdCard className="mt-1 mr-2"/>
									<div className=" text-alt-1">{user.name || "(name)"}</div>
								</div>
								<div className="flex flex-row items-centers text-lg">
									<FiMail className="mt-1 mr-2"/>
									<div className="text-lg text-alt-1">{user.email}</div>
								</div>
							</div>


							<div className="my-2">
								<Label>Bio</Label>
								<div className="text-lg text-alt-1">{user.bio || ""}</div>
							</div>

						</div>
						}
						
						
						{
							editMode && !isSubmitting &&
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="pt-8">
									<div className="mb-4">
										<ShowError errors={errors} msg="Display Name" field="name"/>
										<input
											type="text"
											defaultValue={user.name}
											{...register("name", {
												required: true,
												minLength: {
													value: 5,
													message: 'This input is number only.',
												}
											})}
											className="fish-input"
											placeholder="Your Name"
										/>
									</div>

									<div className="mb-4">
										<ShowError errors={errors} msg="Email" field="email"/>
										<input
											type="email"
											defaultValue={user.email}
											{...register("email", {
												pattern: emailReg,
											})}
											className="fish-input"
											placeholder="Your Email"
										/>
									</div>

									<div className="mb-4">
										<ShowError errors={errors} msg="Bio" field="bio"/>
										<textarea
											style={{ height: "7rem" }}
											defaultValue={user.bio}
											{...register("bio", {
												maxLength: 256
											})}
											className="fish-input py-2"/>
									</div>
									<div>
										<DataError data={userData} error={error}/>
									</div>
									<SaveCancel
										saving={fetching}
										onSave={hocSubmit}
										onCancel={() => setEditMode(false)}/>

								</div>

							</form>
							
						}
						<Credential id={user.hashId}/>
					</div>
					<div className="col-span-3 ">
						
						<div className="bg-main-3 p-4 rounded text-alt-2">
							<div className="text-lg">History</div>
							<div>{user.sessionId}</div>
							<RecordsViewer records={cleanUpPosts(myPractices)}/>
							<pre>
									{yamlObj(cleanUpPosts(myPractices))}
								</pre>
						</div>
						{isLocal &&
						<div className="text-alt-3">
								<pre>
									{yamlObj(userData)}
								</pre>
							<pre>
									{yamlObj(error)}
								</pre>
						</div>
						}
					</div>
				</div>
			</Container>
			
			
			<Container className="pt-24">
				<Modal
					shutDown={shutDown}
					isOpen={isOpen}
					title="Specify Profile Image"
				>
					
					{isOpen && linkInfo && <AvatarCutter src={src} link={linkInfo}
					                                     saved={modalSaved}
					                                     postSaving={modalUpdating}/>}
				</Modal>
			</Container>
		</>
	);
};
