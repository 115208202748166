import { PeerConnection } from "activeDoc/types"
import React from "react"

interface args {
	peer: PeerConnection
}
export const ConnectionPad = (props: args) => {
	const {peer} = props
	return (
		<div className="flex flex-row w-32 items-baseline gap-2 bg-main-2 p-3 rounded">
			<div>
				{peer.connection.peer}
			</div>
			<div className="text-alt-3 text-xs">
				{peer.inward? "in": "out"}
			</div>
		</div>
	)
}
