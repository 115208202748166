import axios, { AxiosRequestConfig } from "axios";
import { gql } from "graphql-request";
import { invariant } from "logs";
import { PasswordActionArgs } from "./typesAndUtils"
import { IUser } from "services/types"
import { FveApp } from "../fveApplication";

export async function signIn (username:string, password:string) {
	const options:AxiosRequestConfig = {
		method: "POST",
		url: FveApp.endPoints.signIn,
		data: { username, password }
	};
	invariant.log("signIn options", options)
	const resp = await axios.request(options);
	const { data } = resp;
	return data;
}

export async function passwordAction (email:string, action:"reset" | "create") {
	const options:AxiosRequestConfig = {
		method: "POST",
		url: FveApp.endPoints.passwordAction,
		data: { email, action }
	};
	const resp = await axios.request(options);
	const { data } = resp;
	return data;
}

export async function actOnPassword (args: PasswordActionArgs) {
	const options:AxiosRequestConfig = {
		method: "POST",
		url: FveApp.endPoints.passwordAction,
		data: args
	};
	const resp = await axios.request(options);
	const { data } = resp;
	return data;
}




export async function getProfile (email:string) {
	const query = `query {
		user (where: {email: {_eq: "${email}"}}) {
			id
			email
			name
			photo
			handle
		}
	}`
	const client = FveApp.getClient(true);
	const result:Partial<IUser> = await client.request(
		gql`
            ${query}
		`
	);
	invariant.log('received from server', result)
	return result;
}


