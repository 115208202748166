import { useMatchingUrl } from "hook"
import React from "react"

const cn = require("classnames")

interface args {
	onClick?: Function
	matching?: string
	children: any
}

export const NavItem = (props:args) => {
	const { children, onClick, matching } = props
	const selected = useMatchingUrl(matching || "-----")
	const className = cn(
		"my-auto",
		"nav-item px-2",
		{ "nav-item-selected": selected }
	)

	const handler = () => {
		if (onClick) onClick()
	}
	return (<div className={className} onClick={handler}>{children}</div>)
}

