import { useEffect } from "react"

export default function useKeypress (key:string, action:Function,  withShift: boolean = false) {
	useEffect(() => {
		function onKeyup (e:any)
		{
			const matchShift = e.shiftKey || (!withShift)
			if (e.key === key && matchShift) {
				e.preventDefault()
				action()
			}
		}
		window.addEventListener('keyup', onKeyup);
		return () => window.removeEventListener('keyup', onKeyup);
	}, [action, key, withShift]);
}


