import { gql, GraphQLClient } from "graphql-request";
import { invariant } from "logs";
import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { FveSession, IUser } from "./types";

const endPointDomain =
	process.env.REACT_APP_API_ENDPOINT ||
	"https://9iieymofzh.execute-api.us-east-1.amazonaws.com";

const wpEndPoint = process.env.REACT_APP_WP_ENDPOINT ||
	"https://fishbank.theideallabs.com/graphql"


const sessionKey = "fve-v1";

const endPoints = {
	fish2: `${endPointDomain}/graphql`,
	wpGraphql: wpEndPoint,
	// createSession: `${endPointDomain}/session`,
	// pullingSession: `${endPointDomain}/p`,
	signIn: `${endPointDomain}/signIn`,
	passwordAction: `${endPointDomain}/password`
};

let session:FveSession = {
	jwt: "",
	user: {} as any,
	iss: ""
};

function setUser (user:IUser):void {
	session = { ...session, user };
	persistSession();
	updateUser(user);
}


function setSessionJwt (jwt:string, iss:string) {
	session = { ...session, jwt, iss };
	invariant.log('**** set session', session)
	persistSession();
}

// todo: we may need to replace this graphql-request
const getClient = (withJwt:boolean = true) => {
	const client = new GraphQLClient(endPoints.fish2);
	if (withJwt) {
		client.setHeader("authorization", `Bearer ${session.jwt}`);
	}
	return client;
};

function persistSession () {
	// localStorage.setItem(sessionKey, JSON.stringify(session));
	sessionStorage.setItem(sessionKey, JSON.stringify(session));
}

function restoreSession () {
	// const raw = localStorage.getItem(sessionKey);
	const raw = sessionStorage.getItem(sessionKey);
	if (raw) {
		session = JSON.parse(raw);
		updateUser(session.user);
		//todo: restore pubnub too
	}
}

function getCurrentJwt () {
	return session.jwt;
}


function getWpEndpoint () {
	const rtn = `${session.iss}/graphql`
	invariant.log('**** -- fish graphql endpoint', rtn)
	return rtn
}


function dumpSession () {
	invariant.log(`...current session is`, session);
}

const user$:BehaviorSubject<Partial<IUser>> = new BehaviorSubject<Partial<IUser>>({});


function updateUser (info:Partial<IUser>) {
	const u = user$.getValue();
	const merged = { ...u, ...info };
	user$.next(merged);
}

function logout () {
	user$.next({});
}

export const useUser = () => {
	const [user, setUser] = useState<Partial<IUser>>(user$.getValue());
	useEffect(() => {
		const sub = user$.subscribe((u) => {
			setUser(u);
		});
		return () => sub.unsubscribe();
	}, []);
	return { user };
};


const query = async <T> (queryString:string) => {
	const client = getClient(true);
	return await client.request(gql`${queryString}`) as T
}

export const FveApp = {
	setUser,
	setSessionJwt,
	endPointDomain,
	endPoints,
	getClient,
	dumpSession,
	restoreSession,
	user$,
	updateUser,
	useUser,
	getCurrentJwt,
	query,
	logout,
	getWpEndpoint
};
