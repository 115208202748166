import { useState, useMemo, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { actOnPassword } from "services/api"

interface Effect {
	readonly email?: string
	readonly token?: string
	readonly errorMessage?: string
}

export const useToken = () => {
	const location = useLocation()
	const [data, setData] = useState<Effect>({})
	const [loading, setLoading] = useState(false)
	const [hasData, setHasData] = useState(false)
	const token = useMemo(() => {
		const { state, state: { token } } = location as any
		console.log(`use memo state`, state)
		console.log(`use memo token`, token)
		return token
	}, [location])
	useEffect(() => {
		console.log(`location....`, location.pathname)
		setLoading(true)
		actOnPassword({ action: "verify", token })
			.then(d => {
				setData(d);
				setLoading(false)
				setHasData(true)
			})
			.catch(e => {
				setData({errorMessage: e.message, error: e} as any);
				setLoading(false)
				setHasData(true)
			})
	}, [token])
	return { token, data, loading, hasData }
}
