import React from 'react';
import { RiWifiOffLine, RiWifiFill } from 'react-icons/ri'
import { useMeet } from 'services/api';
import cn from "classnames";


// todo: we need to find a each status and switch for status ....
export const OnOff = () => {
	const { meetStatus } = useMeet()

	const panelClass = cn(["flex", "flex-row", "items-center", "justify-around", "p-1"])
	const iconClass = cn(["mx-1 p-1 rounded-2xl",
		{
			"bg-green-300": meetStatus === "FREE",
			"bg-red-900": meetStatus === "BUSY"
		}])
	const Icon = () => (meetStatus === "FREE") ? <RiWifiFill /> : <RiWifiOffLine />
	const Label = () => (meetStatus === "FREE") ? <div>Online</div> : <div>Offline</div>
	return <>
		<div className={panelClass}>
			<div className={iconClass}>
				<Icon />
			</div>
			<Label />
		</div>
	</>

}