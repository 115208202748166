import { IconButton } from "fui"
import React from "react"
import { FiX } from "react-icons/fi"
import { ModalInner } from './model-inner'

const cn = require("classnames")

interface ModalArgs {
	isOpen:boolean
	shutDown:Function
	children?:any
	title?:string
	keepOpenClickOutside?:boolean
}

export const Modal = (props:ModalArgs) => {
	const { shutDown, children, isOpen, title, keepOpenClickOutside } = props
	const classNames = cn(
		['text-main-4', 'modal', { 'modal-show': isOpen }, { 'modal-hidden': !isOpen }])
	
	return <div className={classNames}>
		{isOpen && <div className="modal-up-100 bg-main-3 rounded shadow-md">
			<div className=" flex-row flex justify-between m-3">
				<div
					className="font-thin mb-6 text-center text-xl  spacing-2 text-alt-2 pt-1 pl-2 ">
					{title || "no title"}
				</div>
				<IconButton
					className="p-2"
					variant="link"
					onClick={() => shutDown}
				>
					<FiX/>
				</IconButton>
			</div>
			<div className="px-4 ">
				<ModalInner shutDown={shutDown} keepOpenClickOutside={keepOpenClickOutside}>
					{children}
				</ModalInner>
			</div>
		</div>}
	</div>
}
