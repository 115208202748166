import arrow from "assets/images/yellow-arrow.png"
import timerInHead from 'assets/images/timer-in-head.svg'
import React from "react"
import { FacePad } from "./face-pad"


export const CardsSetup = () => {
    return (
        <div className="container text-alt-2 mx-auto pt-24">
            <div className="mb-8 flex flex-row items-center"> 
             <img src={timerInHead} className="w-16 mr-4" alt=""/> Memorization</div>
     
            <div className="grid grid-cols-1">
                <div className="bg-main-3 p-8 rounded">
                    <div className="flex flex-row justify-between items-center ">
                        <img
                            className="w-32 point-left-down"
                            src={arrow}
                            alt=""
                        />
                        <div className="font-hand text-xl w-full text-right">
                            Remember all numbers in sequence before time is
                            running out. 
                        </div>
                      

                    </div>
                    <div className="flex flex-row justify-between items-baseline">
                        <div className="text-2xl text-alt-1 my-4">
                            
                        </div>
                        <div className="text-lg">
                            12
                            <span className="text-green-600 ml-2">s</span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-1">
                        <FacePad num={3} /> <FacePad num={7} />{" "}
                        <FacePad num={2} />
                        <div className="mx-auto">...</div>
                        <FacePad num={4} />
                    </div>
                </div>
            </div>
        </div>
    )
}
