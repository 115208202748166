import { useSubs } from "hook"
import React, { useEffect, useState } from "react"
import { timer } from "rxjs"
import { map, tap } from "rxjs/operators"
import { unsubscribeAll } from "services"

export const SimpleCountdown = () => {
    const [num, setNum] = useState("!")
    const subs = useSubs()
    useEffect(() => {
        unsubscribeAll(subs)()
        let cnt = 10
        const count = () => {
            cnt--
            if (cnt <= 0) cnt = 10
        }
        const timerSub = timer(100, 1000)
            .pipe(
                tap(count),
                map(() => cnt)
            )
            .subscribe((c: any) => setNum(c))
        subs.push(timerSub)
        return unsubscribeAll(subs)
    }, [subs])
    return <div className="cursor-pointer">{num}s</div>
}

export const CountdownNum = () => {
    return (
        <div className="text-3xl font-extrabold w-1/2  rounded box-box game-card game-card-back game-card-back">
            <div
                className="text-center m-1 text-alt-4"
                style={{ background: "transparent" }}
            >
                <SimpleCountdown />
            </div>
        </div>
    )
}
