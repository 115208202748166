
export interface ClockInfo {
    hours: number
    minutes: number
    seconds: number
    display: string
}


const whenTimesUp : ClockInfo = {
    hours: 0, minutes: 0, seconds: 0, display: 'times up'
}

export function timeConversion(duration: number): Partial<ClockInfo> {
    if (duration === 0) return whenTimesUp
    const portions: string[] = []
    const msInHour = 1000 * 60 * 60
    const hours = Math.trunc(duration / msInHour)
    if (hours > 0) {
        portions.push(hours + "h")
        duration = duration - hours * msInHour
    }

    const msInMinute = 1000 * 60
    const minutes = Math.trunc(duration / msInMinute)
    if (minutes > 0) {
        portions.push(minutes + "m")
        duration = duration - minutes * msInMinute
    }

    const seconds = duration / 1000;
    if (seconds >= 0) {
        portions.push(seconds.toFixed(2) + " s")
    }
    const result = {
        hours,
        minutes,
        seconds,
        display: portions.join(" "),
    }
    return result
}
