import { addSeconds, differenceInMilliseconds } from 'date-fns'
import { useSubs } from "hook/use-subs"
import { invariant } from 'logs'
import { useEffect, useMemo, useState } from "react"
import { BehaviorSubject, from, interval, Observable, ObservedValueOf, timer } from "rxjs"
import { distinctUntilChanged, filter, map, shareReplay, take, tap } from "rxjs/operators"
import { PracticeSetup } from "services/gameTypes"
import { nextTimeToAct } from "services/match/machine"
import {
	blankStageConfig, CountDownInfo, randId, StageConfig, unsubscribeAll
} from "services/utils"
import { createMachine, interpret, Interpreter } from "xstate"

interface MatchContext {
	id:string
	fish:string
	elephant:string
}

type MatchEvent =
	| { type:"START_TO_MEMORIZE" }
	| { type:"START_TO_MATCH"; data:object }
	| { type:"MATCH_DONE", data:object }
	| { type:"ABORT" }
	| { type:"START_OVER" }


export type MatchState =
	{ value:"standby"; context:MatchContext }
	| { value:"memorization"; context:MatchContext }
	| { value:"matching"; context:MatchContext }
	| { value:"done"; context:MatchContext }
	| { value:"abort"; context:MatchContext }


export type TimeFunction = (milliseconds:number) => void

class MatchMgr {
	readonly state$:Observable<ObservedValueOf<any>>
	readonly service:Interpreter<MatchContext, any, any, { value:string; context:MatchContext }>;
	subs:any[] = [];
	countDownInfo$:BehaviorSubject<CountDownInfo> = new BehaviorSubject<CountDownInfo>(
		{ stage: "", minutes: 0, seconds: 0 })
	stageConfig$:BehaviorSubject<StageConfig> = new BehaviorSubject<StageConfig>(blankStageConfig())
	private practice?:PracticeSetup = undefined
	private activeMark:Date = new Date()
	private diffBtwMarks:number = 0
	private opponentCallback?:Function = undefined
	
	constructor (private fish:string, private elephant:string)
	{
		const matchMachine = createMachine(
			{
				initial: "standby",
				context: {} as MatchContext,
				states: {
					standby: {
						invoke: { id: "resetAll", src: this.resetAll },
						on: { START_TO_MEMORIZE: { target: "memorization" } }
					},
					memorization: {
						invoke: {
							id: "memorizationCountdown", src: this.memorizationCountdown
						},
						on: {
							START_TO_MATCH: { target: "matching" },
							START_OVER: { target: "standby" }
						}
					},
					matching: {
						invoke: { id: "matchingCountDown", src: this.matchingCountDown },
						on: {
							MATCH_DONE: { target: "done" },
							ABORT: { target: "abort" },
							START_OVER: { target: "standby" }
						}
					},
					done: {
						on: { START_OVER: { target: "standby" } }
					},
					abort: {
						on: { START_OVER: { target: "standby" } }
					}
				}
			})
		this.service = interpret(matchMachine).start();
		this.state$ = from(this.service as any);
	}
	
	startMatching = (practice:PracticeSetup) => {
		this.practice = practice
		invariant.log(`the set up of practice is ...`, practice)
		this.service.send({ type: "START_TO_MEMORIZE" })
	}
	
	memorizationCountdown = async (context:MatchContext, event:MatchEvent) =>
	{
		const duration = (this.practice?.isLocal) ? 5 : 30
		this.genericCountDown("memorization", duration, { type: "START_TO_MATCH", data: {} })
	}
	
	matchingCountDown = async (context:MatchContext, event:MatchEvent) => {
		const duration = (this.practice?.isLocal) ? 30 : this.practice!.length * 60
		this.genericCountDown("matching", duration, { type: "MATCH_DONE", data: {} })
	}
	
	nextActiveMark = () => {
		if (this.practice?.isLocal) {
			return addSeconds(new Date(), 5)
		}
		return addSeconds(new Date(), nextTimeToAct(this.practice?.level as any) / 1000)
	}
	
	requireCallback = () => (this.elephant === "machine" && this.service.state.value === "matching")
	
	setActiveMark = (d:Date) => {
		this.diffBtwMarks = differenceInMilliseconds(d, this.activeMark)
		this.activeMark = d
	}
	getActiveMark = () => this.activeMark
	
	genericCountDown = (stage:string, duration:number, notification:MatchEvent) => {
		
		// --- the Web Audio API -------------------
		// const Dilla = require('dilla');
		// const audioContext = new AudioContext();
		// const dilla = new Dilla(audioContext, {
		// 	"tempo": 2,
		// 	"beatsPerBar": 1,
		// 	"loopLength": 1
		// });
		// dilla.on('tick', function (step: any) {
		// 	// do nothing at this time.
		// });
		//
		// dilla.start();
		
		const frequencyPerSec = 1
		const timerInterval$ = interval(1000 / frequencyPerSec); //1s
		const timer$ = timer(duration * 1000); //30s
		const times = duration * frequencyPerSec + 1;
		const deadline = addSeconds(new Date(), duration)
		
		
		if (this.requireCallback()) {
			this.activeMark = new Date()
			this.setActiveMark(this.nextActiveMark())
		}
		
		const subOpponent = timerInterval$.pipe(
			take(times),
			map(() => new Date()),
			shareReplay(),
			filter((tm) => (this.requireCallback() && tm > this.getActiveMark()))
		).subscribe(() => {
			if (this.opponentCallback) {
				this.opponentCallback(this.diffBtwMarks)
			}
			this.setActiveMark(this.nextActiveMark())
		})
		this.subs.push(subOpponent)
		
		const tillDeadline = (tm:Date) => {
			const till = Math.max(0, differenceInMilliseconds(deadline, tm))
			const minutes = Math.floor(till / 1000 / 60)
			const seconds = till / 1000 - minutes * 60
			return { stage, minutes, seconds }
		}
		
		const infoSub = timerInterval$.pipe(
			map(() => new Date()),
			map((tm) => (tillDeadline(tm))),
		).subscribe((info) => {
			const stage:StageConfig = {
				stage: info.stage,
				startsAt: new Date(),
				endsAt: deadline,
				duration: duration
			}
			this.stageConfig$.next(stage)
			this.countDownInfo$.next(info)
		})
		this.subs.push(infoSub)
		setTimeout(() => {infoSub.unsubscribe()}, duration * 1000)
		
		const timeIsUp = timer$.subscribe(val => this.service.send(notification));
		this.subs.push(timeIsUp)
	}
	
	tearDown = () => {
		this.subs.forEach(s => s.unsubscribe())
		this.subs = []
	}
	
	setOpponentCallback = (cb:TimeFunction) => {this.opponentCallback = cb}
	
	resetAll = async () => {
		this.tearDown()
	}
	
	startOver = () => {
		this.service.send({ type: "START_OVER" })
		unsubscribeAll(this.subs)()
		this.subs = []
	}
	
}


export const useMatchState = (fish:string, elephant:string, opponentCallback?:TimeFunction, gameCompletedCallback?:Function) => {
	const [state, setState] = useState<any>()
	const [gameStarted, setGameStarted] = useState<boolean>(false)
	const gameStartHandler = (value:any) => {setGameStarted(value !== "standby")}
	const [countDown, setCountDown] = useState<CountDownInfo>(
		{ stage: "", seconds: 0, minutes: 0 })
	const [stageConfig, setStageConfig] = useState<StageConfig>(blankStageConfig())
	const subs = useSubs()
	const mgr = useMemo(() => {
		unsubscribeAll(subs)()
		const mgr = new MatchMgr(fish, elephant)
		const unsubscribable = true
		if (unsubscribable) {
			let cachedStates:any
			subs.push(
				mgr.state$.subscribe((state) => setState(state)),
				mgr.state$.pipe(map((state:any) => state.value)).subscribe(gameStartHandler),
				mgr.state$.pipe(
					tap((state) => {cachedStates = state}),
					map((state:any) => state.value), distinctUntilChanged(),
					filter(stage => stage === 'done')
				).subscribe((stage) => {
					// we should pass some callback handler
					// invariant.log(`*** subscribed`, stage)
					if (gameCompletedCallback) {
						gameCompletedCallback(cachedStates)
					}
				}),
				mgr.countDownInfo$.subscribe(s => {
					setCountDown(s)
				}),
				mgr.stageConfig$.subscribe(s => {
					setStageConfig(s)
				})
			)
		} else {
			mgr.state$.subscribe((state) => setState(state))
			mgr.state$.pipe(map((state:any) => state.value)).subscribe(gameStartHandler)
			mgr.countDownInfo$.subscribe(s => {
				setCountDown(s)
			})
			mgr.stageConfig$.subscribe(s => {
				setStageConfig(s)
			})
		}
		
		
		if (opponentCallback) {
			mgr.setOpponentCallback(opponentCallback)
		}
		return mgr
	}, [elephant, fish, gameCompletedCallback, opponentCallback, subs])
	useEffect(() => {return () => mgr.tearDown()}, [mgr])
	const [id] = useState(randId())
	return {
		id,
		state,
		state$: mgr.state$,
		start: mgr.startMatching,
		countDown,
		startOver: mgr.startOver,
		stageConfig,
		gameStarted,
		setOpponentCallback: mgr.setOpponentCallback
	}
}
