import { invariant } from 'logs'
import { BehaviorSubject } from 'rxjs'
import { getGameInstance } from "services/utils"
import { Action, Game } from "./gameTypes"

const deepMerge = require('deepmerge')


export const setAction = (act:Action, isCorrect:Boolean) => {
	const replacement:Action = { ...act, status: (isCorrect) ? "RESOLVED" : "LOST" }
	return replacement
}


const arrayWithId = (ar:any[]) => {
	return (ar.length === 0) ? false : (ar[0]["id"])
}

interface withId {
	readonly id:string
	readonly deleted?:boolean
}

function intersect (a:any[], b:any[]) {
	return a.filter(Set.prototype.has, new Set(b));
}

const findWithId = (collection:withId[]) => (id:string) => {
	return collection.find(item => item.id === id)
}
const mergeWithIds = (targets:withId[], sources:withId[]) => {
	const final:any[] = []
	const findTarget = findWithId(targets)
	const findSource = findWithId(sources)
	const mutual = intersect(targets.map(t => t.id), sources.map(s => s.id))
	mutual.forEach(id => {
		const target = findTarget(id)
		const source = findSource(id)
		if (!source?.deleted) final.push(deepMerge(target, source))
	})
	targets.filter(t => !mutual.includes(t.id)).forEach(t => final.push(t))
	sources.filter(s => !mutual.includes(s.id)).forEach(s => final.push(s))
	return final
}

interface WithIdAndDeletable {
	id:string
	deleted:boolean
}

const overwriteMerge = (destinationArray:any[], sourceArray:any[]) => {
	if (!arrayWithId(destinationArray) && !arrayWithId(sourceArray)) {
		return sourceArray
	}
	// note: special cleaning ....
	// const cloneTarget: WithIdAndDeletable[] = [...destinationArray]
	// const cloneSource: WithIdAndDeletable[] = [...sourceArray]
	// const toDeletedIds = cloneSource.filter(i => i.deleted).map(i => i.id)
	// const merged = mergeWithIds(cloneTarget.filter(i => !toDeletedIds.includes(i.id)),
	//                     cloneSource.filter(i => !toDeletedIds.includes(i.id))
	//                     )
	// invariant.log("** do you see the deleted?")
	// console.table(sourceArray)
	// console.table(merged)
	// return merged
	return mergeWithIds(destinationArray, sourceArray)
}

// todo: we need to merge array or objects by id

export const combineSnapshots = (...args:any[]) => {
	return args.reduce((a, c) => {
		return deepMerge(a, c, {
			arrayMerge: overwriteMerge
		})
	}, {})
}

class GamePubSub {
	publishing$:BehaviorSubject<Game> = new BehaviorSubject<Game>(getGameInstance())
	receiving$:BehaviorSubject<Game> = new BehaviorSubject<Game>(getGameInstance())
	
	publish (extra:Partial<Game>) {
		const next = combineSnapshots(
			this.receiving$.value, extra)
		this.onPublishing(next)
	}
	
	getCurrent ():Game {
		return this.receiving$.value
	}
	
	cleanUpReceiving () {
		this.receiving$.next({ ...this.getCurrent(), actions: [] })
	}
	
	private onPublishing (state:Game) {
		this.publishing$.next(state)
	}
	
	
}


export const PubSub = new GamePubSub()
