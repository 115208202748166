import { Button, Ranking } from "fui";
import React from "react";
import { fromToday, matchHelper } from "services";
import { IUser } from "services/types";
interface OnlineUserArgs {
	user: IUser;
}

export const OnlineUser = (props: OnlineUserArgs) => {
	const { user } = props;
	const onKnock = async () => {
		await matchHelper.knockKnock(user);
	};
	return (
		<div
			key={user.sessionId || Math.random()}
			className="bg-main-2 p-4 rounded"
		>
			<div className="flex flex-row">
				<div className="flex flex-col">
					<img
						className="w-16 rounded-lg mr-2"
						src={user.photo}
						alt={""}
					/>
				</div>
				<div className="flex flex-col">
					<div className="font-bold">{user?.name}</div>
					<div> {fromToday(user?.joinedAt || user?.registeredDate)}</div>
					<div>
						<Ranking full={3} half={true} />
					</div>
				</div>
				<div className="ml-auto my-auto">
					<Button label={"Invite"} onClick={onKnock} />
				</div>
			</div>
		</div>
	);
};
