import { ActiveRepo, PeerSync } from "activeDoc"
import { Container } from "containers"
import { ActionList } from "containers/actions/action-list"
import { ActiveHost } from "containers/active-host"
import { GameBoard2 } from "containers/pad"
import { BarLoader } from "fui"
import { CountDownClock, ScoreDiv } from "fui/count-down-clock"
import {
	useActionResolver, useActiveGame, useAsHandViewer, useConstant, useIsLocal, useMatch, useSubs,
} from "hook"
import useKeypress from "hook/use-key-pressed"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getCurrentMatchNub, getRandomCoset, initRepo, unsubscribeAll } from "services"
import { StateInfo } from "services/active-machine"
import { peerSync$, startPeerInstance } from "services/api"
import { gameTempos, StageCountdown } from "services/stage-countdown"
import { Flag } from "./flag"
import { hostHandler, peerHandler } from "./handlers"

function stateReaction(
	mode: string,
	repo: ActiveRepo,
	freshRepo: Function,
	current?: StateInfo
) {
	if (mode === "peer") {
		; (async () => {
			await peerHandler(repo, current)
		})()
	}
	if (mode === "host") {
		; (async () => {
			await hostHandler(freshRepo, current)
		})()
	}
}

function getPeerInstanceId() {
	return peerSync$.getValue().instanceId()
}

const CurrentState = (props: { state?: StateInfo }) => {
	return <div>{props.state?.value || ""}</div>
}

function startMachine(
	mode: string,
	host: string,
	peerAddress: string,
	setPeerInstance: (value: ((prevState: string) => string) | string) => void
) {
	startPeerInstance(mode === "host" ? host : peerAddress)
	setPeerInstance(getPeerInstanceId())
	if (mode === "host") {
		const s = getCurrentMatchNub()
		s.sendMatchMsg(`event://HOST_READY`).then(() => { })
	}
}

const fishOrElephant = (mode: string) => {
	return mode === "host" ? "elephant" : "fish"
}

export const ActiveMatch = () => {
	const isLocal = useIsLocal()
	const [showViewer, toggleViewer] = useState(false)
	useKeypress("F8", () => { toggleViewer(!showViewer) }, true)
	const subs = useSubs()
	const countdown = useConstant(() => new StageCountdown())
	const { mode, host, peer: peerAddress, matchId } = useParams() as any
	const [current, sendStateEvent] = useMatch({
		mode,
		host,
		peer: peerAddress,
		matchId,
	})
	const [peerInstanceId, setPeerInstance] = useState("")
	const [peer, setPeer] = useState<PeerSync>()
	useEffect(() => {
		startMachine(mode, host, peerAddress, setPeerInstance)
		setPeer(peerSync$.getValue())
	}, [peerAddress, mode, sendStateEvent, host])
	const [repo, freshRepo] = useState(() => initRepo())
	useActionResolver({ repo: repo, player: fishOrElephant(mode) })
	const { update, game } = useActiveGame({ repo: repo, sync: peer })
	const { faces, actions, score } = useAsHandViewer(
		repo,
		fishOrElephant(mode)
	)
	useEffect(() => {
		unsubscribeAll(subs)()
		const player = fishOrElephant(mode)
		const isHost = mode === "host"
		stateReaction(mode, repo, freshRepo, current)
		if (current?.value === "repoSynced") {
			countdown.countdown(isLocal ? gameTempos.local : gameTempos.easy)
		}

		subs.push(
			countdown.stage$?.subscribe((stage) => {
				update.setStage(stage)
				if (stage === "PREP" && isHost) {
					update.newGame(10, getRandomCoset())
				}
				if (stage === "MATCH") {
					update.startTheFirstMove(player)
				}
			})
		)
	}, [current, mode, repo, freshRepo, countdown, isLocal, subs, update])

	return (
		<Container className="pt-20">
			<div className="flex flex-row text-alt-2 px-4 justify-start items-center">
				<div className="flex flex-row items-center justify-around mr-2">
					<span className="mr-2">{host} </span>
					<Flag source={host} connectTo={peerInstanceId} />
				</div>
				<div className="flex flex-row items-center justify-around">
					<span className="mr-2">{peerAddress} </span>
					<Flag source={peerAddress} connectTo={peerInstanceId} />
				</div>
				<div className="">
					<span>{repo.id}</span>
				</div>
				<div className="mx-auto flex flex-row justify-around">
					<CountDownClock info$={countdown.countdown$} />
					<ScoreDiv score={score} />
				</div>
				<div className="ml-auto">
					<CurrentState state={current} />
				</div>

				<BarLoader />
			</div>

			{current && (
				<GameBoard2
					player={fishOrElephant(mode)}
					mode="PLAY"
					repo={repo}
					faces={faces}
					actions={actions}
				/>
			)}

			<ActionList visible={true} actions={actions} />
			{peer && (isLocal || showViewer) && (
				<div className="bg-main-2 p-3 rounded">
					<div className="text-3xl">
						Document{" "}
						<span className="text-xs text-alt-3">
							{peer.instanceId()}
						</span>
					</div>
					<ActiveHost peer={peer} />
				</div>
			)}
		</Container>
	)
}
