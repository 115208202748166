import { ActiveRepo } from "activeDoc"
import * as automerge from "automerge"
import { useMemo } from "react"
import { filter, map } from "rxjs/operators"
import { drawNonTaken, findTakenNumbers, Game, drawNextRnd } from "services"
import invariant from "ts-invariant"
import { useSubs } from "./use-subs"

interface Args {
	repo:ActiveRepo,
	player:string
}


// When a ACTION is inserted with status "ATTACK"
// we need to fill it up with concrete information
export const useActionResolver = (options:Args) => {
	const { repo, player } = options
	const subs = useSubs();
	useMemo(() => {
		
		const sub = repo.docSnapshot$.pipe(
			filter(d => d.id === "game"),
			map(d => d.doc as Game),
			filter(d => d.actions.some(a => a.player === player && a.status === "ATTACK"
			)),
		).subscribe(
			(game:Game) => {
				const { actions } = game
				const range = repo.getDoc("game")!.fish.length
				const taken = findTakenNumbers(actions, player)
				const idx = drawNonTaken(range, taken)
				const actInAttack = game.actions.find(a => a.status === "ATTACK")
				const diff = (new Date().valueOf()) - actInAttack!.startedAt
				const actId = actInAttack!.id
				const reason = `switch ATTACK to RESOLVING for ${actInAttack!.id} (${actInAttack!.cardIdx}) (card #: ${idx}) spotted after ${diff}`
				const nextVersion = automerge.change(
					repo.getDoc("game"), `try to resolve the ATTACK action`, (snapshop:Game) => {
						// patch the attack
						const actInAttack = snapshop.actions.find(a => a.id === actId)
						invariant.log(`[resolve] ${repo.id} / g:${game.id} / snap: ${snapshop.id} / a: ${actId} at ${diff}`, (actInAttack)? "found" : "not-found" )
						Object.assign(actInAttack, {
							cardIdx: idx, status: 'RESOLVING',
							taken: diff,
							startedAt: new Date().valueOf(), nextRnd: drawNextRnd(actInAttack!.coset),
						})
					})
				if (nextVersion) {
					repo.possible("game", nextVersion, reason)
				}
			}
		)
		subs.push(sub)
	}, [player, repo, subs])
	return {}
}
