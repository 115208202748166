import { Game, GameMode } from "services/gameTypes"
import { first2Move, getGameInstance, systemAction } from "services/utils"

function startNewPractice(numOfCards:number = 20, mode: GameMode) {
	const instance = getGameInstance(numOfCards)
	const game: Game = {
		...instance, stage: "PREP", actions: [systemAction(instance.coset), ...first2Move(instance)]
	}
	return game
}

export const GameFactory = {
	startNewPractice
}
