import useKeypress from "hook/use-key-pressed"
import React from "react"
import { Action, GameStage, PlayMode } from "services"

interface GuessArg {
	value:number | string
	action?:Action
	stage:GameStage
	activeCallback?:Function
	answerCallback?:Function
	actionId?:string
	showPopup?:boolean
	mode:PlayMode
	guessKey?:string
}

export const GuessCard = (props:GuessArg) => {
	const { value, guessKey } = props
	const { answerCallback } = props
	const determineAnswer = () => {
		if (answerCallback) {
			answerCallback(value)
		}
	}
	const showKey = (value.toString() !== guessKey)
	useKeypress(guessKey || "", determineAnswer)
	
	return (
		<div className="flex-wrap flex">
			<div className="text-center w-full">
				<div
					className="w-1/2 mx-auto my-auto rounded  game-card guess-card guess-card-relative"
					onClick={determineAnswer}>{value}
					{
						showKey && <div className="guess-card-key">
							{guessKey}
						</div>
					}
				</div>
			</div>
		</div>
	)
	
	
}
