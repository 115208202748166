import { formatDistanceToNow } from "date-fns"
import { Button, Tabs } from "fui";
import { AvailabilityDropdown } from "fui/availability-dropdown";
import React, { useEffect, useState } from "react";
import { FaFish } from 'react-icons/fa'
import { openForMeet, useMeet, useOpenMeet } from "services/api";
import { WhoMeet } from "./who-meet";
import { WhoOpen } from "./who-open";
import classnames from "classnames"
import { JoiningMeet } from "./joining-meet";
import { OnOff } from "./on-off";

export const Meet = () => {
	const { meetStatus } = useMeet()
	const isUnknown = meetStatus === "UNKNOWN"
	useEffect(() => {
		// getPersonalMeetStatus().then(() => { console.log("** we done personal status") })
		if (meetStatus === "UNKNOWN") {
			(async function () {
				await openForMeet()
			})()
		}
	}, [meetStatus])

	const [retrievedAt, setRetrieve] = useState<Date>(new Date());
	const { users, retrieveOpenMeets, fetching } = useOpenMeet();
	useEffect(() => {
		setRetrieve(new Date());
	}, [users]);
	const [tabView, setTabView] = useState<string>("Open");
	const onViewChange = (activating: string, deactivating: string) => {
		setTabView(activating)
	}
	const partClass = classnames(["inline-flex",
		"flex-row",
		"pl-4",
		"items-center",
		"mx-2"])


	return (isUnknown)
		? <JoiningMeet />
		: (
			<div className="pt-24 container mx-auto text-alt-3 ">
				<div className="flex flex-row items-center">

					<OnOff />
					<div className={partClass}>
						<div>Join: </div>
						<div>{formatDistanceToNow(retrievedAt)}</div>
					</div>



					<div className="mx-auto">&nbsp;</div>
					{
						!fetching &&
						<div>
							<Button
								icon="Refresh"
								isLoading={fetching}
								onClick={retrieveOpenMeets}
								label=""
							/>
						</div>

					}
					{
						fetching && <div>
							<FaFish className="rotate" />
						</div>
					}

				</div>
				<Tabs tabNames={["Open", "Meet"]} callback={onViewChange} />
				{(tabView === "Open") && <WhoOpen users={users || []} fetching={fetching} />}
				{(tabView === "Meet") && <WhoMeet />}
			</div>
		);
};

