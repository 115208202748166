import React, { useEffect, useState } from "react"
import { asPeerMessage } from "activeDoc/types"
import { Container } from "containers"
import { ActiveHost } from "containers/active-host"
import { IconButton } from "fui"
import { usePeerSync } from "hook/use-peer-sync"
import { invariant } from "logs"
import { randId } from "services"
import { startPeerInstance } from "services/api"
import { ConnectionPad } from './connectionPad'

const copy = require('clipboard-copy')

export const ActivePage = () => {
	useEffect(() => {startPeerInstance(randId())}, [])
	const { peer, send, instanceId, trace$, connectTo, connections } = usePeerSync({})
	const [peerAddress, setAddress] = useState("")
	const [msg, setMsg] = useState("")
	useEffect(() => {
		const sub = trace$.subscribe(d => invariant.log(d))
		return () => sub.unsubscribe()
	}, [peer, trace$])
	
	const connectToPeer = () => {
		connectTo(peerAddress)
	}
	const sendDataToPeer = () => {
		send({ ...asPeerMessage(msg), requireAck: true })
	}
	return (<div className="text-alt-2 pt-24">
		<Container className="">
			
			<div className="text-alt-4 font-extrabold text-3xl">Active Page</div>
			<div className="grid grid-cols-2 gap-4">
				<div>
					<div className="grid grid-cols-2 gap-8">
						<span className="pl-4">
							address: {instanceId}
						</span>
						<span>
							<IconButton variant="plain"
										className="w-24"
										onClick={async () => {
											await copy(instanceId)
										}}>copy</IconButton>
						</span>
					
					</div>
					<div className="grid grid-cols-2 gap-8 align-items-center m-4 rounded">
						<input className="fish-input" value={peerAddress}
							   placeholder="the other peer"
							   onChange={(e) => {setAddress(e.target.value)}}/>
						<IconButton variant="color" className="w-24 "
									onClick={connectToPeer}>Connect</IconButton>
					</div>
					<div className="grid grid-cols-2 gap-8 align-items-center m-4 rounded">
						<input className="fish-input" value={msg}
							   placeholder="set message data"
							   onChange={(e) => {setMsg(e.target.value)}}/>
						<IconButton variant="color" className="w-24 " onClick={sendDataToPeer}>send
							data</IconButton>
					</div>
				</div>
				<div>
					{connections.map((conn, i) => (<ConnectionPad key={i} peer={conn}/>))}
				</div>
			</div>
			
			
			<div className="bg-main-2 p-3 rounded">
				<div className="text-3xl">Document <span
					className="text-xs text-alt-3">{peer.instanceId()}</span></div>
				<ActiveHost peer={peer}/>
			</div>
		
		</Container>
	</div>)
}
