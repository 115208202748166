import { invariant } from 'logs';
import { BehaviorSubject, Subject } from "rxjs"
import { IUser, randId, sendInvitation, sendMessage } from 'services';

export type MatchMessage = {
	matchId:string
	host:string
	peer:string
}

export type OpponentInfo = Partial<IUser>

export const matchHost$:Subject<MatchMessage> = new Subject<MatchMessage>()
export const matchPeer$:Subject<MatchMessage> = new Subject<MatchMessage>()
export const justInvited$:BehaviorSubject<OpponentInfo> = new BehaviorSubject<OpponentInfo>({})

const createMatch = ():MatchMessage => {
	const matchId = randId()
	const host = randId()
	const peer = randId()
	return { matchId, host, peer }
}


const notifyPeer = async (msg:MatchMessage, target:string) => {
	const status = await sendMessage({ kind: "handshake", signal: "CONNECT", data: msg }, target)
	return status
}

const knockKnock = async (user:IUser) =>
{
	const status = await sendInvitation(user.sessionId)
	justInvited$.next(user)
	invariant.log("[knock]", status);
}


export const matchHelper = {
	notifyPeer,
	createMatch,
	knockKnock: knockKnock
}
