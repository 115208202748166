import fish from "assets/images/clown-fish.svg"
import { ToasterContainer } from "containers"
import { BarLoader, IconButton } from "fui"
import useKeypress from "hook/use-key-pressed"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { IoIosClose } from "react-icons/io"
import { useHistory } from "react-router-dom"
import { API, FveApp, jwtRespToUser, toaster$ } from "services"
import invariant from "ts-invariant"
import { RequestReset, Solution } from "./request-reset"

// dummy comments
const emailPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

const cn = require("classnames")

const error_message = cn("text-yellow-500", "mt-2", "text-right")

function notify (message:string) {
	if (message === "Not Found") {
		// the backend must be in the middle of updating Wordpress
		toaster$.next({
			              type: "WARNING",
			              id: Math.random().toString(),
			              main: "Please try again!",
			              subTitle: "the network is busy",
		              })
		
	} else {
		toaster$.next({
			              type: "WARNING",
			              id: Math.random().toString(),
			              main: "Sign In Error",
			              subTitle: "Please try again!",
		              })
	}
}

export const SignIn = () => {
	const {
		register, handleSubmit, watch,
		formState: { errors }
	} = useForm()
	// @ts-ignore
	let { email: emailInput } = watch(["email"])
	emailInput = emailInput ?? ""
	const foundMatch = emailInput.match(emailPattern)
	const history = useHistory()
	const [processing, setProcessing] = useState(false)
	const [title, setTitle] = useState("Sign In")
	const [solution, setSolution] = useState<Solution>("signIn")
	
	const onSignIn = async (data:{ username:any; password:any }) => {
		setProcessing(true)
		const { username, password } = data
		try {
			const resp = await API.signIn(username, password)
			invariant.log(`signIn response`, resp)
			
			// let's check if the backend is updating the wordpress
			const { message } = resp
			if (message) {
				notify(message)
				return
			}
			
			// deal with the real error ...........................
			const { errorMessage } = resp
			if (errorMessage) {
				// let user know what's wrong ....
				toaster$.next({
					              type: "WARNING",
					              id: Math.random().toString(),
					              main: "Sign In Error",
					              subTitle: errorMessage,
				              })
			} else {
				const { jwt, iss } = resp
				FveApp.setSessionJwt(jwt, iss)
				const replacingUser = jwtRespToUser(resp)
				FveApp.setUser(replacingUser)
				// to next step
				history.push("/meet")
			}
			setProcessing(false)
		}
		catch (e) {
			toaster$.next({
				              type: "WARNING",
				              id: Math.random().toString(),
				              main: "Something Wrong!",
				              subTitle: "Please try again.",
			              })
			setProcessing(false)
		}
		
	
	}
	
	const toSignUp = () => {
		// setSolution("signUp")
		// setTitle("Sign Up")
		history.push("/signUp")
	}
	
	const toPassword = () => {
		setSolution("retrieve")
		setTitle("Forget Your Password?")
	}
	
	const toSignIn = () => {
		setSolution("signIn")
		setTitle("Sign In")
	}
	
	const toHome = () => {
		history.push("/")
	}
	
	const onSubmit = (data:any) => onSignIn(data)
	
	useKeypress("Enter", handleSubmit(onSubmit))
	
	return (
		<div className="text-main bg-main-4 font-display h-screen login-panel pb-64">
			<div className="flex flex-right w-full justify-end">
				<IconButton
					className="mr-4 mt-4 text-3xl"
					variant="link"
					onClick={toHome}
				>
					<IoIosClose/>
				</IconButton>
			</div>
			<div className="container mx-auto h-full flex justify-center items-center">
				<div className="w-72  bg-main-1 pt-8 pb-8 pl-4 pr-4 flex flex-col justify-between">
					<div className="text-center">
						<img className="w-36 mx-auto" src={fish} alt=""/>
						<div className="text-alt-3 text-lg mt-2 font-bold">
							{title}
						</div>
					</div>
					
					{solution === "signIn" && (
						<div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<input
									type="text"
									{...register("username", { required: true, })}
									className="fish-input mt-4"
									placeholder="username or email"
								/>
								{errors.username && (
									<div className={error_message}>
										invalid user name
									</div>
								)}
								<input
									autoComplete="current-password"
									type="password"
									{...register("password", { required: true, })}
									className="fish-input mt-4"
									placeholder="Your password"
								/>
								{errors.email && (
									<div className={error_message}>
										must provide password
									</div>
								)}
								<hr className="my-4 border-gray-700"/>
								<div className="mt-4">
									{processing &&
									<BarLoader/>
									}
									{!processing && (
										<IconButton
											variant="color"
											onClick={handleSubmit(onSubmit)}
										>
											SUBMIT
										</IconButton>
									)}
								</div>
							</form>
						</div>
					)}
					{solution !== "signIn" && <RequestReset solution={solution}/>}
					
					<div className="flex flex-row justify-between mt-8">
						<div>
							{solution !== "retrieve" && (
								<IconButton variant="link" onClick={toPassword}>
									forgot password?
								</IconButton>
							)}
						</div>
						<div className="">
							{solution === "signIn" && (
								<IconButton variant="link" onClick={toSignUp}>
									sign up
								</IconButton>
							)}
							{solution === "retrieve" && (
								<IconButton variant="link" onClick={toSignIn}>
									member already
								</IconButton>
							)}
						</div>
					</div>
				</div>
			
			</div>
			<ToasterContainer/>
		</div>
	)
}
