import { Container } from "containers"
import { IconButton } from "fui"
import { useIsLocal } from "hook"
import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Sliding } from "routes/landing/intro/sliding"
import { setVerbosity } from "ts-invariant"
import { useRouteInstrument } from '../site-hub'
import { FishVsElephant } from "./intro/fish-vs-elephant"

export const Wave = () => {
	const isLocal = useIsLocal()
	const history = useHistory()
	const location = useLocation()
	const { forward } = useRouteInstrument()
	useEffect(() => {
		const { hash } = location
		if (hash) {
			const [_hashMark, category, method, ...params] = hash.split("/")
			forward({ category, method, params })
		}
	}, [forward, location])
	useEffect(() => {
		if (isLocal) {
			setVerbosity("log")
		} else {
			setVerbosity("warn")
		}
	}, [isLocal])
	
	return (
		<>
			<div className="flex flex-col justify-end h-full">
				<div
					className="text-left text-xs container font-thin p-2 mx-auto text-light text-alt-3">
					The Ideal Labs
				</div>
				<FishVsElephant/>
				<div className="flex-grow flex flex-col justify-around mt-8">
					<div className="text-center container mx-auto font-display">
						<Container className="text-alt-2 text-left font-thin text-3xl">
							<Sliding/>
						</Container>
						<Container>
							<div className="mt-12 flex flex-row justify-center">
								<IconButton
									className="text-2xl font-display px-4 py-auto h-12"
									onClick={() => history.push("/signIn")}
									variant="color"
								>
									Start
								</IconButton>
							
							</div>
						</Container>
					</div>
				</div>
				<div className="wave-container">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1440 320"
					>
						<path
							fill="#5b9ddb"
							fillOpacity="1"
							d="M0,192L30,181.3C60,171,120,149,180,170.7C240,192,300,256,360,282.7C420,309,480,299,540,277.3C600,256,660,224,720,181.3C780,139,840,85,900,85.3C960,85,1020,139,1080,144C1140,149,1200,107,1260,122.7C1320,139,1380,213,1410,250.7L1440,288L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
						></path>
					</svg>
				</div>
			</div>
		</>
	)
}
