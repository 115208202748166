export type GameSetup = {
	readonly id:string;
	readonly numberOfCards:number;
	readonly fish:(string | number)[];
	readonly elephants:(string | number)[];
	readonly startedAt:Date;
	readonly coset:string
};

export interface PracticeSetup {
	readonly numOfCards:number;
	readonly length:number;
	readonly level:string;
	readonly isLocal:boolean;
}

type ActionStatus = "RESOLVED" | "RESOLVING" | "LOST" | "ATTACK";

export type Action = {
	readonly id:string;
	readonly coset:string
	readonly cardIdx:number;
	readonly startedAt:number;
	readonly player:string;
	readonly originator:string;
	readonly status:ActionStatus;
	readonly resolved?:Date;
	readonly deleted?:boolean;
	readonly nextRnd:number;
	readonly active?:boolean;
	readonly milliSeconds?:number;
};

export type Snapshot = {
	readonly score?:number;
	readonly expires?:number;
	readonly holes?:number[];
};

export type PlayerInfo = {
	readonly name?:string;
	readonly id:string;
	readonly email?:string;
};

export type Player = PlayerInfo & {
	readonly symbol?:Role;
	readonly nation?:string;
	readonly snapshot?:Snapshot;
};

export type Role = "Fish" | "Elephant";

export type Participants = {
	readonly players:Partial<Player>[];
};

export type GameStage = "STANDBY" | "PREP" | "MATCH" | "DONE";

export type GameSetting = "PRACTICE" | "MEET"

export type Game = GameSetup &
	Participants & {
	actions:Action[];
	stage:GameStage;
	setting:GameSetting;
};

export type PlayMode = "PLAY" | "SPECTATOR" | "PRACTICE";

export type Score = {
	readonly unsolved:number;
	readonly solved:number;
};

export const getGameScore = (actions:Action[], player:string) => {
	const matched = actions.filter((a) => a.player === player);
	return {
		solved: matched.filter((a) => a.status === "RESOLVED").length,
		unsolved: matched.filter((a) => a.status === "LOST").length,
	};
};

export type Hand = {
	faces:(string | number)[]
	actions:Action[]
}

export type GameMode = "SINGLE_PERSON" | "TWO_PLAYERS"


export interface ScoreCard {
	solved:number
	unsolved:number
}

export const theOtherGuy = (role:string) => {
	return (role === "fish") ? "elephant" : "fish"
}

export const actionsToScore = ( allActions:Action[], player:string):ScoreCard => {
	const actions = allActions.filter(a => a.player === player)
	return ({
		solved: actions.filter((a) => a.status === "RESOLVED").length,
		unsolved: actions.filter((a) => a.status === "LOST").length,
	})
}
