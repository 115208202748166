import { Resolving } from "containers"
import React from "react"

export const JumpFish = () => {
	return (
		<div className="text-3xl font-extrabold w-1/2  rounded box-box  game-card game-card-back game-card-back">
			<div
				className="text-center m-1"
				style={{ background: "transparent" }}
			>
				<Resolving
					isChallenged={false}
					isResolving={true}
					isGuessing={false}
					nextAnswer={1}
				/>
			</div>
		</div>
	)
}
