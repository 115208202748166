import React from "react"

interface Args {
	width:number,
	height:number,
	text?:string
	fontFamily?:string,
	fontWeight?:string,
	fontSize?:number,
	dy?:number,
	bgColor?:string,
	textColor?:string,
	dataUri?:boolean
	charset?:string
}

function simpleSvgPlaceholder({
// @ts-ignore
          width = 300, height = 150, text = `${width}×${height}`, fontFamily = 'sans-serif', fontWeight = 'bold', fontSize = Math.floor(Math.min(width, height) * 0.2), dy = fontSize * 0.35, bgColor = '#ddd',
          textColor = 'rgba(0,0,0,0.5)',
          dataUri = true,
          charset = 'UTF-8'
      } = {}) {
	const str = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
    <rect fill="${bgColor}" width="${width}" height="${height}"/>
    <text fill="${textColor}" font-family="${fontFamily}" font-size="${fontSize}" dy="${dy}" font-weight="${fontWeight}" x="50%" y="50%" text-anchor="middle">${text}</text>
  </svg>`;

	// Thanks to: filamentgroup/directory-encoder
	const cleaned = str
		.replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
		.replace(/\s\s+/g, ' ') // Condense multiple spaces
		.replace(/'/gim, '\\i'); // Normalize quotes

	if (dataUri) {
		const encoded = encodeURIComponent(cleaned)
			.replace(/\(/g, '%28') // Encode brackets
			.replace(/\)/g, '%29');

		return `data:image/svg+xml;charset=${charset},${encoded}`;
	}

	return cleaned;
}

const setting = {
	width: 300,
	height: 150,
	fontFamily: 'sans-serif',
	fontWeight: 'bold',
	bgColor: '#ddd',
	textColor: 'rgba(0,0,0,0.5)',
	dataUri: true,
	charset: 'UTF-8'
}

export const SvgPlaceHolder = (props:Args) => {
	const { width, height } = props
	const pose = () => {
		const fontSize = Math.floor(Math.min(width, height) * 0.2)
		return {
			text: `${width}×${height}`,
			fontSize,
			dy: fontSize * 0.35,
		}
	}
	const combined = { ...setting, ...pose(), ...props }
	const payload = simpleSvgPlaceholder(combined)
	return (<img src={payload} alt="img"/>)
}
