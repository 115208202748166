import React from "react"

export function Badge (label:string)
{
	return (
		<div className="text-xs my-auto px-2 bg-main-2 text-alt-1 rounded-full">
			{label}
		</div>
	);
}
