import { addMilliseconds, differenceInSeconds } from "date-fns"
import React, { useEffect, useState } from "react"
import { dateFromFuzzyUnix } from "services"
import { clearInterval, setInterval } from 'requestanimationframe-timer';

interface CountDownArgs {
	startedAt: number
	lastFor: number
	callback?: Function
	overlaid?: boolean
}

export const CountDown = ({ startedAt, lastFor, callback, overlaid }: CountDownArgs) => {
	const [num, setNum] = useState("!")

	useEffect(() => {
		const endedAt = addMilliseconds(dateFromFuzzyUnix(startedAt), lastFor)
		const updateTimer = setInterval(() => {
			const now = new Date()
			const seconds = differenceInSeconds(endedAt, now)
			if (seconds <= 0) {
				clearInterval(updateTimer as any);
				if (callback) callback()
			}
			const display = (seconds < 0) ? "0" : seconds
			setNum(`${display}s`)
			return () => { clearInterval(updateTimer as any); }
		}, 125)
	}, [startedAt, lastFor, callback])


	return <>
		{!overlaid && <div className="cursor-pointer">
			{num}
		</div>}
		{overlaid &&
			<div style={{ position: "absolute" }}>
				<div className="num-on-top font-light text-sm"
					style={{ zIndex: 2000 }}>
					{num}
				</div>
			</div>
		}
	</>
}
