import * as automerge from "automerge";
import { Change, Doc } from "automerge";
import { invariant } from "logs";
import { Observable, Subject } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { ActiveSync, SomeStringMayBe } from "./activeSync";
import { DocChanges, DocSnapshot, randId } from "./types";

const Merge = require("lodash.merge");
const Automerge = require("automerge");

export class ActiveRepo implements ActiveSync {
	change$:Subject<DocChanges> = new Subject<DocChanges>();
	// todo: remove
	// docSnapshot$: BehaviorSubject<DocSnapshot> = new BehaviorSubject<DocSnapshot>(
	// 	{ id: "unspecified" }
	// );
	docSnapshot$:Observable<DocSnapshot> = new Observable<DocSnapshot>()
	askingSeed$:Subject<SomeStringMayBe> = new Subject<SomeStringMayBe>();
	askedSeed$:Subject<SomeStringMayBe> = new Subject<SomeStringMayBe>();
	sendingInstructions$:Subject<DocChanges[]> = new Subject<DocChanges[]>();
	receivedInstructions$:Subject<DocChanges[]> = new Subject<DocChanges[]>();
	
	readonly id:string;
	remoteChanges$:Subject<DocChanges> = new Subject<DocChanges>();
	private docSet = new Map<string, Doc<any>>();
	
	constructor (id?:string)
	{
		this.id = id ?? randId();
		this.wire();
		
	}
	
	public docs = () => this.docSet;
	
	incoming$ = () => this.remoteChanges$;
	outgoing$ = () => this.change$;
	
	public getDoc (docId:string)
	{
		return this.docSet.get(docId);
	}
	
	public onAskingSeed ()
	{
		invariant.log(`[roll-up] asking seed from activeRepo`);
		this.askingSeed$.next();
	}
	
	public registerDoc (docId:string, doc:Doc<any>, changes?:Change[])
	{
		this.docSet.set(
			docId,
			Automerge.applyChanges(doc || Automerge.init(), changes || [])
		);
		this.produceSeed(docId);
	}
	
	public reconstructDocFromSeed (changes:DocChanges[])
	{
		changes.forEach((pair) => {
			const { docId, changes } = pair;
			this.docSet.set(
				docId,
				Automerge.applyChanges(Automerge.init(), changes || [])
			);
			invariant.log(`[reconstructed] reconstructDocFromSeed for ${docId}`);
			this.change$.next(pair)
			// todo: remove
			// this.docSnapshot$.next({ id: docId, doc: this.getDoc(docId) });
		});
	}
	
	public merge (docId:string, delta:Doc<any>, msg?:string)
	{
		const currentDoc = this.docSet.get(docId) || Automerge.init();
		const nextVersion = Automerge.change(
			currentDoc,
			msg || "merge",
			(doc:any) => {
				Merge(doc, delta);
			}
		);
		return this.decideChange(docId, currentDoc, nextVersion);
	}
	
	public possible (docId:string, nextVersion:Doc<any>, reason?:string)
	{
		invariant.log(`[why] ${reason}`);
		const currentDoc = this.docSet.get(docId);
		return this.decideChange(
			docId,
			currentDoc || automerge.init({}),
			nextVersion,
			reason
		);
	}
	
	public decideChange (
		docId:string,
		currentDoc:Doc<any>,
		nextVersion:Doc<any>,
		reason?:string
	)
	{
		const changes:Change[] = Automerge.getChanges(currentDoc, nextVersion);
		if (changes.length > 0) {
			invariant.log(`[${this.id} - ${docId}] next version `, nextVersion);
			this.docSet.set(docId, nextVersion);
			// async approach
			// new Promise((resolve) => {
			// 	this.onChange({ docId, changes })
			// 	resolve("")
			// })
			// direct approach
			this.onChange({ docId, changes, reason });
		}
		return changes;
	}
	
	
	private traceChange (change:DocChanges)
	{
		invariant.log(
			`[why] Publish onDocHasChanged(1) the change on docSnapshot$ ...`,
			change.reason
		);
	}
	
	private produceSeed = (docId?:string) => {
		const changes:DocChanges[] = Array.from(this.docSet.keys())
		                                  .filter((id) => !docId || id === docId)
		                                  .map((id) => ({
			                                  docId: id,
			                                  changes: Automerge.getChanges(
				                                  Automerge.init(),
				                                  this.docSet.get(id)
			                                  ),
		                                  }));
		changes.forEach((chg) => this.onChange(chg));
		return changes;
	};
	
	private wire ()
	{
		this.remoteChanges$.subscribe((docChanges) => {
			const { docId, changes } = docChanges;
			invariant.log(`[repo: ${this.id}] remote change `, docChanges);
			const currentDoc = this.docSet.get(docId);
			if (currentDoc) {
				invariant.log(` => trying to apply changes`);
				const nextVersion = Automerge.applyChanges(currentDoc, changes);
				const p = this.decideChange(docId, currentDoc!, nextVersion);
				invariant.log(` => determined change`, p);
			} else {
				const added = Automerge.applyChanges(Automerge.init(), changes);
				this.docSet.set(docId, added);
				invariant.log(`To be added ==> `, added);
			}
		});
		
		this.askedSeed$
		    .pipe(map((id) => this.produceSeed(id)))
		    .subscribe(this.sendingInstructions$); // hence to the sync layer
		
		this.receivedInstructions$.subscribe((changes) =>
			                                     this.reconstructDocFromSeed(changes)
		);
		
		this.docSnapshot$ = this.change$.pipe(
			tap((change:DocChanges) => {this.traceChange(change)}),
			map(change => {
				const { docId: id, reason } = change;
				return { id, doc: this.getDoc(id), reason }
			}),
			shareReplay()
		)
		
	}
	
	private onChange (docChanges:DocChanges)
	{
		invariant.log(`[${this.id}] local change `, docChanges, docChanges.docId);
		this.change$.next(docChanges);
	}
}
