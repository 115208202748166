import { useConstant } from "hook/use-constant"
import { useEffect, useState } from "react"

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.0/8 are considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
);

export function useIsLocal() {
	// todo: remove
	// const [isLocal, setIsLocal] = useState(false)
	// useEffect(() => {
	// 	setIsLocal(isLocalhost)
	// }, [])
	return useConstant(() => isLocalhost)
}

export function useMatchingUrl(matching: string) {
	const [found, setFound] = useState(false)
	useEffect(() => {
		const matched = window.location.href.indexOf(matching) > 0
		setFound(matched)
	}, [matching])
	return found
}


