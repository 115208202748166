import { invariant } from "logs"
import { useEffect, useState } from "react"
import { FveApp } from "services/fveApplication"
import { IProfile, IUser } from "services/types"
import { gql, useMutation } from "urql"
import { state$, UserState } from "./userStateMachine"


export const useUserStage = () => {
	const [state, setState] = useState<UserState>()
	useEffect(() => {
		const sub = state$.subscribe((s:any) => {
			setState(s)
		})
		return () => sub.unsubscribe()
	}, [])
	return { value: state?.value || "", context: state?.context || {} }
}


const updateUser = `
  	mutation ($where: where_user_exp,  $set: user_update_input!) {
		user: update_user (set: $set, where: $where) {
			   returning {
			      id name scopes photo joinedAt sourceOrg
			   }
			}
 	 }
`;

