import { useMemo, useState } from "react"
import { gql, useQuery } from "urql"

const queryPosts = gql`
    query query_post($author: Int ) {
        posts(where: {author: $author,
	        categoryName: "practice"}) {
            nodes {
                id
                databaseId
                title
                content
                status
                tags {
                    nodes {
                        name
                    }
                }
                guid
                uri
                dateGmt
                date
            }
            pageInfo {
                startCursor
                endCursor
            }
        }
    }
`

export const useGetPractices = () => {
	const [createdPost, setPost] = useState<any>({})
	const [{ fetching, data, error }, executeQuery] = useQuery({ query: queryPosts });
	const getMyPractices = useMemo(
		() => {
			const internal = async (author:number) => {
				const variables = {
					author: author
				}
				await executeQuery(variables)
			}
			return internal
		}, [executeQuery]
	)
	
	return {
		createdPost,
		getMyPractices,
		fetching, data, error
	}
}

