import { CardDisplayType, decideWhatToDisplay, GameCardArgs } from "containers/game-types"
import React from "react"
import { IoIosSad } from "react-icons/io"
import { FacePad } from "./face-pad"

const cn = require("classnames")


export interface ExpandConfig {

}

export interface ComboPadArgs extends GameCardArgs {
	expandConfig?:ExpandConfig
	displayType?:CardDisplayType
	nextRnd?:number
}


export const ShowLabel = ({ label }:{ label:any }) => {
	return (
		<div className='inline-flex'>
			{label}
		</div>
	)
}


export const MissingSomething = () => {
	return (
		<div className='inline-flex'>
			<span className="text-2xl text-alt-4"><IoIosSad/> </span>
		</div>
	)
}

export const Lost = () => {
	return (
		<div className='inline-flex'>
			<span className="text-2xl text-alt-4 game-card-lost"><IoIosSad/> </span>
		</div>
	)
}


export const ComboPad = (props:ComboPadArgs) => {
	const {
		label, action, stage, coset,
		activeCallback, answerCallback, showPopup, mode, idx,
		nextRnd, gameOn,
	} = props;
	const isResolving = stage === "MATCH" && action?.status === "RESOLVING";
	const isChallenged = isResolving && action?.player !== action?.originator;
	const isLost = action?.status === "LOST";
	const displayType:CardDisplayType = props.displayType || decideWhatToDisplay(
		stage,
		isLost,
		isChallenged,
		isResolving,
		gameOn
	);
	
	let rendering = <FacePad render={() => <MissingSomething/>}/>
	switch (displayType) {
		case CardDisplayType.Resolving:
			rendering = (
				<div><FacePad action={action} answerCallback={answerCallback} realLabel={label}
				              nextRnd={nextRnd}
				              displayType={displayType} label={label}/></div>)
			break
		case CardDisplayType.Face:
			rendering = (
				<div><FacePad nextRnd={nextRnd} action={action} displayType={displayType} label={label} coset={coset}/></div>)
			break
		case CardDisplayType.Back:
		case CardDisplayType.Lost:
			rendering = (<div><FacePad displayType={displayType}/></div>)
			break;
		case CardDisplayType.Challenged:
			rendering = (
				<div><FacePad
					action={action}
					countDown={{ lastFor: action!.milliSeconds!, startedAt: action!.startedAt }}
					answerCallback={answerCallback} realLabel={label} nextRnd={nextRnd}
					displayType={displayType} label={label}/></div>)
			break
	}
	
	return rendering
	
}
