import { gql } from "graphql-request";
import { useEffect, useState } from "react";
import { FveApp } from "services/fveApplication";

export interface ProfileLink {
	url:string;
	timestamp:number;
	eager:string;
	signature:string;
	public_id:string;
	api_key:string;
}

const useProfileLoadLink = () => {
	const [linkInfo, setLink] = useState<ProfileLink>();
	useEffect(() => {
		const query = `query { profileLink	}`;
		const client = FveApp.getClient(true);
		client
		.request(
			gql`
                ${query}
			`
		)
		.then((data) => {
			const { profileLink } = data;
			setLink(profileLink);
		});
	}, []);
	return { linkInfo };
};

export const ProfileService = {
	useProfileLoadLink,
};
