import * as automerge from "automerge"
import { Container, GameBoard, GameScore2, GameStage, GameViewer } from "containers"
import { GameBoard2 } from "containers/pad"
import { BarLoader, IconButton } from "fui"
import { useActionResolver, useActiveGame, useAsHandViewer, useIsLocal, } from "hook"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { GameController, GameFactory, initRepo, PracticeSetup } from "services"
import { useMatchState } from "services/match"
import { useCreatePracticePost } from "services/user/add-practice"
import invariant from "ts-invariant"
import { Starter } from "./starter"

let zState = {
	inPractice: false,
	level: "moderate",
}

export const Practice = () => {
	const isLocal = useIsLocal()
	
	const intlRepo = useMemo(() => {
		return initRepo()
	}, [])
	const { update, stage, game, gameRepo } = useActiveGame({ repo: intlRepo })
	
	const { addPost, fetching: addingPost } = useCreatePracticePost()
	
	useActionResolver({ repo: intlRepo, player: "fish" })
	
	const { faces, actions, score } = useAsHandViewer(intlRepo, "fish")
	
	const [setup, configSetup] = useState<PracticeSetup>(
		{
			length: 3,
			level: "moderate",
			numOfCards: 10,
			isLocal,
		})
	
	const configRef = useRef<PracticeSetup>(setup)
	
	useEffect(() => {
		configRef.current = { ...configRef.current, isLocal }
		configSetup({ length: 3, level: "moderate", numOfCards: 10, isLocal })
	}, [isLocal])
	
	
	const addPostInternal = useMemo(
		() => {
			return async () => {
				const game:any = gameRepo.getDoc('game')
				invariant.log('*** tracking game prior recording', game)
				await addPost("practice", ["player:fish", `coset:${game.coset}`, `stage:${game.stage}`], game)
			}
		}, [addPost, gameRepo]
	)
	
	
	const completedCallback = useMemo(() => {
		let wrapper:(postContent:any) => void
		wrapper = (postContent:any) => {
			(async () => {
				await addPostInternal()
			})()
		}
		return wrapper
	}, [addPostInternal])
	
	
	const onStopping = async () => {
		await addPostInternal()
		startOver()
	}
	
	const onStartOver = () => {
		startOver()
	}
	
	// may be we will pass into the callback into the use machine
	const {
		id,
		state,
		state$,
		start,
		countDown,
		setOpponentCallback,
		startOver,
		gameStarted,
		stageConfig
	} = useMatchState("", "machine", undefined, completedCallback)
	
	
	useEffect(() => {
		setOpponentCallback(update.injectAttack("system", "fish"))
	}, [update, setOpponentCallback])
	
	useEffect(() => {
		update.pipeGameStage(state$)
	}, [state$, update])
	
	useEffect(() => {
		if (gameStarted) {
			intlRepo.registerDoc(
				"game",
				automerge.from(
					GameFactory.startNewPractice(
						configRef.current.numOfCards,
						"SINGLE_PERSON"
					)
				)
			)
		}
	}, [gameStarted, intlRepo])
	
	
	const onGameOn = () => {
		GameController.startNewPractice(
			configRef.current.numOfCards,
			"SINGLE_PERSON"
		)
		start({ ...configRef.current })
	}
	
	const fineTune = (extra:any) => {
		const nextConfig = { ...setup, ...extra, actions: [] }
		configRef.current = nextConfig
		configSetup(nextConfig)
	}
	
	useEffect(() => {
		GameController.startNewPractice(setup.numOfCards, "SINGLE_PERSON")
		zState.level = setup.level
		return () => { }
	}, [setup])
	
	const display1stGen = () => false
	
	return (
		<div className="pt-24  full-height ">
			{
				isLocal && <Container className="p-4">
					<div className="text-alt-4 font-3xl mx-auto">
						{stage} | {state && state.value} | gameStarted:{" "}
						{gameStarted.toString()}
					</div>
				</Container>
			}
			
			{!gameStarted && <Starter fineTune={fineTune} onStart={onGameOn}/>}
			
			{gameStarted && (
				<Container className="pt-4">
					<div className="mt-12 flex flex-row justify-end">
						{stage !== "DONE" && (
							<IconButton
								className="text-2xl font-display px-4 py-auto"
								onClick={onStopping}
								variant="color"
								fetching={addingPost}
							>
								Stop
							</IconButton>
						)}
						{stage === "DONE" && (
							<IconButton
								className="text-2xl font-display px-4 py-auto"
								onClick={onStartOver}
								variant="color"
								fetching={addingPost}
							>
								START OVER
							</IconButton>
						)}
					</div>
				</Container>
			)}
			
			{gameStarted && (
				<Container className="pt-2">
					
					<div className="flex flex-row justify-between">
						<GameStage stage={stageConfig}/>
						<GameScore2 score={score}/>
					</div>
					
					<GameBoard2
						player="fish"
						mode="PLAY"
						repo={intlRepo}
						faces={faces}
						actions={actions}
					/>
					
					{display1stGen() && <GameBoard
						player="fish"
						mode="PLAY"
						coset={game?.coset || "english"}
						repo={intlRepo}
						faces={faces}
						actions={actions}
					/>}
				
				
				</Container>
			)}
			{isLocal && (
				<div className="container mx-auto">
					<div className="text-alt-3 text-sm">{id}</div>
					<p className="text-md text-alt-3">
						{JSON.stringify(state?.value || "")}
						{JSON.stringify(state?.context || "")}
					</p>
					<GameViewer repo={intlRepo} faces={faces} score={score}/>
				</div>
			)}
		</div>
	)
}
