import { IconButton, Ranking } from "fui"
import { invariant } from "logs"
import React from "react"
import { InviteType,  sendNotNow, matchHelper, toaster$, randId, matchHost$ } from "services"

interface Args {
	invitation:InviteType
	retractHandler:Function
}

export const IncomingInvitation = (props:Args) => {
	const { invitation: { photo, from, replyTo }, retractHandler } = props
	const notNow = async () => {
		retractHandler()
		await sendNotNow(replyTo)
	}

	const gameOn = async () => {
		retractHandler()
		toaster$.next({
			id: randId(),
			type: "INFO",
			main: "Invitation Accepted",
			subTitle: "Preparing the game page .... ",
		});
		const matchInfo = matchHelper.createMatch()
		const status = await matchHelper.notifyPeer(matchInfo, replyTo)
		// tell myself to start as a host 
		matchHost$.next(matchInfo)
		invariant.log(`notify peer`, status)
	}


	return (<div className="bg-green-800 rounded animate__bounceIn w-32rem p-4" style={{zIndex: 2000}}>
		<div className="flex flex-row">
			<div className="flex flex-col">
				<img className="w-16 rounded-lg mr-2" src={photo} alt={""}/>
			</div>
			<div className="flex flex-col text-alt-1">
				<div className="font-extrabold">Accept the invitation?</div>
				<div className="font-normal">{from}</div>
				<div>
					<Ranking full={3} half={true}/>
				</div>
			</div>
			<div className="ml-auto my-auto grid grid-cols-2 gap-4">
				<IconButton className="px-4" variant="color" onClick={gameOn}>Game On!</IconButton>
				<IconButton className="px-4" variant="plain" onClick={notNow}>Not Now</IconButton>
			</div>
		</div>
	</div>)
}
