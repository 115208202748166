import Pubnub from "pubnub"
import { createMachine } from "xstate"

interface ActiveContext {
    matchId: string
    host: string
    peer: string
}

type ActiveEvent =
    | { type: "START_OVER" }
    | { type: "PEER_READY" }
    | { type: "HOST_READY" }
    | { type: "BOTH_READY" }
    | { type: "PEER_SEES_HOST" }
    | { type: "HOST_SEES_PEER" }
    | { type: "REPO_READY" }
    | { type: "REPO_SYNCED" }
    | { type: "GAME_ON" }

type ActiveState =
    | { value: "standBy"; context: ActiveContext }
    | { value: "hostReady"; context: ActiveContext }
    | { value: "peerReady"; context: ActiveContext }
    | { value: "rendezvous"; context: ActiveContext }
    | { value: "gameStandby"; context: ActiveContext }
    | { value: "repoReady"; context: ActiveContext }
    | { value: "repoSynced"; context: ActiveContext }
    | { value: "gameOn"; context: ActiveContext }

export interface StateInfo {
    value: string
    context: ActiveContext
}

export const getActiveMachine = (
    context: Partial<ActiveContext>,
    nub: Pubnub
) => {
    return createMachine<ActiveContext, ActiveEvent, ActiveState>({
        id: "activeMachine",
        initial: "standBy",
        context: { ...context } as ActiveContext,
        states: {
            standBy: {
                on: { HOST_READY: "hostReady" },
            },
            hostReady: {
                on: {
                    PEER_READY: "peerReady",
                },
            },
            peerReady: {
                on: {
                    PEER_SEES_HOST: "rendezvous",
                    HOST_SEES_PEER: "rendezvous",
                },
            },
            rendezvous: { on: { REPO_READY: "repoReady" } },
            repoReady: { on: { REPO_SYNCED: "repoSynced" } },
            repoSynced: { on: { GAME_ON: "gameOn" } },
            gameOn: { on: { REPO_READY: "repoReady" } },
        },
    })
}
