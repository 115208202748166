import { CenterDesktop } from 'containers/desktop';
import React from 'react';
import {Switch} from 'react-router-dom'
import { PasswordRoute } from './password-route';
export const SetPassword = () => {
	return <CenterDesktop>

		<Switch>
			<PasswordRoute/>
		</Switch>

	</CenterDesktop>
}