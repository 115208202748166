import { Button, IconButton } from "fui"
import { useIsLocal } from "hook"
import React, { useEffect, useState } from "react"
import { FiX } from "react-icons/fi"
import { useHistory, useLocation } from "react-router-dom"
import { User, validateEmail } from "services"
import { startOver } from "services/user"



export const Login = () => {
	const history = useHistory()
	const location = useLocation()
	const isLocal = useIsLocal()
	const [email, setEmail] = useState("")
	const [loading, setLoading] = useState(false)
	const { value, context } = User.useUserStage()
	const [showSendBtn, setSendBtn] = useState(false)
	
	useEffect(() => {
		const {state : {token}} = location as any
		console.log("token....", token)
		
	}, [location])
	
	
	const showEmailBtn = (s:string) => {
		const isValid = validateEmail(s)
		if (showSendBtn !== isValid) setSendBtn(isValid)
		setEmail(s)
	}
	
	const handler = (e:any) => {
		const s = e.target.value
		showEmailBtn(s)
	}
	const registerSession = async () => {
		User.registerSession(email)
		setLoading(true)
	}

	useEffect(() => {
		setEmail(isLocal ? "duncan@mtrade.com" : "")
		if (isLocal) {
		}
	}, [isLocal])
	
	useEffect(() => {
		if (value === "signedIn") {
			// we can capture the context and log it, but we should do it in the machine!
			history.push("/practice")
		}
	}, [value, history])
	
	return (
		<div className=" text-main font-display h-screen login-panel pb-64">
			<div className="container mx-auto h-full flex justify-center items-center">
				<div className="w-2/5">
					<div className="flex-row flex justify-between">
						<div className="font-thin mb-6 text-center text-2xl spacing-2 text-alt-2 ">
							Login to Fish vs. Elephant
						</div>
						<IconButton
							className="p-2 text-3xl"
							variant="link"
							onClick={() => history.push("/")}
						>
							<FiX/>
						</IconButton>
					</div>
					<div className="border-t p-8 bg-main-3 mb-6 rounded-lg shadow-lg text-alt-2">
						{value === "visit" && (
							<>
								{loading && <div>loading ... </div>}
								{!loading && (
									<>
										<div className="mb-4">
											<label className="block mb-2 text-alt-3">
												Send me the login link to ...
											</label>
											<input
												type="text"
												value={email}
												onChange={handler}
												className="fish-input"
												placeholder="Your Username"
											/>
										</div>
										{showSendBtn && (
											<div className="flex items-center justify-end">
												<IconButton
													variant="color"
													className="px-4 py-2"
													onClick={registerSession}
												>
													SEND
												</IconButton>
											</div>
										)}
									</>
								)}
							</>
						)}
						{value === "creatingSession" && (
							<div>
								We are working on it. May take a while depending
								on the Internet.
							</div>
						)}
						{value === "signedIn" && (
							<div>
								You have successfully signed in. Redirecting ...
							</div>
						)}
						{value === "failure" && (
							<div className="grid grid-cols-1 gap-3">
								<div>Something wrong, please try again</div>
								<div className="flex items-center justify-end">
									<Button
										onClick={() => startOver()}
										label={"Try Again"}
									/>
								</div>
							</div>
						)}
						{value === "pendingConfirm" && (
							<div>
								<div className="mb-4">
									<div>Check your inbox of {email}</div>
								</div>
							</div>
						)}
					</div>
					{isLocal && (
						<>
							<div>{value}</div>
							<div>
                                <textarea
	                                readOnly
	                                value={JSON.stringify(context, null, 4)}
                                />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
