import classNames from "classnames"
import { BarLoader } from "fui/bar-loader/bar-loader"
import React, { useState } from "react"
import { BiMessageAdd } from "react-icons/all"
import { HiRefresh } from "react-icons/hi"
import { IoIosAdd } from "react-icons/io"
import { RiBug2Fill, RiCloseLine, RiDeleteBinLine, RiShieldCrossLine, } from "react-icons/ri"

const cn = require("classnames")

type IconType =
	| "Clear"
	| "Delete"
	| "Add"
	| "Close"
	| "Cross"
	| "Plus"
	| "Refresh"

export const Icon = ({ icon }:{ icon:IconType }) => {
	switch (icon) {
		case "Delete":
		case "Clear":
			return <RiDeleteBinLine/>
		case "Add":
			return <BiMessageAdd/>
		case "Close":
			return <RiCloseLine/>
		case "Cross":
			return <RiShieldCrossLine/>
		case "Plus":
			return <IoIosAdd/>
		case "Refresh":
			return <HiRefresh/>
	}
	return <RiBug2Fill/>
}

interface ButtonArgs {
	readonly label?:string
	readonly icon?:IconType
	onClick?:any
	selected?:boolean
	readonly border?:boolean
	isLoading?:boolean
}

interface IconButtonArg {
	className?:string
	onClick?:(event:any) => void
	children?:any
	selected?:boolean
	variant?:"plain" | "link" | "color"
	align?:"left" | "center" | "right"
	disabled?:boolean
	fetching?:boolean
}

export const IconButton = (props:IconButtonArg) => {
	const { className, onClick, children, variant, disabled, align, fetching } = props
	const variantClass = variant ? `btn btn-${variant}` : "btn btn-plain"
	const iconBtnClass = cn(
		[
			"flex flex-row",
			"h-9",
			"items-center",
			{ "btn-selected": props.selected, "btn-disabled": disabled },
			{ "cursor-pointer": !disabled },
			{ [variantClass]: !disabled && !fetching },
			{
				"justify-center": !align || align === "center",
				"justify-start": align === "left",
				"justify-end": align === "right"
			},
			className,
		])
	const clickHandler = (e:any) => {
		if (onClick && !disabled) {
			onClick(e)
		}
	}
	
	return (
		<div className={iconBtnClass} onClick={clickHandler}>
			{!fetching &&
			<div className=" flex flex-row flex-end items-center  my-auto ">
				{children}
			</div>
			}
			{
				fetching && <BarLoader/>
			}
		</div>
	)
}

export const Button = (props:ButtonArgs) => {
	const { icon, label, border, selected, isLoading, ...rest } = props
	const additional = classNames([
		                              "mx-1 ",
		                              "btn btn:hover",
		                              "bg-main-3",
		                              "hover-text-alt-4",
		                              "focus:outline-none ",
		                              "py-2 px-4 rounded inline-flex items-center",
		                              { "bg-main": border },
		                              { "btn-selected": selected },
		                              { animate__heartBeat: props.isLoading },
	                              ])
	return (
		<button {...rest} className={additional}>
			{icon && <Icon icon={icon}/>}
			<span className="ml-1 font-display font-medium">{label}</span>
		</button>
	)
}

interface Caption {
	readonly label:string
	readonly value?:any
}

interface ButtonGroupArgs {
	readonly captions:Caption[]
	readonly icons?:IconType[]
	readonly onClick?:any
	readonly border?:boolean
	readonly defaultLabel?:string
}

export const ButtonGroup = (props:ButtonGroupArgs) => {
	const { defaultLabel } = props
	const [selected, setSelected] = useState(defaultLabel || "")
	const { captions, onClick } = props
	const handler = (label:string, value?:any) => () => {
		setSelected(label)
		if (onClick) {
			onClick(label, value)
		}
	}
	
	function compose (c:Caption, idx:number) {
		const { label } = c
		const btnArgs:IconButtonArg = {
			onClick: () => handler(c.label, c.value)(),
			selected: c.label === selected,
		}
		return (
			<IconButton {...btnArgs} className="px-4 py-2" key={idx}>
				{label}
			</IconButton>
		)
	}
	
	return <>{captions.map((c:Caption, idx:number) => compose(c, idx))}</>
}
