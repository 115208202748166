import { CenterDesktop } from 'containers/desktop';
import { StdImageWait } from 'fui/can-images';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToken } from './use-token';


export const ValidatePasswordToken = () => {
	const { data, hasData } = useToken()
	const history = useHistory()
	useEffect(() => {
		if (hasData) {
			console.log(`ok we have it ....`, data)
			if (data.email) {
				history.push("/setPassword/setting", { token: data.token })
			} else {
				history.push("/setPassword/msg", { mdg: 'Reset password token has expired, please request a new one.' })
			}
		}
	}, [hasData, data, history]);
	return <CenterDesktop>
		<div className="text-alt-1 text-center flex flex-col">
			<div className="mx-auto">
				<StdImageWait/>
			</div>
			<div className="text-xl mx-auto text-alt-4 mt-2">
				checking if token is valid
			</div>
		</div>
	</CenterDesktop>
}
