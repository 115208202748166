import { Container } from "containers"
import React from "react"

const scheme = {
	'main-5': `#0e2439`, "main-4": "#2c4763", "main-3": "#274059", "main-2": "#1f364d",
	"main-1": '#162d43', 'alt-1': '#a2b8cd', 'alt-2': `#63809c`, 'alt-3': `#9cb3c9`, 'alt-4': `#fff`
}

export const Color = () => {
	const css:string[] = []
	css.push("/////")
	const entries = Object.entries(scheme)
	entries.forEach(e => {
		css.push(`$${e[0]}: ${e[1]};`)
	})

	entries.forEach(e => {
		css.push(`.text-${e[0]} {color: $${e[0]} ;}`)
	})
	
	entries.forEach(e => {
		css.push(`.border-${e[0]} {border-color: $${e[0]} ;}`)
	})
	
	entries.forEach(e => {
		css.push(`.hover-text-${e[0]}:hover {color: $${e[0]} ;}`)
	})
	entries.forEach(e => {
		css.push(`.bg-${e[0]} {background-color: $${e[0]} ;}`)
	})
	entries.forEach(e => {
		css.push(`.hover-bg-${e[0]}:hover {background-color: $${e[0]} ;}`)
	})

	return <Container className="flex flex-row pl-20 text-white " style={{ color: 'white' }}>
			<pre>
				{css.join("\n")}
			</pre>
	</Container>
}
