export const waitIcon = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQ
kxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU
1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDkxIDkxI
iBoZWlnaHQ9IjkxcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA5MSA5M
SIgd2lkdGg9IjkxcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZ
y8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxja
XJjbGUgY3g9IjQ1LjUxNyIgY3k9IjQ0Ljc0OSIgZmlsbD0iIzlBQkZEQSIgcj0iMjkuMzk2Ii8+PGc+P
Gc+PHBhdGggZD0iTTE1Ljk4Niw4OS4zNDZjLTAuNTYzLDAtMS4xMjctMC4xODgtMS41OTUtMC41NzJjL
TEuMDcxLTAuODgyLTEuMjI2LTIuNDY1LTAuMzQ1LTMuNTM3bDcuODEzLTkuNDk3ICAgICBjMC44ODItM
S4wNywyLjQ2Ni0xLjIyNSwzLjUzNi0wLjM0NGMxLjA3MiwwLjg4MiwxLjIyNywyLjQ2NSwwLjM0NSwzL
jUzN2wtNy44MTMsOS40OTcgICAgIEMxNy40MzIsODkuMDMzLDE2LjcxMSw4OS4zNDYsMTUuOTg2LDg5L
jM0NnoiIGZpbGw9IiM0NTRCNTMiLz48cGF0aCBkPSJNNzUuNTEyLDg5LjM0NmMtMC43MjUsMC0xLjQ0N
S0wLjMxMy0xLjk0MS0wLjkxNmwtNy44MTYtOS40OTdjLTAuODgxLTEuMDcxLTAuNzI4LTIuNjU1LDAuM
zQ0LTMuNTM3ICAgICBjMS4wNzItMC44ODEsMi42NTQtMC43MjcsMy41MzcsMC4zNDRsNy44MTUsOS40O
TdjMC44ODIsMS4wNzEsMC43MjgsMi42NTUtMC4zNDMsMy41MzcgICAgIEM3Ni42MzksODkuMTU4LDc2L
jA3NCw4OS4zNDYsNzUuNTEyLDg5LjM0NnoiIGZpbGw9IiM0NTRCNTMiLz48cGF0aCBkPSJNNDUuNzUsO
DQuMzljLTIxLjg3LDAtMzkuNjYyLTE3Ljc5Mi0zOS42NjItMzkuNjYyYzAtMjEuODY2LDE3Ljc5Mi0zO
S42NTUsMzkuNjYyLTM5LjY1NSAgICAgYzIxLjg2NywwLDM5LjY1OCwxNy43ODksMzkuNjU4LDM5LjY1N
UM4NS40MDgsNjYuNTk4LDY3LjYxNyw4NC4zOSw0NS43NSw4NC4zOXogTTQ1Ljc1LDEwLjA5OCAgICAgY
y0xOS4xLDAtMzQuNjM3LDE1LjUzNS0zNC42MzcsMzQuNjNjMCwxOS4wOTksMTUuNTM3LDM0LjYzNywzN
C42MzcsMzQuNjM3YzE5LjA5NywwLDM0LjYzMy0xNS41MzgsMzQuNjMzLTM0LjYzNyAgICAgQzgwLjM4M
ywyNS42MzMsNjQuODQ3LDEwLjA5OCw0NS43NSwxMC4wOTh6IiBmaWxsPSIjNDU0QjUzIi8+PHBhdGggZ
D0iTTU2Ljc3OSw0Ny4yNEg0NS44NjZjLTEuMzg4LDAtMi41MTMtMS4xMjUtMi41MTMtMi41MTNWMTYuN
zY1YzAtMS4zODgsMS4xMjUtMi41MTMsMi41MTMtMi41MTMgICAgIHMyLjUxMywxLjEyNSwyLjUxMywyL
jUxM3YyNS40NWg4LjRjMS4zODksMCwyLjUxNCwxLjEyNSwyLjUxNCwyLjUxM1M1OC4xNjgsNDcuMjQsN
TYuNzc5LDQ3LjI0eiIgZmlsbD0iIzQ1NEI1MyIvPjxwYXRoIGQ9Ik00NS44NjksNzUuMTY0Yy0xLjM4O
SwwLTIuNTE0LTEuMTI1LTIuNTE0LTIuNTEzVjY2LjQ1YzAtMS4zODgsMS4xMjUtMi41MTMsMi41MTQtM
i41MTMgICAgIGMxLjM4NywwLDIuNTEyLDEuMTI1LDIuNTEyLDIuNTEzdjYuMjAxQzQ4LjM4MSw3NC4wM
zksNDcuMjU2LDc1LjE2NCw0NS44NjksNzUuMTY0eiIgZmlsbD0iIzQ1NEI1MyIvPjxwYXRoIGQ9Ik0yM
y44NjMsNDcuMjRoLTYuMTExYy0xLjM4OCwwLTIuNTEzLTEuMTI1LTIuNTEzLTIuNTEzczEuMTI1LTIuN
TEzLDIuNTEzLTIuNTEzaDYuMTExICAgICBjMS4zODgsMCwyLjUxMywxLjEyNSwyLjUxMywyLjUxM1MyN
S4yNTEsNDcuMjQsMjMuODYzLDQ3LjI0eiIgZmlsbD0iIzQ1NEI1MyIvPjxwYXRoIGQ9Ik03My43NDYsN
DcuMjRoLTYuMTEzYy0xLjM4NywwLTIuNTEyLTEuMTI1LTIuNTEyLTIuNTEzczEuMTI1LTIuNTEzLDIuN
TEyLTIuNTEzaDYuMTEzICAgICBjMS4zODksMCwyLjUxNCwxLjEyNSwyLjUxNCwyLjUxM1M3NS4xMzUsN
DcuMjQsNzMuNzQ2LDQ3LjI0eiIgZmlsbD0iIzQ1NEI1MyIvPjxwYXRoIGQ9Ik00LjcxMiwxOS45NDVjL
TEuMzg4LDAtMi41MTMtMS4xMjUtMi41MTMtMi41MTNWMTIuMDljMC01Ljk0NSw0Ljg0LTEwLjc4MywxM
C43ODgtMTAuNzgzaDUuMzM5ICAgICBjMS4zODgsMCwyLjUxMywxLjEyNSwyLjUxMywyLjUxM3MtMS4xM
jUsMi41MTMtMi41MTMsMi41MTNoLTUuMzM5Yy0zLjE3OCwwLTUuNzYzLDIuNTgzLTUuNzYzLDUuNzU4d
jUuMzQzICAgICBDNy4yMjUsMTguODIsNi4xLDE5Ljk0NSw0LjcxMiwxOS45NDV6IiBmaWxsPSIjNDU0Q
jUzIi8+PHBhdGggZD0iTTg2Ljc4LDE5Ljk0NWMtMS4zODgsMC0yLjUxMy0xLjEyNS0yLjUxMy0yLjUxM
1YxMi4wOWMwLTMuMTc1LTIuNTg0LTUuNzU4LTUuNzYxLTUuNzU4aC01LjMzOSAgICAgYy0xLjM4OSwwL
TIuNTE0LTEuMTI1LTIuNTE0LTIuNTEzczEuMTI1LTIuNTEzLDIuNTE0LTIuNTEzaDUuMzM5YzUuOTQ3L
DAsMTAuNzg2LDQuODM4LDEwLjc4NiwxMC43ODN2NS4zNDMgICAgIEM4OS4yOTMsMTguODIsODguMTY4L
DE5Ljk0NSw4Ni43OCwxOS45NDV6IiBmaWxsPSIjNDU0QjUzIi8+PC9nPjwvZz48L2c+PC9zdmc+`