import { GameStage } from "../gameTypes"
import { StageInfo } from "./types"

const dummy = new Date()

const guessStage= (s: string) : GameStage => {
	let mapped: GameStage = "STANDBY"
	const m = s.toLowerCase();
	if (m.startsWith("stand") || m.startsWith("prep")) mapped = "STANDBY"
	if (m.startsWith("mem")) mapped = "PREP"
	if (m.startsWith("mat") || m.startsWith("fig") ) mapped = "MATCH"
	if (m.startsWith("don") ) mapped = "DONE"
	return mapped
}

const makeStageInfo = (name: string, spanInSeconds: number) : StageInfo => {
	return {name, spanInSeconds, stage: guessStage(name), from: dummy, till: dummy}
}

const easyTempo : StageInfo[] = [
	makeStageInfo("standby", 5),
	makeStageInfo("memorization", 15),
	makeStageInfo("matching", 90),
	makeStageInfo("done", 1),
]

const localTempo : StageInfo[] = [
	makeStageInfo("standby", 5),
	makeStageInfo("memorization", 5),
	makeStageInfo("matching", 5),
	makeStageInfo("done", 1),
]


export const gameTempos = {
	easy: easyTempo,
	local: localTempo
}
