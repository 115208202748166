import { invariant } from "logs"
import Pubnub, { MessageEvent, SignalEvent, StatusEvent } from "pubnub"
import { Subject } from "rxjs"
import { filter, map, tap } from "rxjs/operators"
import { randId, unsubscribeAll } from "services/utils"
import { FveApp } from "../fveApplication"
import { handshakeHandler } from "./handshake"
import {
    HandshakeMessageType,
    InviteType,
    isHandshake,
    SignalType,
} from "./types"

export const onInvite$: Subject<InviteType> = new Subject<InviteType>()

class MeetNub {
    matchId: string = ""
    subs: any[] = []

    constructor(private nub: Pubnub) {
        this.wireNubListeners()
        // this.wireConnect()
        this.wireSessionMsg()
        this.wireHandshake()
    }

    subscribeMatch = (matchId: string) => {
        if (this.matchId) {
            this.nub.unsubscribe({ channels: [`fve.${this.matchId}`] })
        }
        this.nub.subscribe({ channels: [`fve.${matchId}`] })
        invariant.log(`[match] -- Subscribed matchId ${matchId}`)
        this.matchId = matchId
        return () => {
            this.unsubscribeMatch()
        }
    }

    sendMatchMsg = async (msg: any) => {
        const channel = `fve.${this.matchId}`
        invariant.log("*** send message to channel ", channel, msg)
        await this.nub.publish({ channel, message: msg })
    }

    unsubscribeMatch = () => {
        if (this.matchId) {
            this.nub.unsubscribe({ channels: [`fve.${this.matchId}`] })
            this.matchId = ""
        }
    }

    getNub() {
        return this.nub
    }

    tearDown() {
        this.nub.unsubscribeAll()
        unsubscribeAll(this.subs)()
    }

    private wireNubListeners = () => {
        this.nub.addListener({
            status: function (statusEvent) {
                status$.next(statusEvent)
            },
            message: function (msg) {
                invariant.log(`[raw]`, msg)
                message$.next(msg)
            },
            signal: function (signal) {
                signal$.next(signal)
            },
        })
    }

    private wireSessionMsg = () => {
        const currentSessionId = () => this.nub.getUUID()
        const forTheSession = (msg: any) =>
            msg.channel.endsWith(currentSessionId())
        const traceMsg = (msg: MessageEvent) => {
            invariant.log(
                `[msg-session] ${randId()}`,
                msg.channel,
                currentSessionId(),
                msg.timetoken
            )
        }
        this.subs.push(
            message$
                .pipe(tap(traceMsg), filter(forTheSession))
                .subscribe(sessionChannelMsg$)
        )
    }

    private wireHandshake = () => {
        const forHandshake = (msg: MessageEvent) => {
            return isHandshake(msg.message as any)
        }
        const extractMessage = (msg: MessageEvent) =>
            msg.message as HandshakeMessageType
        sessionChannelMsg$
            .pipe(filter(forHandshake), map(extractMessage))
            .subscribe(handshakeHandler)
    }
}

let meetNub: MeetNub

export const getCurrentMatchNub = () => meetNub

const status$: Subject<StatusEvent> = new Subject<StatusEvent>()
const message$: Subject<MessageEvent> = new Subject<MessageEvent>()
const sessionChannelMsg$: Subject<MessageEvent> = new Subject<MessageEvent>()
const signal$: Subject<SignalEvent> = new Subject<SignalEvent>()

FveApp.user$.subscribe((user) => {
    const { subscribeKey, publishKey, sessionId } = user
    if (!sessionId) {
        return
    }

    if (meetNub) {
        meetNub.tearDown()
    }
    const p = new Pubnub({
        subscribeKey: subscribeKey!,
        publishKey: publishKey!,
        authKey: sessionId,
        uuid: sessionId,
    })
    p.subscribe({ channels: [`fve.${sessionId}`] })
    invariant.log(`[% % %] just subscribed session channel for user`, user.name)
    if (meetNub) {
        meetNub.tearDown()
    }
    meetNub = new MeetNub(p)
})

export const getNub = () => meetNub.getNub()

export const nubSubject = {
    status$,
    signal$,
    message$,
    // connect$,
    sessionChannelMsg$,
}

export const sendSignal = async (message: SignalType, target: string) => {
    return await meetNub.getNub().signal({ channel: `fve.${target}`, message })
}

export const sendMessage = async (
    message: HandshakeMessageType,
    target: string
) => {
    return await meetNub.getNub().publish({
        channel: `fve.${target}`,
        message,
        storeInHistory: false,
    })
}

export const sendInvitation = async (target: string) => {
    const user = FveApp.user$.getValue()
    const { photo, name: from, sessionId } = user
    try {
        return await sendMessage(
            {
                kind: "handshake",
                signal: "INVITE",
                data: {
                    from: from || "",
                    photo: photo || "",
                    replyTo: sessionId || "",
                },
            },
            target
        )
    }
    catch (err) {
        console.error(`why? send Invitation ...`)
        console.log(Object.keys(err))
        console.log(err.name, err.status)
    }
   
}

export const sendGameOn = async (target: string) => {
    return await sendMessage({ kind: "handshake", signal: "AGREE" }, target)
}

export const sendNotNow = async (target: string) => {
    return await sendMessage({ kind: "handshake", signal: "REJECT" }, target)
}
