import { useEffect, useState } from "react";
import { InviteType, onInvite$, unsubscribeAll, } from "services";
import { useSubs } from "./use-subs";

export const useAcceptInvite = () => {
	const subs = useSubs();
	const [showInvite, setShowInvite] = useState(false);
	const [invitation, setInvitation] = useState<InviteType>()
	useEffect(() => {
		subs.push(onInvite$.subscribe(invite => setInvitation(invite)))
		setShowInvite(true)
		return unsubscribeAll(subs)
	}, [subs])
	return { invitation, setShowInvite, showInvite };
};
