import { invariant } from "logs";
import { from } from "rxjs";
import { API } from "services";
import { FveApp } from "services/fveApplication";
import { assign, createMachine, interpret } from "xstate";
import { JwtListener } from "./jwt-listener";

interface UserContext {
	readonly email?: string;
	readonly jwt?: string;
	readonly sessionId?: string;
	readonly errorMessage?: string;
}

const createSession = async (context: any, event: any) => {
	const { email, baseUrl } = context;
	try {
		return await API.createSession(email, baseUrl);
	} catch (e) {
		return Promise.reject(e.message);
	}
};

function extractPayload(token: string) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace("-", "+").replace("_", "/");
	return JSON.parse(atob(base64));
}

interface sessionArgs {
	jwt: string;
	sessionId: string;
	profile: Object;
	pubnub: Object;
	iss: string
}

const confirming = async (context: any, event: any) => {
	const { sessionId } = context;
	JwtListener.listen(sessionId, async (sessionData: sessionArgs) => {
		const { jwt, sessionId, pubnub, iss } = sessionData;
		FveApp.setSessionJwt(jwt, iss);
		const { user } = extractPayload(jwt);
		FveApp.setUser({ ...user, sessionId: sessionId, ...pubnub });
		invariant.log(`confirming session ${user}`);
		notifyConfirm({ ...sessionData });
	});
};

type UserEvent =
	| { type: "visit" }
	| { type: "SIGN_IN"; email: string; baseUrl: string }
	| { type: "SIGN_IN_SUCCESS"; data: object }
	| { type: "SIGN_IN_FAILED"; error: string }
	| { type: "SIGN_OUT" }
	| { type: "START_OVER" };

export type UserState =
	| { value: "visit"; context: UserContext }
	| { value: "signedIn"; context: UserContext }
	| { value: "creatingSession"; context: UserContext }
	| { value: "pendingConfirm"; context: UserContext }
	| { value: "pending"; context: UserContext }
	| { value: "failure"; context: UserContext };

function logAll() {
	return assign((ctx: UserContext, event: any) => {
		const { data } = event;
		return {
			...ctx,
			errorMessage: data,
		};
	});
}

// <UserContext, UserEvent, UserState>
export const userMachine = createMachine({
	initial: "visit",
	context: {},
	states: {
		visit: {
			on: {
				SIGN_IN: {
					target: "creatingSession",
					actions: [
						assign((ctx: UserContext, ent: any) => ({
							...ctx,
							email: ent.email,
						})),
					],
				},
			},
		},
		creatingSession: {
			invoke: {
				id: "createSession",
				src: createSession,
				onDone: {
					target: "pendingConfirm",
					actions: [
						assign((ctx: UserContext, event: any) => {
							// we need to listen to firefox
							invariant.log(`before listen`, ctx, event.data);
							const merged = { ...ctx, ...event.data };
							return merged;
						}),
					],
				},
				onError: {
					target: "failure",
					actions: [logAll()],
				},
			},
		},
		pendingConfirm: {
			invoke: {
				id: "confirming",
				src: confirming,
			},
			on: {
				SIGN_IN_SUCCESS: {
					target: "signedIn",
					actions: [
						assign((ctx: UserContext, event: any) => {
							invariant.log("receive event", event);
							return { ...ctx, ...event.data };
						}),
					],
				},
			},
		},
		signedIn: {
			on: {
				SIGN_OUT: "visit",
			},
		},
		failure: {
			on: {
				START_OVER: {
					target: "visit",
					actions: [assign((ctx: UserContext) => ({}))],
				},
			},
		},
	},
});

export const service = interpret(userMachine).start();
export const state$ = from(service as any);

export const registerSession = (email: string, baseUrl: string) => {
	service.send({
		type: "SIGN_IN",
		email,
		baseUrl,
	});
};

export const notifyConfirm = (data: object) => {
	service.send({
		type: "SIGN_IN_SUCCESS",
		data,
	});
};

export const startOver = () => {
	service.send({ type: "START_OVER" });
};

export const verifySession = async (sessionId: string, token: string) => {
	try {
		const { data } = await API.verifySession(sessionId, token);
		const { email } = data;
		if (email) {
			const event: UserEvent = {
				type: "SIGN_IN_SUCCESS",
				data,
			};
			service.send(event);
			return "";
		}
		return "the token is not correct!";
	} catch (e) {
		return e.message;
	}
};
