/* eslint-disable jsx-a11y/anchor-is-valid */
import classnames from "classnames"
import React from "react"
import { BiDotsVerticalRounded, BiCaretDown } from "react-icons/bi"
import { useHistory } from "react-router-dom"
import { busy, openForMeet, useMeet } from "services/api"
import "./dropdown.scss"
import { StatusIcon } from "./status-icon"


// adapted from https://codepen.io/huphtur/pen/ordMeN

export const AvailabilityDropdown = () => {
    const { meetStatus } = useMeet()
    const placeHolders = classnames([
        "font-semibold py-2 px-4 rounded flex flex-row items-baseline",
        "cursor-pointer",
        "hover-text-alt-3",
    ])
    const pullDown = classnames([
        "cursor-pointer",
        "menu-option",
        "rounded-b",
        "py-2 px-4 block whitespace-no-wrap",
    ])

    return (
        <div className="my-auto">
            <div className="dropdown inline-block relative">
                <div className={placeHolders}>
                    <StatusIcon className="meet-status" status={meetStatus} />
                    <BiCaretDown />
                </div>
                <ul className="dropdown-menu rounded-lg absolute hidden pt-1">
                    <li className="status-menu rounded-t">
                        <span
                            className={pullDown}
                            onClick={async () => {
                                await busy()
                            }}
                        >
                            No interruption!
                        </span>
                    </li>
                    <li className="status-menu">
                        <span
                            className={pullDown}
                            onClick={async () => {
                                await openForMeet()
                            }}
                        >
                            I am available.
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
