import fish from "assets/images/clown-fish.svg"
import React from "react"

const cn = require("classnames")

interface Args {
	title?:string
	children?:any,
	width?:string
}

export const FishBox = (props:Args) => {
	const { title, children } = props
	const comboName = cn([`bg-main-1 pt-8 pb-8`,
		                     `pl-4 pr-4 rounded`,
		                     `flex flex-col justify-between`,
		                     { "w-72": !props.width },
		                     { [`${props.width}`]: props.width }
	                     ])
	
	return <div className={comboName}>
		<div className="text-center">
			<img className="w-36 mx-auto" src={fish} alt=""/>
			<div className="text-alt-3 text-lg mt-2 font-bold">
				{title || ""}
			</div>
		</div>
		<div>
			{children}
		</div>
	</div>
	
}
