import { IconButton } from "fui/button"
import React from 'react';
import { FiArrowLeft, FiSave } from "react-icons/fi"
import {ImCancelCircle} from  'react-icons/im'

interface Args {
	onSave?:Function
	onCancel?:Function
	saving?:boolean
}

export const SaveCancel = (props:Args) => {
	const { onSave, onCancel, saving } = props;
	const saveHandler = () => {
		if (onSave) {onSave()}
	}
	const cancelHandler = () => {
		if (onCancel) {onCancel()}
	}
	return <>
		{saving && <div>saving ...</div>}
		{!saving && <div className="flex flex-row justify-between">
			<IconButton className="mt-2 text-lg px-4"
			            variant="color"
			            onClick={saveHandler}>
				<FiSave/>
				<div className="pl-2">Save</div>
			</IconButton>

			<IconButton className="mt-2 text-lg px-4 "
			            variant="plain"
			            onClick={cancelHandler}>
				<ImCancelCircle/>
				<div className="pl-2">Cancel</div>
			</IconButton>
		</div>
		}
	</>
}
