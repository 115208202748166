import classed from "classed-components"


export const Heading = classed.div(
	[
		"flex",
		"flex-row",
		"text-c1-700",
		"my-4",
		"ml-1",
	]);
export const HeadingLabel = classed.div(["font-bold", "ml-1", "text-alt-3"]);
export const Unit = classed.div(["bg-main-3-to-5", "rounded p-4"]);
