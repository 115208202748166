import React, { useMemo } from 'react';
import { PracticeRecord } from 'services/practice-record';
import { RecordViewer } from './record-row';


interface RecordsArgs {
	records: PracticeRecord[]
}
export const RecordsViewer = (args: RecordsArgs) => {
	console.log('** RecordsViewer', args)
	const recs = useMemo(() => {
		if (args.records && Array.isArray(args.records)) {
			return args.records
		}
		else {
			return []
		}
	}, [args.records])
	return (<div>
		<div className="text-3xl">RecordsViewer</div>
		{recs.map((record, index) => (<RecordViewer  key={index} {...record} />))}
	</div>)
}