import { useEffect } from "react"

type callbackAction = (key: string, event: any) => void

export const useKey  = (action:callbackAction) => {
	useEffect(() => {
		function handler (e:any)
		{
			action(e.key, e)
		}
		window.addEventListener('keyup', handler);
		return () => window.removeEventListener('keyup', handler);
	}, [action]);
}


