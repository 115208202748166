import { Button, H6 } from "fui"
import React from "react"


interface ConsoleArgs {
}

export const GameConsole = (props:ConsoleArgs) => {
	
	function resetActions ()
	{
	}
	
	const onMatch = () => {
	
	}
	
	const onDone = () => {}
	
	return (<div className="my-2">
		<div className="bg-main-2-to-5 p-4 rounded">
			<H6 className="font-extrabold font-display text-alt-2">
				CONSOLE - Stage
			</H6>
			<div className="flex flex-row ">
				<Button onClick={resetActions} label="clear actions"
						icon="Clear"/>
				<Button label="add action"
						icon="Add"/>
				<Button onClick={onMatch} label="Game On" icon="Add"/>
				<Button onClick={onDone} label="Done" icon="Add"/>
			</div>
		</div>
	</div>)
	
}
