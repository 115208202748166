import { useEffect, useMemo } from "react"


const undoAll = (subs:Function[]) => {
	return () => {
		for (let i = 0; i < subs.length; i++) {
			const f = subs[i]
			try {
				f()
			}
			catch {
				// do nothing
			}
		}
	}
}

export const useTearDown = () => {
	const undo:Function[] = useMemo(() => [], []);
	useEffect(() => {
		return undoAll(undo)
	}, [undo]);
	return undo;
}
