import { ActiveRepo, ActiveSync } from "activeDoc"
import { invariant } from "logs"
import { tap } from "rxjs/operators"

export const addSyncToRepo = (sync:ActiveSync, repo:ActiveRepo) => {
	// wire the in and out changes
	repo.change$.subscribe(sync.outgoing$())
	sync.incoming$().subscribe(repo.remoteChanges$)
	// rollup
	repo.askingSeed$.pipe(
		tap(() => {invariant.log(`on the way to peer layer`)})
	).subscribe(sync.askingSeed$)
	sync.askedSeed$.subscribe(repo.askedSeed$)
	repo.sendingInstructions$.subscribe(sync.sendingInstructions$)
	sync.receivedInstructions$.subscribe(repo.receivedInstructions$)
}
