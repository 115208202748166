import { ToasterContainer } from 'containers';
import { CenterDesktop, FishBox } from 'containers/desktop';
import { IconButton } from 'fui';
import { StdImageWait } from 'fui/can-images';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom';
import { toaster$ } from 'services';
import { usePasswordAction } from "services/api"


const passwordTitle = "Ok, Let's Reset Password"

export const PasswordForm = () => {
	const location = useLocation()
	const history = useHistory()
	const [success, setSuccess] = useState(false)
	const { data, updateAction, loading: settingPassword } = usePasswordAction()
	const { state, state: { token } } = location as any
	
	console.log(`****`, state)
	
	const { register, handleSubmit, watch, formState: { errors } } = useForm()
	
	
	const onSubmit = async (data:any) => {
		console.log('****', errors)
		data = { ...data, token }
		const { password, password2 } = data
		if (password !== password2) {
			toaster$.next({
				              type: "WARNING",
				              main: "Passwords do not match.",
				              id: Math.random().toString(),
				              subTitle: `Please check your inputs again.`
			              })
		} else {
			console.log(`** calling password updated Action **`)
			await updateAction({ action: "updatePassword", token, password })
		}
	}
	
	
	useEffect(() => {
		const { status, errorMessage } = data || {}
		if (status && (status === 'OK' || status === 'yoyo')) {
			setSuccess(true)
		}
	}, [data])
	
	const goSignIn = () => {
		history.push("/signIn")
	}
	
	return <CenterDesktop>
		<FishBox title={passwordTitle} width='w-72'>
			{
				!success &&
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="text-alt-2 m-2">
						* The password must be at least 8 characters long.
					</div>
					<input
						type="password"
						{...register("password", { required: true, })}
						className="fish-input mt-4"
						placeholder="Choose Password"
					/>

					<input
						type="password"
						{...register("password2", { required: true, })}
						className="fish-input mt-4"
						placeholder="Confirm Password"
					/>
					
					{!settingPassword &&
					<div>
						<IconButton className="mt-3" variant="color"
						            onClick={handleSubmit(onSubmit)}>Submit</IconButton>
					</div>
					}
					{
						settingPassword && <div>
							<div className="bg-main-2 p-2 mt-2 font-bold flex flex-row">
								<div className="mx-2">
									<StdImageWait className="w-6"/>
								</div>
								<div className="text-alt-4">
									updating your password
								</div>
							</div>
						</div>
					}
				</form>
			}
			{
				success && <div>
					<hr className="alt my-2"/>
					<div className="text-alt-3 mt-4">
						Thank you for your registration! Your account is now ready to use.
					</div>
					<div className="flex flex-row justify-end mt-2">
						<IconButton className="px-2" variant="color" onClick={goSignIn}>
							Sign In
						</IconButton>
					</div>
				</div>
			}
			<ToasterContainer/>
		</FishBox>
	</CenterDesktop>
}
