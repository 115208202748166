import { ActiveRepo } from "activeDoc"
import { invariant } from "logs"
import { getCurrentMatchNub } from "services"
import { StateInfo } from "services/active-machine"
import { peerSync$ } from "services/api"

function sleep (ms:number)
{
	return new Promise((resolve) => setTimeout(resolve, ms));
}


export const peerHandler = async (repo:ActiveRepo, current?:StateInfo) => {
	const nub = getCurrentMatchNub();
	const peer = peerSync$.getValue();
	const { host } = current?.context || {};
	invariant.log(`[state] - ${current}`);
	const { value: stateValue, context } = current || {};
	if (stateValue === "hostReady") {
		await nub.sendMatchMsg(`event://PEER_READY`);
	}
	if (stateValue === "peerReady") {
		// we need to try this multiple time just in case.
		let executionCnt = 0;
		while (!peer.connections.has(host!)) {
			executionCnt++;
			invariant.log(
				`[match] connectTo to host w/ count: ${executionCnt}`,
				context
			);
			await peer.connectTo(context!.host);
			await sleep(1000);
			if (peer.connections.has(host!)) {
				break;
			}
			if (executionCnt > 10) {
				break;
			}
			await sleep(1500);
		}
	}
	if (stateValue === "repoReady") {
		// get the instruction of the repo
		repo.onAskingSeed();
		await nub.sendMatchMsg(`event://REPO_SYNCED`);
	}
};
