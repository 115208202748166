import { ActiveRepo } from "activeDoc"
import classnames from "classnames"
import { CardGrid } from "containers"
import { getAction } from "containers/game-types"
import { Badge } from "fui"
import { useActiveGame, useAsGameViewer, useIsLocal } from "hook"
import React, { useMemo } from "react"
import { FaFish } from "react-icons/fa"
import { GiElephant } from "react-icons/gi"
import { Action, PlayMode } from "services"
import { GenGameCard } from "./gen-game-card"

export interface GameProps {
	player: string;
	mode: PlayMode;
	repo?: ActiveRepo;
	faces: (string | number)[]
	actions: Action[]
}

const Header = (props: GameProps) => {
	return <div
		className="flex-row flex items-center font-display justify-between text-2xl inline">
		<div className="flex flex-row gap-2 items-center text-xl font-thin text-alt-3">
			{props.player === "fish" && <FaFish />}
			{props.player === "elephant" && <GiElephant />}
			{props.player}
		</div>
		<div className="ml-2 font-display flex flex-row justify-end">
			{Badge(props.mode.toString())}
		</div>
	</div>
}

interface CbCollection {
	activeCb: Function
	answerCb: Function
}

function cacheCallbacks(cacheCb: any, update: any, act: Action): any {
	let cb: CbCollection = { activeCb: () => { }, answerCb: () => { } }
	if (act && cacheCb[act.id]) {
		cb = cacheCb[act.id]
	}
	else {
		const additional = {
			activeCb: (isActive: boolean) => {
				update.toggleActive(act.id, isActive);
			},
			answerCb: (isCorrect: boolean) => {
				update.proposeAnswer(act.id, isCorrect);
			}
		}
		if (act) {
			cacheCb[act.id] = additional
		}
		cb = additional
	}
	return cb
}

export const GameBoard2 = (props: GameProps) => {
	const cacheCb: any = useMemo(() => { return {} }, [])
	const isLocal = useIsLocal()

	const { mode, repo, faces, actions } = props;
	const { stage, isGameOn, } = useAsGameViewer(repo!);
	const { update, game } = useActiveGame({ repo: repo || new ActiveRepo("") })
	const bodyStyle = classnames("px-2", "py-4")

	const getFaces = faces.map((label: any, idx: number) => {
		const act = getAction(idx, actions) as Action
		const {activeCb, answerCb} = cacheCallbacks(cacheCb, update, act)

		return <GenGameCard stage={stage}
			act={act}
			coset={game?.coset || "english"}
			label={label}
			key={idx}
			idx={idx}
			actions={actions}
			mode={mode}
			gameOn={isGameOn}
			activeCb={activeCb}
			answerCb={answerCb}
		/>
	}
	)

	return (<div className="p-4 shadow-md rounded text-light bg-main-3 ">
		<Header {...props} />
		{isLocal && <div>My coset is {game?.coset}</div>}
		<div className={bodyStyle}>
			<CardGrid cols={10}>
				{getFaces}
			</CardGrid>
		</div>
	</div>)
}
