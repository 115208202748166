import classed from "classed-components"
import { useClickOutside } from "hook"
import React, { useRef } from "react"

export const Inner = classed.div(['modal-inner'])

interface args {
	children:any
	shutDown:Function
	keepOpenClickOutside?: boolean
}

export const ModalInner = (props:args) => {
	const { children, shutDown, keepOpenClickOutside } = props
	const popoverRef:any = useRef(null)
	useClickOutside(popoverRef, () => {
		if (!keepOpenClickOutside) {
			shutDown()
		}
	})
	return (<div ref={popoverRef}>
		<Inner>{children}</Inner>
	</div>)
}
