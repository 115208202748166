import { CenterDesktop, FishBox } from 'containers/desktop';
import { IconButton } from "fui/button"
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from "react-hook-form"
import { useHistory } from 'react-router-dom';
import { usePasswordAction } from "services/api"
import { ErrorMsg } from "./error-msg"

interface IFormInput {
	userName:string
	email:string
	password:string
	confirmPassword:string
}

const emailReg = /^\S+@\S+\.\S+$/

export const SignUp = () => {
	const {
		register, handleSubmit, control,
	} = useForm<IFormInput>({ mode: "all" });
	const { errors } = useFormState({ control });
	const [errorMsgs, setErrorMsgs] = useState<Partial<IFormInput>>({})
	const { createAccount, loading, data } = usePasswordAction()
	const [created, setCreated] = useState(false)
	
	const onSubmit = async (data:IFormInput) => {
		const msgs:Partial<IFormInput> = {}
		if (data.userName.length < 5) {
			msgs.userName = 'at least 5 characters long'
		}
		if (data.userName.includes(" ")) {
			msgs.userName = 'select a user name with spaces'
		}
		if (data.password.length < 8) {
			msgs.password = 'at least 8 characters long'
		}
		if (data.password !== data.confirmPassword) {
			msgs.password = 'please confirm the password'
		}
		setErrorMsgs({ ...msgs })
		if (Object.keys(msgs).length === 0) {
			await createAccount({ ...data })
		}
	}
	
	useEffect(() => {
		const keys = Object.keys(errors)
		console.log(keys)
	}, [errors, setErrorMsgs])
	
	useEffect(() => {
		const { status, errorMessage } = data || {}
		if (status && status === "notOK") {
			if (errorMessage.includes("email")) {
				setErrorMsgs({ email: errorMessage })
			} else {
				setErrorMsgs({ userName: errorMessage })
			}
		}
		if (status && status === "OK") {
			setCreated(true)
		}
	}, [data])
	
	const history = useHistory()
	const toSignIn = () => history.push("/signIn")
	
	
	return <CenterDesktop>
		<FishBox width="w-72" title="Sign up for an Account">
			{
				!created && <div>
					<form>
						<input
							type="text"
							className="fish-input mt-4"
							placeholder="User Name"
							{...register("userName", { required: true })}
						/>
						<ErrorMsg message={errorMsgs.userName}/>
						<input
							type="text"
							className="fish-input mt-4"
							placeholder="Email"
							{...register("email", { required: true, pattern: emailReg })}
						/>
						<ErrorMsg message={errorMsgs.email}/>
						<input
							type="password"
							className="fish-input mt-4"
							placeholder="Password"
							{...register("password", { required: true })}
						/>
						<ErrorMsg message={errorMsgs.password}/>
						<input
							type="password"
							className="fish-input mt-4"
							placeholder="Confirm Password"
							{...register("confirmPassword", { required: true })}
						/>
						{
							!loading &&
							<div className="mt-4">
								<IconButton
									variant="color"
									onClick={handleSubmit(onSubmit)}
								>
									SUBMIT
								</IconButton>
							</div>
						}

					</form>
					<div className="flex flex-row justify-end mt-2">
						<IconButton variant="link" onClick={toSignIn}>
							member already
						</IconButton>
					</div>
				</div>
			}
			{
				created && <div>
					<hr className="alt my-2"/>
					<div className="text-alt-3 mt-4">
						Thank you for your registration! Your account is now ready to use.
					</div>
					<div className="flex flex-row justify-end mt-2">
						<IconButton className="px-2" variant="color" onClick={toSignIn}>
							Sign In
						</IconButton>
					</div>
				</div>
			}
		</FishBox>
	</CenterDesktop>
}
