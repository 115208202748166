import classed from "classed-components"
import { CardDisplayType } from "containers/game-types"
import { FacePad } from "containers/pad/face-pad"
import React, { useMemo } from "react"
import { IoTriangleSharp } from "react-icons/io5"
import { Action } from "services"
import { hideAnswer } from "services/rand"
import './select-pad.scss'

const cn = require("classnames")

export const anchorColor = "main-1"

export const borderClass = cn(
	"border-4",
	`border-${anchorColor}`,
	"border-opacity-100"
)

const backgroundClass = cn("bg-main-3")
const topStyle = cn(
	"h8",
	"transparent",
	"bg-opacity-0",
	`text-${anchorColor}`,
	"flex flex-row",
	" justify-center"
)

const letters = [0, 1, 2, 3, 4, 5, 6, 7, 8]

interface SelectPadProps {
	action:Action,
	nextRnd?:number
	ref:any
	answerCallback?:Function
	realLabel?:number
}

export const SelectionPad = React.forwardRef((props:SelectPadProps, ref:any) => {
	const { nextRnd, answerCallback, realLabel, action } = props
	const hiddenAnswers = useMemo(() => {
		const answers = hideAnswer(action, realLabel!)
		console.log(`[hidden]`, answers)
		return answers
	}, [action, realLabel])
	const MainArea = classed.div([borderClass, backgroundClass])
	const KeyArea = classed.div(
		"py-3 grid grid-flow-row grid-cols-3 grid-rows-3 gap-2 w-60"
	)
	const NextNumArea = classed.div(
		"py-3 grid grid-flow-row grid-cols-3 gap-2 w-60"
	)
	const TopAnchor = () => (
		<div style={{ position: "relative", top: "2px" }} className={topStyle}>
			<IoTriangleSharp/>
		</div>
	)
	
	return (
		<div className="select-pad" ref={ref}>
			<TopAnchor/>
			<MainArea>
				<KeyArea>
					{
						letters.map((l, idx:number) => (
							<FacePad key={l} keyCap={l + 1}
							         action={action}
									 coset={action.coset}
							         realLabel={realLabel}
							         answerCallback={answerCallback}
							         displayType={CardDisplayType.GuessWithKey}
							         label={hiddenAnswers[idx]}/>))
					}
				</KeyArea>
				{/*<NextNumArea>*/}
				{/*	<FacePad action={action} displayType={CardDisplayType.CurrentUnknown}/>*/}
				{/*	<img className=""*/}
				{/*	     src="https://clipartstation.com/wp-content/uploads/2018/09/arrow-clipart-transparent-background-1.png"*/}
				{/*	     alt=""/>*/}
				{/*	<FacePad  coset={action.coset} label={nextRnd} displayType={CardDisplayType.NextNum}/>*/}
				{/*</NextNumArea>*/}
			</MainArea>
		</div>
	)
})
