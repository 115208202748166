import { BaseGameCardArgs, CardDisplayType } from "containers/game-types"
import { ComboPad } from "containers/pad"
import React, { useMemo } from "react"
import { Action } from 'services'

export const How = () => {
	
	const action = useMemo(() => {
		const action:Action = {
			id: Math.random().toString(),
			coset: "english",
			startedAt: new Date().valueOf(), milliSeconds: 20000,
			nextRnd: 8
		} as any
		return action
	}, [])
	
	return (
		<div className="container text-alt-2 mx-auto pt-24">
			<div className="text-2xl font-extrabold mb-4">The Rules</div>
			<div className="grid grid-cols-2">
				<div className="bg-main-3 p-8 rounded">
					<div className="text-2xl text-alt-1 my-4">Memorization</div>
					<div className="flex flex-row gap-1">
						<ComboPad {...BaseGameCardArgs} action={action}/> <ComboPad {...BaseGameCardArgs} />
						<ComboPad expandConfig={{}} {...BaseGameCardArgs}
						          nextRnd={8} action={action}
						          displayType={CardDisplayType.Resolving}/>
						<ComboPad {...BaseGameCardArgs} action={action}/>
						<ComboPad {...BaseGameCardArgs} action={action}/>
					</div>
					<div className="flex flex-row gap-1 mt-2">
						<ComboPad {...BaseGameCardArgs} action={action}/>
						<ComboPad {...BaseGameCardArgs} action={action}/>
						<ComboPad {...BaseGameCardArgs} action={action}/>
						<ComboPad {...BaseGameCardArgs} action={action}/>
						<ComboPad {...BaseGameCardArgs} displayType={CardDisplayType.Challenged}
						          action={action}/>
					
					</div>
					<div className="font-hand text-2xl my-4">
						You have 30 seconds to remember the cards.
					</div>
				</div>
			</div>
		
		</div>
	)
}
