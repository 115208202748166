import React from "react"

interface Args {
	className?: string
	onClick?: Function
}

export const withWrapper = (WrappedComponent: Function) => {

	return (props: Args) => {
		const { className, ...rest } = props
		return (
			<WrappedComponent className={className || ""} {...rest} />
		);
	}
};