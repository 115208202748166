import classed from "classed-components";
import { Container } from "containers";
import { ButtonGroup, IconButton } from "fui";
import React from "react";
import { BiTable } from "react-icons/bi";
import { GiElephant } from "react-icons/gi";
import { IoIosTimer } from "react-icons/io";

interface StarterArg {
	fineTune: Function;
	onStart: Function;
}

const Heading = classed.div([
	"flex",
	"flex-row",
	"text-c1-700",
	"my-4",
	"ml-1",
]);
const HeadingLabel = classed.div(["font-bold", "ml-1", "text-alt-3"]);
const Unit = classed.div(["bg-main-3-to-5", "rounded p-4"]);

export const Starter = (props: StarterArg) => {
	const { fineTune, onStart } = props;
	const onCardNumbers = (label: string, value: any) => {
		fineTune({ numOfCards: value });
	};
	const onLevel = (label: string, value: any) => {
		fineTune({ level: label });
	};
	const onLength = (label: string, value: any) => {
		fineTune({ length: value });
	};
	return (
		<>
			<Container className="text-alt-2 container mx-auto">
				<div className="text-alt-4 text-3xl">Practice</div>
				<div className="text-alt-2 text-xl my-2">
					<p>Select the preference and click start.</p>
				</div>

				<div className="grid-cols-3 grid gap-3">
					<Unit>
						<Heading>
							<BiTable className="text-2xl font-bold" />
							<HeadingLabel>Number of Cards</HeadingLabel>
						</Heading>
						<div className="flex flex-row ">
							<ButtonGroup
								onClick={onCardNumbers}
								defaultLabel="10"
								captions={[
									{ label: "10", value: 10 },
									{
										label: "20",
										value: 20,
									},
									{ label: "30", value: 30 },
								]}
							/>
						</div>
					</Unit>
					<Unit>
						<Heading>
							<IoIosTimer className="text-2xl font-bold" />
							<HeadingLabel>Practice Time</HeadingLabel>
						</Heading>
						<div className="flex flex-row ">
							<ButtonGroup
								defaultLabel="3 m"
								onClick={onLength}
								captions={[
									{ label: "1 m", value: 1 },
									{
										label: "3 m",
										value: 3,
									},
									{ label: "5 m", value: 5 },
								]}
							/>
						</div>
					</Unit>
					<Unit>
						<Heading>
							<GiElephant className="text-2xl font-bold" />
							<HeadingLabel>Difficulty</HeadingLabel>
						</Heading>
						<div className="flex flex-row ">
							<ButtonGroup
								defaultLabel="moderate"
								onClick={onLevel}
								captions={[
									{ label: "easy" },
									{ label: "moderate" },
									{ label: "hard" },
								]}
							/>
						</div>
					</Unit>
				</div>
			</Container>
			<Container>
				<div className="mt-12 flex flex-row justify-end">
					<IconButton
						className="text-xl font-display px-4 py-auto"
						onClick={() => onStart()}
						variant="color"
					>
						Start
					</IconButton>
				</div>
			</Container>
		</>
	);
};
