export interface CloudinaryResource {
	asset_id:string;
	public_id:string;
	version:number;
	version_id:string;
	signature:string;
	width:number;
	height:number;
	format:string;
	resource_type:string;
	created_at:string;
	tags:any[];
	bytes:number;
	type:string;
	etag:string;
	placeholder:boolean;
	url:string;
	secure_url:string;
	overwritten:boolean;
	original_filename:string;
	eager:Eager[];
}

export interface Eager {
	transformation:string;
	width:number;
	height:number;
	bytes:number;
	format:string;
	url:string;
	secure_url:string;
}

export function getEagerUrl (info:CloudinaryResource)
{
	const { eager } = info
	return (eager && eager.length > 0) ?
		   eager[0].secure_url : ""
}
