import { drawTill9 } from "services/coset"
import { Action } from "services/gameTypes"

const randomFactory = require('random-seed')
const stringHash = require("string-hash");
const rand = randomFactory.create()

export const defaultRnd = rand

export const btw0And1 = () => {
	return rand.random()
}

export const btwMinAndMax = (min:number, max:number) => {
	return rand.intBetween(min, max)
}


const fillPopulation = (min:number, max:number) => {
	const base = Math.floor(min)
	const length = Math.floor(max) - base + 1
	return Array.from(new Array(length), (x, i) => base + i);
}

const shuffleArray = (source:number[], seed:number) => {
	const rnd = randomFactory.create(seed)
	const l = source.length
	const cnt = l * 3;
	for (let i = 0; i < cnt; i++) {
		const m = rnd(l)
		const n = rnd(l)
		const temp = source[m]
		source[m] = source[n]
		source[n] = temp
	}
	return source
}


export const hideAnswer = (action:Action, answer: number) => {
	const seed = stringHash(action.id)
	const rnd = randomFactory.create(seed)
	const position = seed % 9;
	console.log('seed position....',  position, action.cardIdx)
	const disguised = drawTill9(action.coset, [answer], rnd)
	disguised.shift()
	disguised[position] = answer
	return disguised
}




