import { OnlineUser } from "fui"
import React from "react";
import { IUser } from "services/types";

interface whoOpenArgs {
	users:IUser[];
	fetching?:boolean
}

export const WhoOpen = (props:whoOpenArgs) => {
	const { users, fetching } = props
	return (
		<div>
			<div className="grid lg:grid-cols-3 grid-cols-3 gap-3 mt-2">
				{!fetching &&
				users &&
				users.map((u, idx) => (<OnlineUser key={idx} user={u}/>
				))}
			</div>
		</div>
	);
};
