import axios from "axios";
import { IconButton } from "fui";
import { invariant } from "logs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { ProfileLink } from "services";
import { getEagerUrl } from "services/cloudinary"
import { useUpdateUser } from "services/user"
import "./avatar.css";

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas (canvas:any, newWidth:number, newHeight:number)
{
	const tmpCanvas = document.createElement("canvas");
	tmpCanvas.width = newWidth;
	tmpCanvas.height = newHeight;
	
	const ctx:any = tmpCanvas.getContext("2d");
	ctx.drawImage(
		canvas,
		0,
		0,
		canvas.width,
		canvas.height,
		0,
		0,
		newWidth,
		newHeight
	);
	
	return tmpCanvas;
}

function generateDownload (previewCanvas:any, crop:any)
{
	if (!crop || !previewCanvas) {
		return;
	}
	
	const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
	
	canvas.toBlob(
		(blob) => {
			const previewUrl = window.URL.createObjectURL(blob);
			
			const anchor = document.createElement("a");
			anchor.download = "cropPreview.png";
			anchor.href = URL.createObjectURL(blob);
			anchor.click();
			
			window.URL.revokeObjectURL(previewUrl);
		},
		"image/png",
		1
	);
}

function getCroppedImg (image:any, crop:any, fileName:any)
{
	const canvas = document.createElement("canvas");
	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = crop.width;
	canvas.height = crop.height;
	const ctx:any = canvas.getContext("2d");
	
	ctx.drawImage(
		image,
		crop.x * scaleX,
		crop.y * scaleY,
		crop.width * scaleX,
		crop.height * scaleY,
		0,
		0,
		crop.width,
		crop.height
	);
	
	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob:any) => {
				invariant.log(`what is this blob....`, blob);
				blob.name = fileName;
				resolve(blob);
			},
			"image/jpeg",
			1
		);
		
	})
	
	
}

interface cropArg {
	width?:number;
	height?:number;
}

interface args {
	src?:any;
	link:ProfileLink;
	postSaving:Function
	saved: Function
}

export const AvatarCutter = (props:args) => {
	const { src, link, saved } = props;
	const imgRef = useRef(null);
	// const previewCanvasRef = useRef(null);
	const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
	const [completedCrop, setCompletedCrop] = useState<cropArg>({});
	const { setUser } = useUpdateUser()
	
	const onLoad = useCallback((img:any) => {
		imgRef.current = img;
	}, []);
	
	useEffect(() => {
		if (!completedCrop || !imgRef.current) {
			return;
		}
	}, [completedCrop]);
	
	const uploadFile = async (blob:any) => {
		const formData = new FormData();
		formData.append("file", blob);
		const keys = Object.keys(link).filter((k) => k !== "url");
		invariant.log("keys", keys)
		keys.forEach((k) => {
			formData.append(k, (link as any)[k]);
		});
		invariant.log(`formData`, formData, link);
		return axios.post(link!.url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
	};
	
	const handler = async () => {
		invariant.log(`[avatar]`, completedCrop);
		props.postSaving()
		const blob = await getCroppedImg(imgRef.current, completedCrop, "what")
		try {
			const resp = await uploadFile(blob)
			const { data } = resp
			const photo = getEagerUrl(data)
			const replaceUrl = photo.replaceAll(new RegExp("https://res.", "ig"), 'https://res-1.')
			if (photo) {
				invariant.log('[avatar]', replaceUrl)
				const patch = await setUser({ photo: replaceUrl })
				await saved()
			}
		}
		catch (err) {
			invariant.log(`return error ....`, err)
		}
		// props.postSaving()
	};
	
	// @ts-ignore
	return (
		<div className="pt-4 py-4">
			<div className="flex flex-row justify-around">
				<ReactCrop
					className="uncut"
					src={props.src}
					onImageLoaded={onLoad}
					crop={crop as any}
					onChange={(c:any) => setCrop(c)}
					onComplete={(c:any) => setCompletedCrop(c)}
				/>
			</div>
			<div className="flex flex-row justify-end mt-4">
				<IconButton
					onClick={handler}
					variant="color"
					className="w-16 px-4 py-2"
				>
					OK
				</IconButton>
			</div>
		</div>
	);
};
