import React from 'react'
import { useLocation } from "react-router-dom"

const defaultMsg = 'Reset password token has expired, please request a new one.'

export const PasswordMessage = () => {
	const { state: { msg } } = useLocation() as any
	
	return (<div className="text-alt-2 text-2xl">
		{msg || defaultMsg}
	</div>);
}
