import { MatchMessage } from "services/match";

const signals = ["PING", "INVITE", "AGREE", "REJECT"] as const;
export type SignalType = typeof signals[number];

export type InviteType = {
	from: string;
	photo: string;
	replyTo: string;
};

export type PeerInfo = {
	fishAddress: string;
	elephantAddress: string;
};

type Handshake = "handshake"
export type HandshakeMessageType =
	| {kind: Handshake, signal: "INVITE"; data: InviteType }
	| {kind: Handshake, signal: "AGREE" }
	| {kind: Handshake, signal: "REJECT" }
	| {kind: Handshake, signal: "CONNECT"; data: MatchMessage };

export const isHandshake = (msg: any) => {
	const {kind}= msg
	return kind && kind === "handshake"
}

export function isInvitation(msg: HandshakeMessageType) {
	return msg.signal === "INVITE";
}

export function isGameOn(msg: HandshakeMessageType) {
	return msg.signal === "AGREE";
}

export function isNotNow(msg: HandshakeMessageType) {
	return msg.signal === "REJECT";
}

export function isConnectMsg(msg: HandshakeMessageType) {
	return msg.signal === "CONNECT";
}

