import React from "react";
import { Action, GameStage, PlayMode } from "services";
import { ComboPad } from "containers/pad"

interface GenCardProps {
	act: Action,
	stage: GameStage,
	coset: string,
	label: number,
	idx: any,
	actions: Action[],
	mode: PlayMode,
	gameOn: boolean,
	activeCb: Function,
	answerCb: Function
}


export const GenGameCard = (
	props: GenCardProps
) => {
	const {
		act,
		stage,
		coset,
		label,
		idx,
		mode,
		gameOn,
		activeCb,
		answerCb,
	} = props


	return (
		<ComboPad
			key={idx}
			idx={idx}
			stage={stage}
			action={act}
			label={label}
			actionId={act?.id}
			showPopup={act?.active}
			mode={mode}
			nextRnd={act ? act.nextRnd : undefined}
			gameOn={gameOn}
			answerCallback={answerCb}
			activeCallback={activeCb}
			coset={coset}
		/>
	);
}

