import { randId } from "activeDoc"
import { CardDisplayType } from "containers/game-types"
import { FacePad } from "containers/pad/face-pad"
import { WithExpanded } from "containers/pad/types"
import { CountDown } from "fui/count-down"
import React, { useMemo } from "react"
import { JumpFish } from "routes/landing/intro/jumpFish"


export const FishOrCountDown = (props: WithExpanded) => {
	const myId = useMemo(() => randId(), [])

	const { displayType, countDown, answerCallback, expanded, action, nextRnd } = props
	// next session - deal with the countdown ---------
	const { lastFor, startedAt } = countDown || {}

	const handleExpires = useMemo(() => {
		console.log('** countdown', `answerCallback changed ${myId}`, answerCallback)
		if (answerCallback) return () => answerCallback(false)
		return undefined
	}, [answerCallback, myId])


	const challenged = (displayType === CardDisplayType.Challenged)

	return (
		<>
			{displayType === CardDisplayType.Resolving && (
				<span>
					{expanded && <FacePad coset={action?.coset} label={nextRnd}
						displayType={CardDisplayType.NextNum} />}
					{!expanded && <JumpFish />}
				</span>
			)}
			{challenged && !expanded &&
				<CountDown
					callback={handleExpires}
					lastFor={lastFor || 5000}
					startedAt={startedAt!}
				/>
			}
			{challenged && expanded &&
				<div>
					<CountDown callback={handleExpires}
						lastFor={lastFor || 5000}
						startedAt={startedAt!} overlaid={true} />
					<FacePad coset={action?.coset} label={nextRnd}
						displayType={CardDisplayType.NextNum} />
				</div>

			}

		</>
	)
}
