import { Subject } from "rxjs"
import { DocChanges } from "./types"


export type SomeStringMayBe = string | undefined

export interface RollupEquipped {
	askingSeed$:Subject<SomeStringMayBe>
	askedSeed$:Subject<SomeStringMayBe>
	sendingInstructions$:Subject<DocChanges[]>
	receivedInstructions$:Subject<DocChanges[]>
}

// to do :
export interface ActiveSync extends RollupEquipped {
	id:string
	incoming$:() => Subject<DocChanges>
	outgoing$:() => Subject<DocChanges>
}


export const chainActiveSync = (upstream:ActiveSync, downstream:ActiveSync) => {
	const subs:any[] = []
	// what comes in must coming down
	subs.push(upstream.incoming$().subscribe(downstream.incoming$()))
	// what goes out must goes to the top
	subs.push(downstream.outgoing$().subscribe(upstream.outgoing$()))
	// deal with seeding requests
	
	subs.push(downstream.askingSeed$.subscribe(upstream.askingSeed$))
	subs.push(upstream.askedSeed$.subscribe(downstream.askedSeed$))
	subs.push(downstream.sendingInstructions$.subscribe(upstream.sendingInstructions$))
	subs.push(upstream.receivedInstructions$.subscribe(downstream.receivedInstructions$))
	return subs
}
