import { getCurrentMatchNub, initRepo } from "services"
import { StateInfo } from "services/active-machine"

export const hostHandler = async (refreshRepo:Function, current?:StateInfo) => {
	const { value: stateValue, } = current || {};
	const nub = getCurrentMatchNub();
	if (stateValue === "rendezvous") {
		// we suppose to create a new repo and send in message, HOW?
		refreshRepo(initRepo());
		await nub.sendMatchMsg(`event://REPO_READY`);
	}
};
