import { IconButton } from "fui/button"
import React from "react"
import { IoIosClose } from "react-icons/io"
import { useHistory } from 'react-router'

interface Args {
	children?: any
}

export const CenterDesktop = (props: Args) => {
	const history = useHistory()
	const toHome = () => {history.push("/")}
	const {children} = props
	return <div className="text-main bg-main-4 font-display h-screen login-panel pb-64">
		<div className="flex flex-right w-full justify-end">
			<IconButton
				className="mr-4 mt-4 text-3xl"
				variant="link"
				onClick={toHome}
			>
				<IoIosClose/>
			</IconButton>
		</div>
		<div className="container mx-auto h-full flex justify-center items-center">
			<div>
				{children}
			</div>

		</div>
	</div>
}
