import { useSubs } from "hook"
import React, { useEffect, useState } from "react"
import { Observable } from "rxjs"
import { ClockInfo, ScoreCard, timeConversion } from "services"
import { StageCountDown } from "services/stage-countdown"

interface Args {
	info$?:Observable<Partial<StageCountDown>>
}


export const ScoreDiv = (props:{ score:ScoreCard }) => {
	const { score } = props
	const { solved, unsolved } = score
	
	return (solved || unsolved) ?
	       (
		       <div className="flex flex-row justify-between text-alt-3 score ml-4">
			       <div>
				       <span className="label">score</span>
				       <span className="number ml-2">{score.solved - score.unsolved}</span>
			       </div>
		       </div>
	       ) : <></>
}


export const CountDownClock = (props:Args) => {
	const subs = useSubs()
	const { info$ } = props
	const [stage, setStage] = useState("")
	const [display, setDisplay] = useState<Partial<ClockInfo>>({})
	
	useEffect(() => {
		if (info$) {
			subs.push(
				info$.subscribe((info) => {
					setStage(info.stage || "")
					setDisplay(timeConversion(info.countdown || 0))
				})
			)
		}
	}, [info$, subs])
	
	return stage ? (
		<div className="flex flex-row justify-between text-alt-3 score">
			<div>
				<span className="label">{stage}</span>
				<span className="ml-4">
                    <span className="time">{display.minutes}</span>
                    <span className="">m</span>
                    <span className="time ml-4">
                        {display.seconds?.toFixed(2)}
                    </span>
                    <span className="">s</span>
                </span>
			</div>
		</div>
	) : <></>
}
