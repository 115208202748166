import { timer } from "rxjs"
import { API } from "services"
import {invariant} from 'logs'


const listen = (sessionId:string, callback:Function) => {
	const source = timer(1000, 1000);
	const subscription = source.subscribe(val => {
		API.pullingSession(sessionId).then((data:any) => {
			const { jwt, profile, pubnub } = data
			if (jwt) {
				invariant.log('received', { jwt, profile, pubnub })		
				subscription.unsubscribe()
				callback({ jwt, profile, sessionId, pubnub })
			} else {
				invariant.log(`pulling session ... at ${val}`)
			}
		})
	});
};


export const JwtListener = {
	listen,
};
