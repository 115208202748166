import { useMemo } from "react"
import invariant from "ts-invariant"
import { gql, useMutation } from "urql"

const resetPasswordGql = gql`
    mutation update_password($id: ID!, $password: String) {
        updateUser(input: {id: $id, password: $password}) {
            user {
                id
                email
            }
        }
    }
`


export const useUpdatePassword = () => {
	const [{ fetching, data, error }, update] = useMutation(resetPasswordGql);
	const updatePassword = useMemo(() => {
		                               const internal = async (userId:number, password:string) => {
			                               const variables = { id: userId, password }
			                               const rt = await update(variables)
			                               // let's
			                               invariant.log('the person has password data is -->'
				                               , rt)
		                               }
		                               return internal
	                               }, [update]
	)
	
	
	return {
		updatePassword,
		fetching, data, error
	}
}

