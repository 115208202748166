import React from "react"
import './loader.scss'
const cn = require("classnames")

interface args{
	className?: string
}

export const BarLoader = (props: args) => {
	const classNames = cn([props.className, 'spinner'])
	return (
		<div className={classNames}>
			<div className="rect1" />
			<div className="rect2" />
			<div className="rect3" />
			<div className="rect4" />
			<div className="rect5" />
		</div>
	)
}

