import React from "react"
import { JumpFish } from "routes/landing/intro/jumpFish"

const cn = require("classnames"
)
export const FacePadUnknown = () => {
	const innerCls = cn(
		"inline-grid", "justify-center", "font-base",
		"face-pad-base"
	)
	return (<div className="flex flex-row justify-center">
		<div className={innerCls}>
			<span> <JumpFish/> </span></div>
	</div>)
	
}

