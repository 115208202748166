import { DocChanges } from "./types"

export interface PeerMessageTracking {
	id:string
	sender?:string,
	peerId?:string
	requireAck?:boolean
	senderTimestamp?:number
	ackTimestamp?:number
}

export interface PeerMessage extends PeerMessageTracking {
	topic:string
	subTopic:string
	data:any
}

export interface SyncMessage extends PeerMessage {
	topic:"SYNC"
	subTopic:"DATA"
	data:DocChanges
}

export interface RollUpMessage extends PeerMessage {
	topic:"ROLLUP"
	data:undefined | null | string[]
}

export const isRollUpMessage = (d: PeerMessage) => {
	const {topic} = d
	return topic === 'ROLLUP'
}

export const isSyncMessage = (d: PeerMessage) => {
	const {topic} = d
	return topic === 'SYNC'
}




