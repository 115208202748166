import clownFish from "assets/images/clown-fish.svg"
import elephant from "assets/images/elephant.svg"
import vs from "assets/images/vs.svg"
import React from "react"

export const FishVsElephant = () => {
	return (
		<div className="">
			<div className="flex-row flex items-center justify-center mt-10">
				<div>
					<img className="w-64" src={clownFish} alt="goldFish"/>
				</div>
				<div>
					<img className="w-16 mx-8" src={vs} alt="goldFish"/>
				</div>
				<div>
					<img className="w-64" src={elephant} alt="goldFish"/>
				</div>
			</div>

		
		</div>
	)
}
