import { ActiveRepo } from "activeDoc"
import { useSubs } from "hook/use-subs"
import { invariant } from "logs"
import { useMemo, useState } from "react"
import { Subject } from "rxjs"
import { distinctUntilChanged, filter, map, tap } from "rxjs/operators"
import { Action, Game, isSame, ScoreCard } from "services"



export const useAsHandViewer = (repo:ActiveRepo, player:string) => {
	const [faces, setFaces] = useState<(string | number)[]>([]);
	const [actions, setActions] = useState<Action[]>([]);
	const [score, setScore] = useState<ScoreCard>({ solved: 0, unsolved: 0 })
	const subs = useSubs();
	useMemo(() => {
		const gameSnapshot$:Subject<Game> = new Subject<Game>();
		subs.push(
			gameSnapshot$
				.pipe(
					// tap(a => {invariant.log(`### tap into the original doc`, a)}),
					map((d) => d.actions || []),
					map((actions) =>
							actions.filter((a) => a.player === player)
								   .filter((a) => a.status !== "ATTACK")
					),
					distinctUntilChanged(isSame)
					// tap(a => {invariant.log(`### tap into actions`, a)})
				)
				.subscribe((actions:Action[]) => {
					setActions(actions)
					setScore({
								 solved: actions.filter((a) => a.status === "RESOLVED").length,
								 unsolved: actions.filter((a) => a.status === "LOST").length,
							 })
				})
		);
		
		subs.push(
			gameSnapshot$
				.pipe(
					// tap(doc => {invariant.log(`*** tap into FACES ==>`, doc.fish)}),
					map((d) => (player === "fish" ? d.fish : d.elephants)),
					map((d) => d || []),
					distinctUntilChanged(isSame)
					// tap(faces => {invariant.log(`### tap into ==>`, faces)})
				)
				.subscribe((faces) => setFaces(faces))
		);
		
		subs.push(
			repo.docSnapshot$
				.pipe(
					filter((d) => d.id === "game"),
					tap(d => {invariant.log(`[why] Publishing gameSnapshot$(2) ${d.reason}`)}),
					map((d) => repo.getDoc("game") as Game),
				)
				.subscribe(gameSnapshot$)
		);
		
	}, [subs, repo, player]);
	return { faces, actions, score };
};

