import { yamlObj } from "activeDoc/types"
import React from 'react';

interface Args {
	data?:any
	error?:any
}

export const DataError = (props:Args) => {
	const {  error } = props
	if (error) {
		const {message} = error
		return <div className="text-warning">{message}</div>
	}
	else {
		return  <></>
	}
}
