import { useSubs } from "hook";
import React, { useEffect, useState } from "react";
import { unsubscribeAll } from "services";
import { toaster$, hideToaster$, ToasterMessage } from "services/toaster";
import { Toast } from "./toast";

// markup
// https://tailwindui.com/components/application-ui/overlays/notifications

export const ToasterContainer = () => {
	const [msgs, setMsgs] = useState<ToasterMessage[]>([]);
	const subs = useSubs();
	const hasMessage = () => msgs.length > 0;
	useEffect(() => {
		unsubscribeAll(subs)()
		subs.push(
			toaster$.subscribe((m) => {
				setMsgs((msgs) => [...msgs, m]);
			})
		);
		subs.push(
			hideToaster$.subscribe((id) => {
				setMsgs((msgs) => msgs.filter((m) => m.id !== id));
			})
		);
	}, [subs]);

	return (
		<>
		{ hasMessage() && 
			<div className="fixed top-2 z-50 mt-16 right-2 p-4 grid grid-cols-1 gap-4">
				{msgs.map(m => (<Toast key={m.id} msg={m} />))}
			</div>
		}
		</>
	);
};
