import React from "react";
import { MeetStatus } from "services/api";
import { FaCircle, FaMinusCircle } from "react-icons/fa";


const FreeIcon = () => {
	return (
		<span className="free">
			<FaCircle />
		</span>
	);
};

const BusyIcon = () => {
	return (
		<span className="busy">
			<FaMinusCircle />
		</span>
	);
};

const UnknownIcon = () => {
	return (
		<span className="unknown">
			<FaMinusCircle />
		</span>
	);
};

interface withMeetStatus {
	status: MeetStatus;
	className?: string;
}

export const StatusIcon = (props: withMeetStatus) => {
	function getChild() {
		switch (props.status) {
			case "BUSY":
				return <BusyIcon />;
			case "FREE":
				return <FreeIcon />;
			case "UNKNOWN":
				return <UnknownIcon />;
		}
	}
	return <div className="meet-status">{getChild()}</div>;
};
