import { Action, GameMode } from "services/gameTypes";
import { first2Move, getGameInstance, systemAction, } from "services/utils";
import { PubSub } from "./pub-hub";

export const drawNonTaken = (length:number, taken:number[]) => {
	let done = false;
	let idx = 0;
	let numOfAttempts = 0
	while (!done) {
		++numOfAttempts
		idx = Math.ceil(Math.random() * length) - 1;
		if (!taken.includes(idx)) break;
		if (numOfAttempts > 500) {
			idx = -1
			break
		}
	}
	return idx;
};

class Controller {
	protected mode:GameMode = "SINGLE_PERSON";
	
	constructor (private pubSub = PubSub) {}
	
	// generateAction = (player: string, originator: string) => {
	// 	const unfiltered = this.pubSub.getCurrent().actions;
	// 	const taken = unfiltered
	// 		.filter((a: Action) => {
	// 			return (
	// 				a.player === player &&
	// 				(a.status === "RESOLVING" || a.status === "LOST")
	// 			);
	// 		})
	// 		.map((a) => a.cardIdx);
	// 	const idx = drawNonTaken(this.pubSub.getCurrent().fish.length, taken);
	//
	// 	const act: Action = {
	// 		id: randId(),
	// 		cardIdx: idx,
	// 		status: "RESOLVING",
	// 		originator,
	// 		player,
	// 		nextRnd: get0To9(),
	// 		startedAt: unixTime(),
	// 	};
	// 	return act;
	// };
	
	public startNewGame (numOfCards:number = 20, mode:GameMode) {
		this.mode = mode;
		const instance = getGameInstance(numOfCards);
		const actions = this.pubSub.getCurrent().actions || [];
		const appendingSystem = actions.find((a) => a.player === "system")
		                        ? []
		                        : [systemAction(instance.coset)];
		const game = {
			...instance,
			status: "PREP",
			actions: [
				...appendingSystem,
				...actions.map((a) => ({ ...a, deleted: true })),
			],
		};
		this.pubSub.cleanUpReceiving();
		this.pubSub.publish(game);
	}
	
	public startNewPractice (numOfCards:number = 20, mode:GameMode) {
		this.mode = mode;
		const instance = getGameInstance(numOfCards);
		const game = {
			...instance,
			status: "PREP",
			actions: [systemAction(instance.coset), ...first2Move(instance)],
		};
		return game;
		// this.pubSub.cleanUpReceiving()
		// this.pubSub.publish(game)
	}
	
	public onActivateAction (actionId:string, active:boolean) {
		const act = this.getActionById(actionId) as Action;
		this.pubSub.publish({
			                    actions: [{ ...act, active }],
		                    });
	}
	
	// public challengeOn(onPlayer: string, source: string, milliSeconds: number) {
	// 	const act: Action = {
	// 		...this.generateAction(onPlayer, source),
	// 		milliSeconds,
	// 		startedAt:
	// 			this.mode === "SINGLE_PERSON"
	// 				? unixTime()
	// 				: (FirebaseService.serverTime as any),
	// 	};
	// 	this.pubSub.publish({ ...PubSub.getCurrent(), actions: [act] });
	// }
	
	// public onActionCompleted(actionId: string, isCorrect: boolean) {
	// 	const act = this.getActionById(actionId) as Action;
	// 	const milliSeconds = differenceInMilliseconds(
	// 		new Date(),
	// 		dateFromFuzzyUnix(act?.startedAt)
	// 	);
	// 	const updated: Action = {
	// 		...act,
	// 		status: isCorrect ? "RESOLVED" : "LOST",
	// 		active: false,
	// 		milliSeconds,
	// 	};
	// 	console.log(`updated`, updated);
	// 	const mySelf = act.player;
	// 	const opponent = mySelf === "fish" ? "elephant" : "fish";
	// 	const actions: Action[] = [updated];
	// 	if (act.originator === mySelf) {
	// 		if (isCorrect)
	// 			actions.push({
	// 				...this.generateAction(opponent, mySelf),
	// 				milliSeconds,
	// 				startedAt:
	// 					this.mode === "SINGLE_PERSON"
	// 						? unixTime()
	// 						: (FirebaseService.serverTime as any),
	// 			});
	// 		actions.push(this.generateAction(mySelf, mySelf));
	// 	}
	// 	const { fish, elephants } = PubSub.getCurrent();
	// 	let clone = mySelf === "fish" ? [...fish] : [...elephants];
	// 	clone[act.cardIdx] = act.nextRnd;
	// 	const pushing = {
	// 		actions,
	// 		fish: mySelf === "fish" ? clone : fish,
	// 		elephants: mySelf === "elephant" ? clone : elephants,
	// 	};
	// 	console.log(`pushing .... `, pushing);
	// 	this.pubSub.publish(pushing);
	// }
	
	private getActionById = (id:string) => {
		return this.pubSub.getCurrent().actions.find((a) => a.id === id);
	};
}

export const GameController = new Controller();
