import { Container } from "containers"
import { GameConsole } from "containers/game-console"
import { ButtonGroup } from "fui"
import { invariant } from "logs"
import React, { useState } from "react"

export const Dev = () => {
	const [mode, setMode] = useState("none")
	const roleHandler = (label:any, value:any) => {
		invariant.log(label, value)
		setMode(label)
	}
	return (
		<div className="h-screen bg-gradient-to-br from-c1-700 bg-c1-a00 ">
			<Container className="pt-24">
				<GameConsole/>
				<div className="flex-row flex justify-start bg-main-3 p-2 rounded items-baseline">
					<div className="text-alt-2 font-bold px-2">Select the participant mode:</div>
					<ButtonGroup onClick={roleHandler}
								 captions={[{ label: "fish" }, { label: "elephant" },
									 { label: "spectator" }]}/>
				</div>
				
				<div className="grid grid-cols-1 gap-5 mt-2 shadow-md">
					{/*{*/}
					{/*	(mode === "fish" || mode === "none") && <GameBoard player="fish" mode="PLAY"/>*/}
					{/*}*/}
					{/*{*/}
					{/*	(mode === "elephant" || mode === "none") && <GameBoard player="elephant" mode="PLAY"/>*/}
					{/*}*/}
					{/*{*/}
					{/*	(mode === "spectator" || mode === "none") &&*/}
					{/*	<>*/}
					{/*		<GameBoard player="elephant" mode="SPECTATOR"/>*/}
					{/*		<GameBoard player="fish" mode="SPECTATOR"/>*/}
					{/*	</>*/}
					{/*}*/}
				</div>
			</Container>
		</div>
	)
}
