import { PeerSync } from "activeDoc"
import { PeerConnection } from "activeDoc/types"
import { useEffect, useState } from 'react'
import {peerSync$} from 'services/api'

interface args {
	id?:string
}

export const usePeerSync = (option:args) => {
	const { id } = option
	const [peer] = useState(peerSync$.getValue())
	const [connections, setConnections] = useState<PeerConnection[]> ([])
	useEffect(() => {
		const sub = peer.onConnections$.subscribe(connections => setConnections(connections))
		return () => sub.unsubscribe()
	}, [peer])
	
	return {
		peer: peer,
		send: peer.send,
		connectTo: peer.connectTo,
		instanceId: peer.instanceId(),
		directInstanceId: () => peerSync$.getValue().instanceId() + "/" + peer.instanceId(),
		trace$: peer.trace$,
		connections
	}
}
