import React from "react"
import { getCosetChar } from "services"

interface CosetArgs {
	coset?: string
	label: number
}

export const CosetLabel = (props: CosetArgs) => {
	const {coset, label} = props
	return (<>{getCosetChar(coset || "english", label)}</>)
}
