type Level = "easy" | "moderate" | "hard"

export const nextTimeToAct = (level:Level) => {
	const draw = (min:number, max:number) => (Math.random() * (max - min) + min)
	switch (level) {
		case "easy":
			return draw(25, 40) * 1000
		case "moderate":
			return draw(15, 35) * 1000
		default:
			return draw(10, 20) * 1000
	}
}


export const useMachinePlayer = () => {
	let poolingHandle:any;
	let currentMark = 0
	let incremental = 200; // 200 mili second
	const pooling = (level:Level, isLocal: boolean, callback:Function) => {
		cancelPooling()
		let activateMark:number = (isLocal)? 5000: nextTimeToAct(level)
		setInterval(n => {
			currentMark += incremental
			if (currentMark >= activateMark) {
				callback(n)
				activateMark += nextTimeToAct(level)
			}
		}, incremental);
	}
	const cancelPooling = () => {
		if (poolingHandle) {
			clearInterval(poolingHandle);
			poolingHandle = undefined
		}
	}
	return { pooling, cancelPooling }
}
