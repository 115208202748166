import React, { useEffect, useState } from "react"

export interface IMsg {
	message?:string
}

export const ErrorMsg = (props:IMsg) => {
	const [display, setDisplay] = useState<string>("")
	useEffect(() => {
		const {message} = props
		setDisplay(message || "")
	}, [props, setDisplay])
	return (display) ? (<div className="pl-4 mt-1 text-red-400">
		                 {display}
	                 </div>)
	                 : (<></>)
}
