import { AppLayout } from "containers"
import { useIsLocal } from "hook";
import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Color } from "routes/beta"
import { Credit } from "routes/credit"
import { Dev } from "routes/dev"
import { Landing } from "routes/landing"
import { Meet } from "routes/meet"
import { ActivePage } from "routes/nodeHost/activePage"
import { SetPassword, ValidatePasswordToken } from "routes/reset-password"
import { How } from "./how";
import { Login } from './login'
import { ActiveMatch } from "./nodeHost";
import { Practice } from "./practice"
import { Setup } from "./setup"
import { SignIn, SignUp } from "./sign-in";

interface wrapperArgs {
	component: any
	layout: any
	path: string
}

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }: wrapperArgs) => {
	return (
		<Route {...rest} render={(props) =>
			<Layout {...props}>
				<Component {...props} />
			</Layout>
		} />
	);
}


export const Site = () => {
	const isLocal = useIsLocal()
	return (
		<Router>
			<Route exact path="/"> <Landing /> </Route>
			<Route exact path="/credit"><Credit /></Route>
			<Route exact path="/login"><Login /></Route>
			<Route exact path="/signIn"><SignIn /></Route>
			<Route exact path="/signUp"><SignUp /></Route>

			<Route path="/validatePasswordToken"><ValidatePasswordToken /></Route>
			<Route path="/setPassword"><SetPassword /></Route>

			<Route exact path="/color"><Color /></Route>
			<RouteWrapper layout={AppLayout} path="/practice" component={Practice} />
			{
				isLocal &&
				<RouteWrapper layout={AppLayout} path="/dev" component={Dev} />
			}
			<RouteWrapper layout={AppLayout} path="/nodePage" component={ActivePage} />
			<RouteWrapper layout={AppLayout} path="/meet" component={Meet} />
			<RouteWrapper layout={AppLayout} path="/profile" component={Setup} />
			<RouteWrapper layout={AppLayout} path="/how" component={How} />
			<RouteWrapper layout={AppLayout} path="/activeMatch/:mode/:matchId/:host/:peer"
				component={ActiveMatch} />

		</Router>
	)
}
