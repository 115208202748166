import { ActiveRepo } from "activeDoc"
import { useSubs } from "hook/use-subs"
import { useMemo, useState } from "react"
import { Subject } from "rxjs"
import { distinctUntilChanged, filter, map } from "rxjs/operators"
import { Action, Game, GameStage } from "services"

export const useAsGameViewer = (repo: ActiveRepo) => {
	const subs: any[] = useSubs();
	const [isGameOn, setGameOn] = useState(false);
	const [actions, setActions] = useState<Action[]>([]);
	const [stage, setStage] = useState<GameStage>("PREP");
	useMemo(() => {
		const gameSnapshot$: Subject<Game> = new Subject<Game>();
		subs.push(
			repo.docSnapshot$
				.pipe(
					filter((d) => d.id === "game"),
					map((d) => d.doc as Game)
				)
				.subscribe(gameSnapshot$)
		);
		subs.push(
			gameSnapshot$.subscribe((d) => setGameOn(d.stage !== "DONE"))
		);
		subs.push(gameSnapshot$.subscribe((d) => setActions(d.actions)));
		subs.push(
			gameSnapshot$
				.pipe(
					map((d) => d.stage),
					distinctUntilChanged()
				)
				.subscribe((stage) => setStage(stage))
		);
	}, [subs, repo]);
	
	return { isGameOn, stage, actions };
};
