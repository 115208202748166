import { invariant } from "logs"
import { matchPeer$, randId, toaster$, ToasterMessage } from "services"
import { onInvite$ } from "./nub"
import { HandshakeMessageType, InviteType, isNotNow } from "./types"

export const handshakeHandler = (msg:HandshakeMessageType) => {
	const { signal } = msg;
	const { data } = msg as any
	if (signal === "INVITE") {
		onInvite$.next(data as InviteType);
	}
	if (signal === "CONNECT") {
		const acceptMsg:ToasterMessage = {
			id: randId(),
			type: "INFO",
			main: "Invitation Accepted",
			subTitle: "Preparing the game page .... ",
		}
		toaster$.next(acceptMsg);
		invariant.log(`[CONNECT]`, msg, acceptMsg);
		setTimeout(() => {
			matchPeer$.next(data as any);
		}, 1000);
	}
	if (isNotNow(msg)) {
		toaster$.next(
			{
				id: randId(),
				type: "WARNING",
				main: "Not Now",
				subTitle: "The person you invited is not available at this time.",
			});
	}
}
