import { useIsLocal } from "hook"
import React from "react"
import { HiLogout } from "react-icons/hi"
import { useHistory } from "react-router-dom"
import { busy } from "services/api"
import { FveApp } from "services/fveApplication"
import { AvailabilityDropdown } from "./availability-dropdown"
import { IconButton } from "./button"
import { SvgLogo } from "./logo"
import { NavItem } from "./nav-item"

interface NavArgs {
	readonly transparent:boolean
}

export function Navbar (props:NavArgs) {
	const { user } = FveApp.useUser()
	const isLocal = useIsLocal()
	const history = useHistory()
	const onLogout = async () => {
		// we can logout the user first then tell the server what happened
		FveApp.logout()
		history.push("/")
		await busy()
	}
	return (
		<>
			<nav
				className="top-0 absolute z-50 w-full
					nav-bar
					bg-main-2
					flex flex-wrap items-center
					justify-between px-1 py-1
					navbar-expand-lg"
			>
				<div
					className="container px-4 mx-auto flex flex-row items-center justify-between w-100">
					<div className="">
						<SvgLogo/>
					</div>
					<div className="flex flex-row flex-grow ml-auto text-alt-2 justify-end">
						<NavItem
							matching="practice"
							onClick={() => history.push("/practice")}
						>
							<div>Practice</div>
						</NavItem>
						{isLocal &&
						<NavItem
							matching="dev"
							onClick={() => history.push("/dev")}
						>
							<div>Dev</div>
						</NavItem>
						}
						{
							isLocal &&
							<NavItem
								matching="nodePage"
								onClick={() => history.push("/nodePage")}
							>
								<div>Node</div>
							</NavItem>
						}
						
						<NavItem
							matching="meet"
							onClick={() => history.push("/meet")}
						>
							<div>Meet</div>
						</NavItem>
						<NavItem
							matching="how"
							onClick={() => history.push("/how")}
						>
							<div>How</div>
						</NavItem>
						<AvailabilityDropdown/>
						
						<img
							className="inline object-cover w-10 h-10 rounded-full cursor-pointer"
							src={user.photo}
							onClick={() => history.push("/profile")}
							alt={""}
						/>
						<NavItem>
							<IconButton
								className="ml-2"
								onClick={onLogout}
								variant="link"
							>
								<HiLogout/>
							</IconButton>
						</NavItem>
					</div>
				</div>
			</nav>
		</>
	)
}
