import { Change, Doc, from } from "automerge"
import { customAlphabet } from 'nanoid'
import { DataConnection } from "peerjs"
import YAML from 'yaml'
import { PeerMessage, PeerMessageTracking } from './messageTypes'

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)

export interface DocChanges {
	docId:string
	changes:Change[]
	reason?: string
}

export function randId ()
{
	return nanoid()
}

export function asDoc (d:any)
{
	return from(d)
}

export function yamlObj (obj:any)
{
	return YAML.stringify(obj)
}


export interface PeerConnection {
	connection:DataConnection,
	inward:boolean
}


export function getMsgTracking (msg:PeerMessage):PeerMessageTracking
{
	const { id, peerId, requireAck, senderTimestamp, ackTimestamp } = msg
	return { id, peerId, requireAck, senderTimestamp, ackTimestamp }
}

export function asPeerMessage (data:any):PeerMessage
{
	return {
		id: randId(),
		peerId: "",
		topic: "",
		subTopic: "",
		data
	}
}

export function isAck (message:PeerMessage):boolean
{
	return message.topic === "ack"
}

export function isRepo (message:PeerMessage):boolean
{
	return message.topic === "repo"
}

export function isRollup (message:PeerMessage):boolean
{
	return message.topic === "rollup"
}

export function isInstruction (message:PeerMessage):boolean
{
	return message.topic === "instruction"
}

export function wrapAsMessage(topic: string, data: any): PeerMessage {
	return { id: randId(), topic, subTopic: "", data }
}

export interface DocSnapshot {
	id:string
	doc?:Doc<any>
	reason?: string
}
