import React from "react"
const classNames = require('classnames')

interface GameGridArgs {
	cols: number
	children: any
}

export const CardGrid = ({ cols, children, ...rest }: GameGridArgs) => {
	const gridClasses = classNames(
		"grid",
		"card-grid",
		{
			[`grid-cols-${cols}`] : true
		},
		"mx-auto"
	)
	return <div className={gridClasses}>{children}</div>
}
