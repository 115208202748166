import React from "react"
import { Wave } from './wave'
export const Landing = () => {
	return (
		<>
			<div className="h-full">
				<Wave/>
			</div>
		</>
	)
}
