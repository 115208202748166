import { CountDown, JumpFish, LocationIcon } from "fui";
import { useClickOutside, useIsLocal } from "hook";
import { invariant } from "logs";
import React, { useEffect, useRef } from "react";
import { CardDisplayType, decideWhatToDisplay, GameCardArgs } from "./game-types"

const cn = require("classnames");

interface ResolveArgs {
	isResolving:boolean;
	isChallenged:boolean;
	isGuessing:boolean;
	nextAnswer:number | string;
}

export const Resolving = (
	{
		isResolving,
		isChallenged,
		isGuessing,
		nextAnswer,
	}:ResolveArgs) => {
	const locateClass = cn("", {
		animate__flash: isChallenged,
		animate__animated: isChallenged,
		animate__infinite: isChallenged,
	});
	return (
		<div className={locateClass}>
			{isResolving && !isGuessing && <JumpFish/>}
			{isResolving && isGuessing && <>{nextAnswer}</>}
			{!isResolving && <LocationIcon/>}
		</div>
	);
};


export const GameCard = (props:GameCardArgs) => {
	const {
		label,
		action,
		stage,
		activeCallback,
		answerCallback,
		showPopup,
		mode,
		idx,
		gameOn,
	} = props;
	const isResolving = stage === "MATCH" && action?.status === "RESOLVING";
	const isChallenged = isResolving && action?.player !== action?.originator;
	const isLost = action?.status === "LOST";
	let displayType:CardDisplayType = decideWhatToDisplay(
		stage,
		isLost,
		isChallenged,
		isResolving,
		gameOn
	);
	const [guessing, setGuessing] = React.useState(!!showPopup);
	const btnRef:any = useRef(null);
	const popoverRef:any = useRef(null);
	const isLocal = useIsLocal();
	useClickOutside(popoverRef, () => {
		if (guessing) activeHandler(false);
	});
	const openPopover = () => {
		if (!isResolving) {
			return;
		}
		if (isLocal) {
			invariant.log(
				`for this one .... you are looking for the answer is ${label}`
			);
		}
		activeHandler(true);
	};
	
	const closePopover = () => {
		activeHandler(false);
	};
	
	const activeHandler = (active:boolean) => {
		if (activeCallback) {
			activeCallback(active);
		}
	};
	
	const handleExpires = () => {
		invariant.log(
			`fail to provide the right answer. right answer is ${label}`
		);
		if (answerCallback) answerCallback(false);
	};
	
	
	useEffect(() => {
		setGuessing(!!showPopup);
	}, [showPopup]);
	
	const togglePopUp = () => {
		if (mode === "PLAY" || mode === "PRACTICE") {
			guessing ? closePopover() : openPopover();
		}
	};
	
	const cardClass = cn(
		`text-3xl`,
		`font-extrabold w-1/2 `,
		"rounded",
		"box-box",
		"mx-auto",
		"game-card",
		{ "game-card-5": idx % 10 === 4 },
		{ "game-card-back": displayType === CardDisplayType.Back },
		{ "game-card-lost": displayType === CardDisplayType.Lost },
		{ "game-card-face": displayType === CardDisplayType.Face },
		{ "game-card-resolving": displayType === CardDisplayType.Resolving },
		{ "game-card-resolving-challenged": isChallenged },
		{ "game-card-resolving-guessing": guessing },
		{ "game-card-back": displayType === CardDisplayType.Back }
	);
	return (
		<>
			<div className="flex flex-wrap">
				<div className="w-full text-center">
					<div
						className={cardClass}
						ref={btnRef}
						onClick={togglePopUp}
					>
						<div className="text-center  m-1 ">
							{displayType === CardDisplayType.Lost && <div/>}
							{displayType === CardDisplayType.Face && label}
							{displayType === CardDisplayType.Back && (
								<LocationIcon/>
							)}
							{displayType === CardDisplayType.Resolving && (
								<Resolving
									isChallenged={isChallenged}
									isResolving={isResolving}
									isGuessing={guessing}
									nextAnswer={action?.nextRnd!}
								/>
							)}
							{displayType === CardDisplayType.Challenged && (
								<CountDown
									callback={handleExpires}
									lastFor={action!.milliSeconds || 5000}
									startedAt={
										action!.startedAt
									}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
