import { ActiveRepo } from "activeDoc";
import automerge, { Doc } from "automerge";
import { differenceInMilliseconds } from "date-fns";
import { invariant } from "logs";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Game, GameStage, theOtherGuy } from "services/gameTypes";
import {
	dateFromFuzzyUnix, findTakenNumbers, drawNextRnd, getRandomAction, randId, startNewMatchGame,
	unixTime,
} from "services/utils";
import { drawNonTaken } from "./game-controller";
import { Action } from "./gameTypes";

type DeltaGame = Partial<Game>;

type Delta = {
	delta:any;
	description?:string;
};

function injectAttackAction (
	originator:string,
	to:string,
	milliSeconds:number,
	game:Game
)
{
	const action:Action = {
		originator,
		coset: game.coset,
		player: to,
		active: false,
		id: randId(),
		milliSeconds,
		status: "ATTACK",
		startedAt: new Date().valueOf(),
		cardIdx: -1,
		nextRnd: -1,
	};
	game.actions.push(action);
	return action;
}

const findAction = (actions:Action[], actionId:string) => {
	return actions.find((a) => a.id === actionId);
};

export interface VersionInfo {
	nextVersion:Doc<any>;
	reason?:string;
}

export const createSetter = (repo:ActiveRepo) => {
	const docKey = "game";
	const onMerge$:Subject<Delta> = new Subject<any>();
	const onVersion$:Subject<VersionInfo> = new Subject<VersionInfo>();
	const stage$:Subject<GameStage> = new Subject<GameStage>();
	onMerge$.subscribe((d:Delta) => {
		repo.merge(docKey, d.delta, d.description);
	});
	onVersion$.subscribe((d) => {
		repo.possible(docKey, d.nextVersion, d.reason);
	});
	
	// onVersion$.
	const possibleVersion = (nextVersion:Doc<any>, reason:string) => {
		repo.possible(docKey, nextVersion, reason);
	};
	
	const getGame = () => (repo.getDoc(docKey) as any) as Game;
	
	const setStage = (stage:GameStage) => {
		const delta:DeltaGame = { stage: stage };
		onMerge$.next({ delta, description: "set stage" });
		invariant.log(
			`** what's the immediate stage after setStage to ${stage} is ===>`,
			getGame().stage
		);
		stage$.next(getGame().stage);
	};
	
	const toggleActive = (actionId:string, isActive:boolean) => {
		const nextVersion = automerge.change(
			getGame(),
			`toggle active property fmaor ${actionId} to ${isActive}`,
			(game:Game) => {
				const action = findAction(game.actions, actionId);
				Object.assign(action, { active: isActive });
			}
		);
		onVersion$.next({
			                nextVersion,
			                reason: `toggle active property for ${actionId} to ${isActive}`,
		                });
	};
	
	const isSameOrigin = (action:Action) => (action?.player === action?.originator)
	
	const proposeAnswer = (actionId:string, isCorrect:boolean) => {
		const nextStatus = isCorrect ? "RESOLVED" : "LOST";
		const nextVersion = automerge.change(
			getGame(),
			`toggle active property for ${actionId} to ${isCorrect}`,
			(game:Game) => {
				const action = findAction(game.actions, actionId);
				if (action) {
					const { player } = action
					updateStatus(action!, nextStatus);
					// specify the card for current user ti guess 
					if (isSameOrigin(action)) {
						const nextAction = genSelfGuessAction(game, player)
						game.actions.push(nextAction)
					}
					if (isSameOrigin(action) && action.status === "RESOLVED") {
						injectAttackAction(
							action.originator,
							theOtherGuy(action.originator),
							action.milliSeconds! || 5000,
							game
						);
					}
				}
			}
		);
		onVersion$.next({
			                nextVersion,
			                reason: `answered ${actionId} to ${nextStatus} / correct: ${isCorrect}`,
		                });
	};
	
	const injectAttack = (originator:string, to:string) => (
		milliSeconds:number
	) => {
		let reason = "";
		const nextVersion = automerge.change(
			getGame(),
			`inject an attack from ${originator} to ${to} with time ${milliSeconds}`,
			(game:Game) => {
				const path = `${originator.substring(0, 1)} to ${to} `;
				const action = injectAttackAction(
					originator,
					to,
					milliSeconds,
					game
				);
				reason = `inject an ATTACK (${action.id}) from ${path} with time ${milliSeconds}`;
			}
		);
		// onVersion$.next({ nextVersion, reason });
		possibleVersion(nextVersion, reason);
	};
	
	const addRandomAction = () => {
		const current = getGame();
		const action = getRandomAction(getGame());
		const nextVersion = automerge.change(
			current,
			"add random action",
			(game:Game) => {
				game.actions.push(action);
			}
		);
		onVersion$.next({ nextVersion, reason: "add random action" });
	};
	
	const pipeGameStage = (source:Observable<any>) => {
		source
			.pipe(
				map((state:any) => state.value),
				distinctUntilChanged()
			)
			.subscribe((value) => {
				switch (value) {
					case "memorization":
						setStage("PREP");
						break;
					case "matching":
						setStage("MATCH");
						break;
					case "abort":
					case "done":
						setStage("DONE");
						break;
				}
			});
	};
	
	
	const startTheFirstMove = (player:string) => {
		const n = getGame().id.charCodeAt(0)
		const coset = getGame().coset
		const idx = n % getGame().fish.length
		const action:Action = {
			id: randId(),
			coset,
			cardIdx: idx,
			status: "RESOLVING",
			player,
			nextRnd: drawNextRnd(coset),
			originator: player,
			startedAt: new Date().valueOf(),
		}
		const current = getGame();
		const reason = "add the starting move";
		const nextVersion = automerge.change(
			current, reason, (game:Game) => {game.actions.push(action);}
		);
		onVersion$.next({ nextVersion, reason });
	}
	
	const newGame = (numOfCards:number = 20, coset: string = "english") => {
		const current = getGame();
		const reason = "start a new instance of game";
		const nextVersion = automerge.change(
			current, reason, (game:Game) => {
				const g = startNewMatchGame(numOfCards, coset)
				Object.assign(game, g)
			}
		);
		onVersion$.next({ nextVersion, reason });
	}
	
	
	return {
		setStage,
		stage$,
		addRandomAction,
		pipeGameStage,
		toggleActive,
		proposeAnswer,
		injectAttack,
		startTheFirstMove,
		newGame,
	};
};

function updateStatus (action:Action, nextStatus:string)
{
	const milliSeconds = differenceInMilliseconds(
		new Date(),
		dateFromFuzzyUnix(action!.startedAt)
	);
	Object.assign(action, {
		status: nextStatus,
		active: false,
		milliSeconds,
	});
}

function genSelfGuessAction (game:Game, player:string)
{
	const range = game.fish.length;
	const taken = findTakenNumbers(game.actions, player);
	const idx = drawNonTaken(range, taken);
	const coset = game.coset
	const act:Action = {
		id: randId(),
		coset,
		cardIdx: idx,
		status: "RESOLVING",
		originator: player,
		player,
		nextRnd: drawNextRnd(coset),
		startedAt: unixTime(),
	};
	return act;
}
