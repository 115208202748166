import React from "react";

interface args {
	score:any
}

export const GameScore2 = (props:args) => {
	const { score } = props;
	return (
		<div className="flex flex-row justify-between text-alt-3 score">
			<div className="flex flex-row align-baseline">
				<span className="label mr-2">Score</span>
				<span className="number">{score.solved - score.unsolved}</span>
			</div>
		</div>
	);
};
