import React, { useMemo } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { TiInfoLargeOutline, TiWarning, TiTimes } from "react-icons/ti";
import { GoStop, GoCheck } from "react-icons/go";

import { hideToaster$, ToasterMessage } from "services/toaster";
const cn = require("classnames");

interface Args {
	msg: ToasterMessage;
}

function getIcon(msg: ToasterMessage) {
	switch (msg.type) {
		case "ERROR":
			return <MdError />;
		case "INFO":
			return <TiInfoLargeOutline />;
		case "SUCCESS":
			return <GoCheck />;
		case "WARNING":
			return <GoStop />;
	}
}

export const Toast = (props: Args) => {
	const { id, main, subTitle, type } = props.msg;
	const removeMe = useMemo(() => {
		const removeMe = () => {
			hideToaster$.next(id);
		};
		setInterval(() => {
			removeMe();
		}, 3500);
		return removeMe;
	}, [id]);

	const hostClassNames = cn([
		"animate__bounceIn",
		{
			"bg-blue-600 text-alt-4": type === "INFO",
			"bg-green-600 text-alt-4": type === "SUCCESS",
			"bg-red-600 text-alt-4": type === "ERROR",
			"bg-yellow-400 text-main-4": type === "WARNING",
		},
		"border-t-4",
		"border-teal",
		"rounded-b",
		"px-4 py-3 shadow-md my-2",
	]);
	return (
		<div className={hostClassNames} role="alert">
			<div className="flex justify-between ">
				<div className="text-xl mr-2">{getIcon(props.msg)}</div>
				<div className="flex-grow">
					<p className="font-bold text-left">{main}</p>
					{subTitle && <p className="text-sm">{subTitle}</p>}
				</div>
				<div
					onClick={removeMe}
					className="text-right text-gray hover:bold hover:text-blue-900 cursor-pointer ml-2 w-2"
				>
					<TiTimes />
				</div>
			</div>
		</div>
	);
};
