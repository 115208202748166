import { yamlObj } from 'activeDoc';
import { GameViewer } from 'containers';
import { fromUnixTime } from 'date-fns/esm';
import React from 'react';
import { fromToday, Game } from 'services';
import { PracticeRecord } from 'services/practice-record';
import { TagsViewer } from './tags-viewer';



interface Args {
	id: string
	title: string
	content: Game
	tags: string[]
	status: string
	dateGmt: Date
}
export const RecordViewer = (props: Args) => {
	const { status, dateGmt, tags, content: game } = props
	return (<>
		<div className="flex flex-row justify-around bottom-dashed ">
			<div>{fromToday(dateGmt)}</div>
			<div>{status}</div>
			<div>{game.stage}</div>
			{tags.map((tag, index) => (<div key={index}>{tag}</div>))}
		</div>
	</>)
}


