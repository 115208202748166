import React, { useState } from "react";
import classnames from "classnames";

type callbackFunc = (activating: string, deactivating: string) => any;

interface tabsArgs {
	tabNames: string[];
	defaultTab?: string;
	callback?: callbackFunc;
}

interface tabArgs {
	name: string;
	active: boolean;
	onClick: Function;
}
const Tab = (props: tabArgs) => {
	const { name, active, onClick } = props;
	const cn = classnames([
		"text-lg",
		"px-4",
		"transition-1",
		{
			"border-b-4 border-solid border-white text-alt-1": active,
		},
		{
			"text-alt-2 cursor-pointer hover-text-alt-1": !active,
		},
	]);
	return (
		<div className={cn} onClick={() => onClick()} key={name}>
			{name}
		</div>
	);
};

export const Tabs = ({ tabNames, defaultTab, callback }: tabsArgs) => {
	const [activeTab, setActive] = useState<string>(defaultTab || tabNames[0]);
	const onClick = (activating: string) => {
		const deactivating = activeTab;
		setActive(activating);
		if (callback) {
			callback(activating, deactivating);
		}
	};
	return (
		<div className="flex flex-row">
			{tabNames.map((name, idx) => (
				<Tab
					key={idx}
					name={name}
					onClick={() => {
						onClick(name);
					}}
					active={activeTab === name}
				/>
			))}
		</div>
	);
};
