import { CardDisplayType } from "containers/game-types"
import { FacePadUnknown, SelectionPad } from "containers/pad"
import { FishOrCountDown } from "containers/pad/fish-or-countdown"
import { LocationIcon } from "fui"
import { useClickOutside, useKey } from "hook"
import React, { useRef, useState } from "react"
import { getCosetChar } from "services"
import "./face-pad.scss"
import { FacePadArgs } from "./types"

const cn = require("classnames")


interface CosetArgs {
	coset?: string
	label: number
}

const CosetLabel = (props: CosetArgs) => {
	const { coset, label } = props
	return <>{getCosetChar(coset || "english", label)}</>
}

export const FacePad = (props: FacePadArgs) => {
	const {
		label,
		render,
		action,
		realLabel,
		displayType,
		keyCap,
		nextRnd,
		answerCallback,
		coset,
	} = props
	const [expanded, setExpanded] = useState(false)
	// todo: if we pass down the display type as a parameter in the props, we can cunstom the class
	const cls = cn(
		"game-card",
		"game-card-face",
		"rounded ",
		"inline-block ",
		"place-self-center",
		{
			"hover-bg-main-3": props.clickable,
			"cursor-pointer": props.clickable,
		},
		{
			"game-card-lost": displayType === CardDisplayType.Lost,
			challenged: displayType === CardDisplayType.Challenged,
		}
	)
	const toggleExpanded = () => {
		setExpanded(!expanded)
	}
	const innerCls = cn(
		"inline-grid",
		"justify-center",
		"font-base",
		"face-pad-base",
		{
			"cursor-pointer": displayType === CardDisplayType.Resolving,
			"face-pad-keyCap-host":
				displayType === CardDisplayType.GuessWithKey,
			"next-num-card": displayType === CardDisplayType.NextNum,
			"count-down-label": displayType === CardDisplayType.Challenged,
		}
	)

	// following session to handle the selection pad session -------------------
	const popoverRef: any = useRef(null)
	useClickOutside(popoverRef, () => {
		if (expanded) {
			setExpanded(false)
		}
	})

	// next session - handling the answer --------------------
	const answerHandler = () => {
		console.log(
			`%%%% the real ... is ${realLabel}, your guess is ${label}`
		)

		if (answerCallback) {
			// we need to know if user click the right answer ... how????
			answerCallback(label === realLabel)

			if (expanded) {
				setExpanded(false)
			}
		}
	}

	const dumpMe = (e: any) => alert(e)

	const resolvable =
		displayType === CardDisplayType.Resolving ||
		displayType === CardDisplayType.Challenged

	useKey((key, event) => {
		// handle the remove popup
		const timeToRetract = key === "Escape" && expanded
		if (timeToRetract) {
			event.preventDefault()
			if (expanded) {
				setExpanded(false)
			}
			return
		}

		// open jumping fish
		const timeToOpenFish =
			key === "Enter" &&
			displayType === CardDisplayType.Resolving &&
			!expanded
		if (timeToOpenFish) {
			event.preventDefault()
			if (!expanded) {
				setExpanded(true)
			}
			return
		}

		// handle the guess key
		const withIn1And9 = key >= "1" && key <= "9"
		if (
			displayType === CardDisplayType.GuessWithKey &&
			withIn1And9 &&
			keyCap &&
			answerCallback
		) {
			answerCallback(key === keyCap.toString())
			event.preventDefault()
			if (expanded) {
				setExpanded(false)
			}
		}
	})

	return (
		<div className={cls}>
			{displayType === CardDisplayType.Lost && <></>}
			{displayType === CardDisplayType.Back && (
				<span className={innerCls}>
					<LocationIcon />
				</span>
			)}
			{(displayType === CardDisplayType.Face ||
				displayType === CardDisplayType.NextNum) && (
					<span className={innerCls}>
						<span>
							<CosetLabel label={label!} coset={coset} />
						</span>
					</span>
				)}
			{displayType === CardDisplayType.GuessWithKey && (
				<div className={innerCls} onClick={answerHandler}>
					<span className="face-pad-keyCap">{keyCap}</span>
					<span>
						<CosetLabel label={label!} coset={coset} />
					</span>
				</div>
			)}
			{displayType === CardDisplayType.CurrentUnknown && (
				<FacePadUnknown />
			)}
			{resolvable && (
				<div className="flex flex-row justify-center">
					<div className={innerCls} onClick={toggleExpanded}>
						<FishOrCountDown {...props} expanded={expanded} />
					</div>
					{expanded && (
						<div className="relative">
							<div className="absolute">
								<SelectionPad
									action={action!}
									realLabel={realLabel}
									answerCallback={answerCallback}
									ref={popoverRef}
									nextRnd={nextRnd}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}
