import React from "react"

const cn = require("classnames")

const defaultProfileUrl =
	"https://res.cloudinary.com/twks/image/upload/c_scale,w_400/v1603033547/fve/photo-1544552866-d3ed42536cfd_wrpkfp.jpg";


interface imageArgs {
	url?:string;
	callback?:(file:any) => void
	editing?:boolean
}

export const ProfileImage = (props:imageArgs) => {
	const { url, editing, callback } = props;
	const hiddenFileInput:any = React.useRef(null);
	const handleClick = (event:any) => {hiddenFileInput.current.click();};
	const handleChange = (event:any) => {
		const fileUploaded = event.target.files[0];
		if (props.callback) props.callback(fileUploaded)
	};
	const names = cn([" profile profile-image", {
		"profile-image-edit": editing,
		"rounded-full": editing,
		"profile-image-display": !editing
	}])
	return (
		<div className="flex flex-wrap justify-center">
			<input
				type="file"
				accept="image/*"
				multiple={false}
				ref={hiddenFileInput}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
			<div>
				<img
					className={names}
					src={url || defaultProfileUrl}
					alt="Profile"
					onClick={(editing) ? handleClick : undefined}
				/>
			</div>
		</div>
	);
};
