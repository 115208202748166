import React from "react";
import { Site } from 'routes'
import { FveApp } from "services"
import { createClient, Provider } from "urql"

function getToken ()
{
	return FveApp.getCurrentJwt()
}

const client = createClient(
	{
		// todo: we need to find a way to reset this endpoint based on the 
		// wp_endpoint from server, not the environment   
		url: FveApp.endPoints.wpGraphql,
		fetchOptions: () => {
			const token = getToken();
			return {
				headers: { authorization: token ? `Bearer ${token}` : '' },
			}
		},
	});

function App ()
{
	FveApp.restoreSession()
	console.log('env', process.env)
	console.log('endPoints', FveApp.endPoints)
	return (
		<Provider value={client}>
			<Site/>
		</Provider>
	)
}

export default App;
