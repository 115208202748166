import React from "react"
import { ImConnection } from "react-icons/im"

type FlagArg = {
	source:string
	connectTo:string
}
export const Flag = (props:FlagArg) => {
	const { source, connectTo: compareTo } = props
	return (source === compareTo) ? <span className="text-yellow-300"><ImConnection/></span> :
		   <span/>
}
