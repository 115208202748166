import { useMemo, useState } from "react"
import { stringOrJson } from "services"
import invariant from "ts-invariant"
import { gql, useMutation } from "urql"



const createPost = gql`
    mutation create_post($title: String, $tags: PostTagsInput, $content: String) {
        createPost(input: {title: $title, tags: $tags, content: $content,
            categories: {append: false, nodes: {name: "practice"}},
	        status: PUBLISH}) {
            post {
                id
                databaseId
                authorId
                content
                status
                tags {
                    nodes {
                        name
                    }
                }
            }
        }
    }
`


export const useCreatePracticePost = () => {
	const [createdPost, setPost] = useState<any>({})
	const [{ fetching, data, error }, update] = useMutation(createPost);
	const addPost = useMemo(() => {
		                        const internal = async (postTitle:string, postTags:string[], postContent:any) => {
			                        const variables = {
				                        title: postTitle,
				                        tags: { nodes: postTags.map(tag => ({ name: tag })) },
				                        content: stringOrJson(postContent)
			                        }
			                        const rt = await update(variables)
			                        // let's
			                        const { data: { createPost: { post } } } = rt
			                        invariant.log('the post from result ....', post)
			                        setPost(post)
		                        }
		                        return internal
	                        }, [update]
	)
	
	
	return {
		createdPost,
		addPost,
		fetching, data, error
	}
}

