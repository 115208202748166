import React from "react"

export const Credit = () => {
    return (
        <div>
            <ul>
                <li>
                    Icons made by{" "}
                    <a
                        href="https://www.flaticon.com/authors/freepik"
                        title="Freepik"
                    >
                        Freepik
                    </a>{" "}
                    from{" "}
                    <a href="https://www.flaticon.com/" title="Flaticon">
                        www.flaticon.com
                    </a>
                </li>
                <li>
                    <a
                        href="https://iconscout.com/icons/locked"
                        target="_blank"
                    >
                        Locked Icon
                    </a>{" "}
                    by{" "}
                    <a href="https://iconscout.com/contributors/twitter-inc">
                        Twitter Emoji
                    </a>{" "}
                    on<a href="https://iconscout.com">Iconscout</a>
                </li>
                <li>
                    <a
                        href="https://iconscout.com/icons/question-placeholder"
                        target="_blank"
                    >
                        Question Placeholder Icon
                    </a>
                    by
                    <a
                        href="https://iconscout.com/contributors/kerismaker"
                        target="_blank"
                    >
                        Kerismaker
                    </a>
                </li>
                <li>http://www.radfaces.com/ for faces s</li>
            </ul>
            <div>
                Icons made by{" "}
                <a
                    href="https://www.flaticon.com/authors/roundicons"
                    title="Roundicons"
                >
                    Roundicons
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                </a>
            </div>
            <div>
                Icons made by{" "}
                <a
                    href="https://www.flaticon.com/authors/vectors-market"
                    title="Vectors Market"
                >
                    Vectors Market
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                </a>
            </div>
            <div>
                Icons made by{" "}
                <a href="https://www.freepik.com" title="Freepik">
                    Freepik
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                </a>
            </div>
            <div>
                Icons made by{" "}
                <a
                    href="https://www.flaticon.com/authors/pixel-perfect"
                    title="Pixel perfect"
                >
                    Pixel perfect
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                </a>
            </div>
        </div>
    )
}
