import { ActiveRepo } from "activeDoc"
import automerge from "automerge"
import {
    formatDistance,
    fromUnixTime,
    getUnixTime,
    isDate,
    parseISO,
    addMinutes,
} from "date-fns"
import { htmlDecode } from "js-htmlencode"
import { invariant } from "logs"
import { customAlphabet } from "nanoid"
import { btw0And1, defaultRnd } from "services/rand"
import { IUser, JwtSignInResponse } from "services/types"
import YAML from "yaml"
import { drawFromCoset, getRandomCoset } from "./coset"
import { Action, Game, GameStage, Player } from "./gameTypes"
import { PracticeRecord } from "./practice-record"

const isEqual = require("lodash.isequal")
const factory = customAlphabet(
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZZ",
    10
)

export interface CountDownInfo {
    readonly stage: string
    readonly minutes: number
    readonly seconds: number
}

export interface StageConfig {
    readonly stage: string
    readonly startsAt: Date
    readonly endsAt: Date
    readonly duration: number
}

export const blankStageConfig = (): StageConfig => ({
    stage: "",
    startsAt: new Date(),
    endsAt: new Date(),
    duration: 0,
})

export const unixTime = () => getUnixTime(new Date())
export const dateFromFuzzyUnix = (n: number) => {
    return n > 1000314368332 ? fromUnixTime(n / 1000) : fromUnixTime(n)
}

export function twoDigits(num: number) {
    return (Math.round(num * 100) / 100).toFixed(2)
}

export const randId = () => {
    return factory()
}

export const getRange = (n: number) => Array.from({ length: n }, (x, i) => i)

export const drawNextRnd = (coset: string) => drawFromCoset(coset, defaultRnd)

export enum KindOfCard {
    Number,
}

export const drawCard = (from?: KindOfCard) => {
    const kind = from ? from : KindOfCard.Number
    return Math.floor(btw0And1() * 20 + 10)
}

export const getRandomAction = (game: Game) => {
    const idx = Math.ceil(Math.random() * game.fish.length) - 1
    const coset = game.coset
    const act: Action = {
        id: randId(),
        coset,
        cardIdx: idx,
        status: Math.random() > 0.5 ? "RESOLVING" : "LOST",
        player: Math.random() > 0.5 ? "fish" : "elephant",
        nextRnd: drawNextRnd(coset),
        originator: Math.random() > 0.5 ? "fish" : "elephant",
        startedAt: getUnixTime(new Date()),
    }
    invariant.log(`generate an action ....`, act)
    return act
}

export const getRandomStage = (): GameStage => {
    const r = Math.ceil(Math.random() * 3) - 1
    if (r === 0) return "PREP"
    if (r === 1) return "MATCH"
    if (r === 2) return "DONE"
    return "PREP"
}
const dummyPlayer: Partial<Player> = {
    name: "fd fds",
    email: "fdsfds@fdfsd.com",
    nation: "",
    id: randId(),
}

export const systemAction = (coset: string): Action => ({
    id: randId(),
    coset,
    cardIdx: -1,
    status: Math.random() > 0.5 ? "RESOLVING" : "LOST",
    player: "system",
    nextRnd: drawNextRnd(coset),
    originator: Math.random() > 0.5 ? "fish" : "elephant",
    startedAt: unixTime(),
})

export const first2Move = (game: Game) => {
    const idx = Math.ceil(Math.random() * game.fish.length) - 1
    const coset = game.coset
    const acts: Action[] = [
        {
            id: randId(),
            coset,
            cardIdx: idx,
            status: "RESOLVING",
            player: "fish",
            nextRnd: drawNextRnd(coset),
            originator: "fish",
            startedAt: new Date().valueOf(),
        },
        {
            id: randId(),
            coset: game.coset,
            cardIdx: idx,
            status: "RESOLVING",
            player: "elephant",
            nextRnd: drawNextRnd(coset),
            originator: "elephant",
            startedAt: new Date().valueOf(),
        },
    ]
    return acts
}

const defaultGame: Game = {
    id: randId(),
    coset: "english",
    numberOfCards: 20,
    fish: getRange(20).map(() => drawNextRnd("english")),
    setting: "PRACTICE",
    elephants: getRange(20).map(() => drawNextRnd("english")),
    players: [
        { ...dummyPlayer, symbol: "Fish", id: "fish" },
        {
            ...dummyPlayer,
            symbol: "Elephant",
            id: "elephant",
        },
    ],
    startedAt: new Date(),
    actions: [systemAction("english")],
    stage: "PREP",
}

export const getGameInstance = (
    numOfCards: number = 20,
    assignCoset: string = ""
): Game => {
    const coset = assignCoset || getRandomCoset()
    const starter = getRange(numOfCards).map(() =>
        drawFromCoset(coset, defaultRnd)
    )
    return {
        ...defaultGame,
        coset,
        fish: [...starter],
        elephants: [...starter],
        startedAt: new Date(),
    }
}

export const startNewMatchGame = (
    numOfCards: number = 20,
    coset: string = "english"
): Game => {
    const instance = { ...defaultGame, coset }
    const starter = getRange(numOfCards).map(() => drawNextRnd(instance.coset))
    return {
        ...instance,
        fish: [...starter],
        elephants: [...starter],
        startedAt: new Date(),
        id: randId(),
        actions: [
            {
                id: randId(),
                coset,
                cardIdx: -1,
                status: "RESOLVED",
                player: "system",
                nextRnd: drawNextRnd(coset),
                originator: "system",
                startedAt: unixTime(),
            },
        ],
    }
}

export type PossibleDate = Date | string | undefined

function guessDate(d: PossibleDate): Date {
    if (d === undefined) {
        return undefined!
    }
    if (isDate(d)) {
        return d as Date
    }
    return typeof d === "string" ? parseISO(d) : d
}

function timeZoneOffMinutes() {
    const tz = new Date().getTimezoneOffset()
    return tz
}

export function fromToday(d: PossibleDate) {
    if (!d) {
        return "missing date"
    }
    return formatDistance(
        guessDate(d),
        addMinutes(new Date(), timeZoneOffMinutes())
    )
}

export const yamlString = (obj: any) => YAML.stringify(obj)

export const unsubscribeAll = (subs: any[]) => {
    return () => {
        subs.forEach((s: any) => {
            if (s && typeof s.unsubscribe === "function") {
                s.unsubscribe()
            }
        })
        subs = []
    }
}

export const isSame = (u: any, v: any) => isEqual(u, v)

export function findTakenNumbers(actions: Action[], player: string) {
    return actions
        .filter(
            (a: Action) =>
                a.player === player &&
                (a.status === "RESOLVING" || a.status === "LOST")
        )
        .map((a) => a.cardIdx)
}

export function initRepo() {
    const id = randId()
    const repo = new ActiveRepo(id)
    repo.registerDoc("game", automerge.from(getGameInstance(10, "korean")))
    return repo
}

export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

/**
 * Convert WP response to local IUser
 * @param wp
 */
export function jwtRespToUser(wp: JwtSignInResponse) {
    const user: IUser = {
        ...wp.data.user,
        hashId: wp.data.user.hashId,
        username: wp.data.user.username,
        joinedAt: parseISO(wp.data.user.registeredDate),
        sessionId: wp.sessionId,
        ...wp.pubnub,
    }
    return user
}

export function validateEmail(mail: string) {
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            mail
        )
    ) {
        return true
    }
    return false
}

export const stringOrJson = (content: any) => {
    return typeof content === "string" ? content : JSON.stringify(content)
}

export const reverseHtml = (s: string) => {
    return s.replace("<p>", "").replace("</p>", "")
}

const replaceQuotes = (s: string) => {
    return s.replace(/&#8220;/gi, '"').replace(/&#8221;/g, '"')
}

const backToObject = (s: string) => {
    return JSON.parse(htmlDecode(s))
}

const extractTags = (content: any) => {
    const { tags } = content
    if (!tags) return []
    const { nodes } = tags
    if (!nodes) return []
    if (!Array.isArray(nodes)) return []
    return nodes.map((node) => node.name)
}

export const cleanUpPost = (post: any): PracticeRecord => {
    const { content } = post
    const replacement =
        content && typeof content === "string"
            ? backToObject(replaceQuotes(reverseHtml(content)))
            : content
    const clean = {
        ...post,
        content: replacement,
        tags: extractTags(post),
        dateGmt: post.dateGmt ? parseISO(post.dateGmt) : undefined,
        date: post.date ? parseISO(post.date) : undefined,
    }
    return clean
}

export const cleanUpPosts = (data: any): PracticeRecord[] => {
    if (Array.isArray(data?.posts?.nodes)) {
        const cleanedVersion = data?.posts?.nodes
            .map((post: any) => cleanUpPost(post))
            .sort((a: any, b: any) => b.databaseId - a.databaseId)
        console.log("cleanedVersion", cleanedVersion)
        return cleanedVersion
    }
    return data
}
