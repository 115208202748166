import { LocationIcon } from "fui"
import React from "react"
import { CountdownNum } from "./countdown-num"
import { JumpFish } from "./jumpFish"

export const Covered = () => {
    return (
        <div className="text-3xl font-extrabold w-1/2  rounded box-box  game-card game-card-back game-card-back">
            <div className="text-center  m-1 ">
                <LocationIcon />
            </div>
        </div>
    )
}

export const Replace = () => {
    return (
        <div className="text-alt-2 mx-auto pt-24">
            <div className="mb-8 flex flex-row items-center font-hand text-lg">
                When time's up, all numbers are covered. You will see one three
                possible displays: location icon, animated fish and count-down
                number
            </div>

            <div className="bg-main-2-to-5 p-8 rounded">
                <div className="flex flex-row gap-1 justify-center">
                    <Covered />
                    <Covered />
                    <JumpFish />
                    <Covered />
                    <Covered />
                    <CountdownNum />
                    <Covered />
                    <div className="">...</div>
                    <Covered />
                </div>
            </div>
        </div>
    )
}
