import React from "react";
import clownFish from "assets/images/clown-fish.svg";
import elephant from "assets/images/elephant.svg";
import vs from "assets/images/vs.svg";
import { useHistory } from "react-router-dom";
import { GiElephant } from "react-icons/gi";
import { FaFish, FaChevronRight } from "react-icons/fa";

export const Logo = () => {
	const history = useHistory();
	const goHome = () => {
		history.push("/");
	};
	return (
		<div
			onClick={goHome}
			className="flex-row cursor-pointer items-center inline-flex  "
		>
			<div className="font-display ml-1 text-alt-4 text-4xl font-bold">
				{" "}
				<FaFish />
			</div>
			<div className="text-xl ml-1">
				<span className=" vs-slash spacing-normal font-thin font-content">
					{" "}
					<FaChevronRight />
				</span>
			</div>
			<div className="font-display ml-1 text-alt-4 text-4xl font-thin">
				<GiElephant />
			</div>
		</div>
	);
};

export const SvgLogo = () => {
	const history = useHistory();
	const goHome = () => {
		history.push("/");
	};
	return (
		<div
			className="flex-row flex items-center justify-center cursor-pointer"
			onClick={goHome}
		>
			<div>
				<img className="w-12" src={clownFish} alt="goldFish" />
			</div>
			<div>
				<img className="w-6 mx-4" src={vs} alt="goldFish" />
			</div>
			<div>
				<img className="w-12" src={elephant} alt="goldFish" />
			</div>
		</div>
	);
};
