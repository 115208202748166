import { IconButton } from "fui/button"
import  React from 'react';
import { FiEdit } from "react-icons/fi"

interface Args {
	onClick?: Function
	label?: string
}

export const EditHeader = (props: Args) => {
	const {onClick, label} = props
	const handler = () => {
		if (onClick) {
			onClick()
		}
	}
	return <>
		<IconButton className="mt-2 mb-1 text-sm w-50 right"
		            align="left"
		            variant="link"
		            onClick={handler}>
			<FiEdit/>
			<div className="pl-2">{label || "nothing"}</div>
		</IconButton>
		<hr className="mt-2 mb-1 alt" />
	
	</>
}
