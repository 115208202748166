import { ActiveRepo } from "activeDoc";
import { CardGrid, GameCard } from "containers";
import { GuessCard } from "containers/guess-card";
import { Badge } from "fui";
import { useActiveGame, useAsGameViewer } from "hook";
import React, { useEffect, useState } from "react";
import { FaFish } from "react-icons/fa";
import { GiElephant } from "react-icons/gi";
import { Action, GameStage, getRange, PlayMode, } from "services";
import { findPop, GameProps, getAction } from "./game-types";


// export const BoardGrid = () => {};

const GenGameCard = (
	stage:GameStage,
	coset: string,
	label: number,
	idx:number,
	actions:Action[],
	mode:PlayMode,
	gameOn:boolean,
	activeCb:Function,
	answerCb:Function
) => {
	const act = getAction(idx, actions) as Action;
	const activeCallback = (isActive:boolean) => {
		if (act && activeCb) {
			activeCb(act.id, isActive);
		}
	}
	const answerCallback = (isCorrect:boolean) => {
		if (act && answerCb) {
			answerCb(act.id, isCorrect);
		}
	}
	return (
		<GameCard
			key={idx}
			idx={idx}
			stage={stage}
			action={act}
			label={label}
			actionId={act?.id}
			showPopup={act?.active}
			mode={mode}
			gameOn={gameOn}
			answerCallback={answerCallback}
			activeCallback={activeCallback}
			coset={coset}
		/>
	);
};

const GenGuessCard = (
	stage:GameStage,
	coset: string,
	label:string | number,
	idx:number,
	action:Action,
	mode:PlayMode,
	rightAnswer:string | number,
	activeCb:Function,
	answerCb:Function,
) => {
	const activeCallback = (isActive:boolean) => {
		if (action && activeCb) {
			activeCb(action.id, isActive);
		}
	}
	const answerCallback = (guessed:any) => {
		if (action && answerCb) {
			answerCb(action.id, rightAnswer === guessed);
		}
	}
	const idxToKey = (n:number) => {
		return ((n + 1) % 10).toString()
	}
	
	return (
		<GuessCard
			key={idx}
			stage={stage}
			action={action}
			value={label}
			actionId={action?.id}
			showPopup={action?.active}
			mode={mode}
			answerCallback={answerCallback}
			activeCallback={activeCallback}
			guessKey={idxToKey(idx)}
		/>
	);
};




export const GameBoard = (props:GameProps) => {
	const { mode, repo, faces, actions, coset } = props;
	const { stage, isGameOn } = useAsGameViewer(repo!);
	const [popAction, setPopAction] = useState<Action>();
	const { update, game } = useActiveGame({ repo: repo || new ActiveRepo("") })
	const rightAnswer = (idx:number) => faces[idx];
	useEffect(() => {
		setPopAction(findPop(actions));
	}, [actions]);
	return (
		<div className="p-4 shadow-md rounded text-light bg-main-3 ">
			<div
				className="flex-row flex items-center font-display justify-between text-2xl inline">
				<div className="flex flex-row gap-2 items-center text-xl font-thin text-alt-3">
					{props.player === "fish" && <FaFish/>}
					{props.player === "elephant" && <GiElephant/>}
					{props.player}
				</div>
				<div className="ml-2 font-display flex flex-row justify-end">
					{Badge(props.mode.toString())}
				</div>
			</div>
			<div className="px-2 py-4">
				<CardGrid cols={10}>
					{faces.map((label:any, idx:number) =>
						           GenGameCard(
							           stage, game?.coset || "english", label, idx, actions, mode, isGameOn,
							           update.toggleActive, update.proposeAnswer
						           )
					)}
				</CardGrid>
				{popAction && (
					<CardGrid cols={10}>
						{getRange(10).map((n:number, idx:number) =>
							                  GenGuessCard(
								                  stage,
								                  coset,
								                  (n + 1) % 10,
								                  idx,
								                  popAction,
								                  mode,
								                  rightAnswer(popAction?.cardIdx || 0),
								                  update.toggleActive,
								                  update.proposeAnswer
							                  )
						)}
					</CardGrid>
				)}
			</div>
		
		</div>
	);
}
