import { Navbar } from "fui";
import { useAcceptInvite, useSubs } from "hook";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { matchHost$, matchPeer$, unsubscribeAll, useUser } from "services";
import { IncomingInvitation } from "./invitation";
import { ToasterContainer } from "./toaster";



export const AppLayout: React.FC = ({ children }) => {
	const { user } = useUser();
	const { invitation, showInvite, setShowInvite } = useAcceptInvite();
	const history = useHistory();
	const subs = useSubs();
	useEffect(() => {
		unsubscribeAll(subs)()
		matchHost$.subscribe(host => {
			history.push(`/activeMatch/host/${host.matchId}/${host.host}/${host.peer}`)
		})
		matchPeer$.subscribe(host => {
			history.push(`/activeMatch/peer/${host.matchId}/${host.host}/${host.peer}`)
		})
	}, [history, subs])
	return (
		<div>
			<Navbar transparent={true} />
			{children}
			{showInvite && invitation && (
				<div
					className="bg-transparent w-full fixed flex flex-row justify-center z-50 top-5"
				>
					<IncomingInvitation
						invitation={invitation!}
						retractHandler={() => setShowInvite(false)}
					/>
				</div>
			)}
			<ToasterContainer />
		</div>
	);
};
