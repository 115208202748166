import { ActiveRepo, ActiveSync, chainActiveSync } from "activeDoc";
import { DocChanges } from "activeDoc/types";
import { useMemo, useState } from "react";
import { filter } from "rxjs/operators";
import { createSetter, Game, GameStage, unsubscribeAll, } from "services";
import { useSubs } from "./use-subs";


interface Args {
	sync?:ActiveSync;
	repo:ActiveRepo;
}

export const useActiveGame = (props:Args) => {
	const { sync, repo } = props;
	const [game, setGame] = useState<Game | undefined>(undefined);
	const [changeSnapshot, setChanges] = useState<DocChanges | undefined>(
		undefined
	);
	const [stage, setStage] = useState<GameStage | undefined>(undefined);
	const subs:any[] = useSubs();
	const { gameRepo, update } = useMemo(() => {
		unsubscribeAll(subs)() // this important to clean all trailing over and re-rendering
		if (sync) {
		 	chainActiveSync(sync!, repo).forEach(s => subs.push(s));
		}
		const gameRepo = repo;
		const update = createSetter(gameRepo);
		subs.push(update.stage$.subscribe((s) => setStage(s)));
		subs.push(gameRepo.change$.subscribe((chg) => setChanges(chg)));
		subs.push(
			gameRepo.docSnapshot$
					.pipe(filter((d) => d.id === "game"))
					.subscribe((d) => setGame(d.doc as Game))
		);
		// subs.push(gameRepo.askingSeed$.subscribe(sync?.askingSeed$))
		return { gameRepo, update };
	}, [repo, subs, sync]);
	return { gameRepo, game, update, stage, changeSnapshot };
};


