import { useHistory } from "react-router"

export interface RouteInstrument {
	readonly  category:string;
	readonly method:string;
	readonly params:any[]
}

export const useRouteInstrument = () => {
	const history = useHistory()

	// we need to decide where we forward this to ... 
	const forward = (args:RouteInstrument) => {
		const { category, method, params } = args
		if (category === "password") {
			const [token, ...rest] = params
			if (method === "reset") {
				console.log("** pig -- where are you???")
				history.push("/validatePasswordToken", {token})
			}
		}
	}
	return { forward }
}




