import { PeerSync } from "activeDoc"
import { invariant } from "logs"
import { useEffect, useState } from "react"
import { BehaviorSubject } from "rxjs"
import { FveApp } from "services/fveApplication"
import { IUser } from "services/types"
import { randId } from "services/utils"

export type MeetStatus = "BUSY" | "FREE" | "UNKNOWN"

let peerInstance = new PeerSync()
const status$ = new BehaviorSubject<MeetStatus>("UNKNOWN")
export const peerSync$ = new BehaviorSubject<PeerSync>(peerInstance)

export function startPeerInstance(address: string) {
    invariant.log(`[peer]`, `start new PeerSync w/ address ${address}`)
    peerInstance = new PeerSync(address)
    peerSync$.next(peerInstance)
}

export async function openForMeet(address?: string) {
    const id = address ? address : randId()
    // todo: this is not necessary because we do rely on the server passing the address
    const result: any = await FveApp.query(`
		mutation register {
			openForMeet(peerId: "${peerInstance.instanceId()}")}`)
    status$.next("FREE")
    invariant.log("registered", result)
    return result
}

export async function busy() {
    // const result: any = await FveApp.query(`mutation register {openForMeet}`)
    status$.next("FREE")
    const result: any = await FveApp.query(`mutation {setBusy}`)
    invariant.log("registered", result)
    status$.next("BUSY")
}

async function getOpenMeets() {
    const result: any = await FveApp.query(`query {openMeets}`)
    invariant.log("getOpenMeets", result.openMeets)
    return result.openMeets || []
}

const getPersonalMeetStatus = async () => {
    const result: any = await FveApp.query(`query {personalStatus}`)
    invariant.log("** get Status ", result.personalStatus)
    status$.next(result.personalStatus || "UNKNOWN")
}

export const useMeet = () => {
    const [meetStatus, setStatus] = useState<MeetStatus>(status$.getValue())
    useEffect(() => {
        const sub = status$.subscribe((d) => setStatus(d))
        return () => sub.unsubscribe()
    }, [])
    return { meetStatus, setStatus, openForMeet, busy, getPersonalMeetStatus }
}

export const useOpenMeet = () => {
    const [users, setUsers] = useState<IUser[]>()
    const [fetching, setFetching] = useState<boolean>(true)
    useEffect(() => {
        getOpenMeets().then((d) => {
            setUsers(d)
            setFetching(false)
        })
    }, [])

    const retrieveOpenMeets = async () => {
        setFetching(true)
        const openUsers = (await getOpenMeets()) || []
        setUsers(openUsers)
        setFetching(false)
    }
    return { users, fetching, retrieveOpenMeets }
}
