import React from "react"

const cn = require("classnames")

interface args {
    className?: string
    children: any
    slideName: string
    activeSlide: string
}

export const Slide = ({
    className,
    children,
    slideName,
    activeSlide,
}: args) => {
    const combo = cn(
        className,
        { "inactive-slide": activeSlide !== slideName },
        { invisible: activeSlide !== slideName },
        { "activating-slide": activeSlide === slideName },
)

    return (
        <>
            <div className={combo}>{children}</div>
        </>
    )
}
