import { IconButton } from "fui"
import { StdImageDone, StdImageWait } from "fui/can-images";
import React, { useState } from "react"
import { useForm } from 'react-hook-form';
import { passwordAction } from "services/api/client"

export type Solution = "signIn" | "signUp" | "retrieve"

interface args {
	solution: Solution
}

interface IFormInput {
	userNameOrEmail: string
}

type StatusType = "Initial" | "Sent"

export const RequestReset = ({ solution }: args) => {

	const [status, setStatus] = useState<StatusType>("Initial")

	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isSubmitting },
	} = useForm<IFormInput>();
	
	const onReset = handleSubmit(async (data) => {
		const resp = await passwordAction(data.userNameOrEmail, "reset")
		setStatus("Sent")
	})

	
	return (
		<div className="text-alt-3 mt-3">
			{solution === "signUp" && (
				<div>
					Enter your <span className="font-bold">email address</span>{" "}
					that you will be use to sign in. We will send you an email
					with link to create password ***.
				</div>
			)}
			{solution === "retrieve" && (
				<div>
					Enter your <span className="font-bold">email address or User Name</span>{" "}
					that you used to register. We will send you an email with
					link to reset your password.
				</div>
			)}


			{status === "Initial" &&

				<form>
					<input
						type="text"
						className="fish-input mt-4"
						placeholder="Your email"
						{...register("userNameOrEmail", { required: true })}
					/>
					<hr className="my-4 border-gray-700" />
					{isSubmitting &&
						<div className="bg-main-2 p-2 mt-2 font-bold flex flex-row">
							<div className="mx-2">
								<StdImageWait className="w-6" />
							</div>
							<div>
								Sending ...
							</div>
						</div>
					}
					{!isSubmitting && <div className="mt-4">
						<IconButton variant="color" onClick={onReset}>
							Send Me Link
						</IconButton>
					</div>
					}

				</form>

			}

			{status === "Sent" && (
				<div className="bg-main-2 p-2 mt-2 font-bold flex flex-row">
					<div className="mx-2">
						<StdImageDone className="" />
					</div>

					<div>
						We have sent an email to the associate account with further instructions.
					</div>
				</div>
			)}




		</div>
	)
}
